/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PersonalInfos
 */
export interface PersonalInfos {
    /**
     * 
     * @type {string}
     * @memberof PersonalInfos
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PersonalInfos
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof PersonalInfos
     */
    sex: string;
    /**
     * 
     * @type {Date}
     * @memberof PersonalInfos
     */
    birthday: Date;
    /**
     * 
     * @type {string}
     * @memberof PersonalInfos
     */
    alias: string;
    /**
     * 
     * @type {string}
     * @memberof PersonalInfos
     */
    currentJob: string;
    /**
     * 
     * @type {string}
     * @memberof PersonalInfos
     */
    learnedJob: string;
}

/**
 * Check if a given object implements the PersonalInfos interface.
 */
export function instanceOfPersonalInfos(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "sex" in value;
    isInstance = isInstance && "birthday" in value;
    isInstance = isInstance && "alias" in value;
    isInstance = isInstance && "currentJob" in value;
    isInstance = isInstance && "learnedJob" in value;

    return isInstance;
}

export function PersonalInfosFromJSON(json: any): PersonalInfos {
    return PersonalInfosFromJSONTyped(json, false);
}

export function PersonalInfosFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonalInfos {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'firstName': json['firstName'],
        'sex': json['sex'],
        'birthday': (new Date(json['birthday'])),
        'alias': json['alias'],
        'currentJob': json['currentJob'],
        'learnedJob': json['learnedJob'],
    };
}

export function PersonalInfosToJSON(value?: PersonalInfos | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'firstName': value.firstName,
        'sex': value.sex,
        'birthday': (value.birthday.toISOString().substr(0,10)),
        'alias': value.alias,
        'currentJob': value.currentJob,
        'learnedJob': value.learnedJob,
    };
}

