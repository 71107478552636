/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ActingExperienceFormValuesDto } from './ActingExperienceFormValuesDto';
import {
    ActingExperienceFormValuesDtoFromJSON,
    ActingExperienceFormValuesDtoFromJSONTyped,
    ActingExperienceFormValuesDtoToJSON,
} from './ActingExperienceFormValuesDto';
import type { AddressFormValuesDto } from './AddressFormValuesDto';
import {
    AddressFormValuesDtoFromJSON,
    AddressFormValuesDtoFromJSONTyped,
    AddressFormValuesDtoToJSON,
} from './AddressFormValuesDto';
import type { BahnCardFormValuesDto } from './BahnCardFormValuesDto';
import {
    BahnCardFormValuesDtoFromJSON,
    BahnCardFormValuesDtoFromJSONTyped,
    BahnCardFormValuesDtoToJSON,
} from './BahnCardFormValuesDto';
import type { BankAccountInformationFormValuesDto } from './BankAccountInformationFormValuesDto';
import {
    BankAccountInformationFormValuesDtoFromJSON,
    BankAccountInformationFormValuesDtoFromJSONTyped,
    BankAccountInformationFormValuesDtoToJSON,
} from './BankAccountInformationFormValuesDto';
import type { BodyAccessoriesFormValuesDto } from './BodyAccessoriesFormValuesDto';
import {
    BodyAccessoriesFormValuesDtoFromJSON,
    BodyAccessoriesFormValuesDtoFromJSONTyped,
    BodyAccessoriesFormValuesDtoToJSON,
} from './BodyAccessoriesFormValuesDto';
import type { BodyDetailsFormValuesDto } from './BodyDetailsFormValuesDto';
import {
    BodyDetailsFormValuesDtoFromJSON,
    BodyDetailsFormValuesDtoFromJSONTyped,
    BodyDetailsFormValuesDtoToJSON,
} from './BodyDetailsFormValuesDto';
import type { CastingBodyFormValuesDto } from './CastingBodyFormValuesDto';
import {
    CastingBodyFormValuesDtoFromJSON,
    CastingBodyFormValuesDtoFromJSONTyped,
    CastingBodyFormValuesDtoToJSON,
} from './CastingBodyFormValuesDto';
import type { CategoryFormValuesDto } from './CategoryFormValuesDto';
import {
    CategoryFormValuesDtoFromJSON,
    CategoryFormValuesDtoFromJSONTyped,
    CategoryFormValuesDtoToJSON,
} from './CategoryFormValuesDto';
import type { ColorsAndTypesFormValuesDto } from './ColorsAndTypesFormValuesDto';
import {
    ColorsAndTypesFormValuesDtoFromJSON,
    ColorsAndTypesFormValuesDtoFromJSONTyped,
    ColorsAndTypesFormValuesDtoToJSON,
} from './ColorsAndTypesFormValuesDto';
import type { ContactInfoFormValuesDto } from './ContactInfoFormValuesDto';
import {
    ContactInfoFormValuesDtoFromJSON,
    ContactInfoFormValuesDtoFromJSONTyped,
    ContactInfoFormValuesDtoToJSON,
} from './ContactInfoFormValuesDto';
import type { DancesFormValuesDto } from './DancesFormValuesDto';
import {
    DancesFormValuesDtoFromJSON,
    DancesFormValuesDtoFromJSONTyped,
    DancesFormValuesDtoToJSON,
} from './DancesFormValuesDto';
import type { DietFormValuesDto } from './DietFormValuesDto';
import {
    DietFormValuesDtoFromJSON,
    DietFormValuesDtoFromJSONTyped,
    DietFormValuesDtoToJSON,
} from './DietFormValuesDto';
import type { DriversLicenseFormValuesDto } from './DriversLicenseFormValuesDto';
import {
    DriversLicenseFormValuesDtoFromJSON,
    DriversLicenseFormValuesDtoFromJSONTyped,
    DriversLicenseFormValuesDtoToJSON,
} from './DriversLicenseFormValuesDto';
import type { IdentificationFormValuesDto } from './IdentificationFormValuesDto';
import {
    IdentificationFormValuesDtoFromJSON,
    IdentificationFormValuesDtoFromJSONTyped,
    IdentificationFormValuesDtoToJSON,
} from './IdentificationFormValuesDto';
import type { InstrumentsFormValuesDto } from './InstrumentsFormValuesDto';
import {
    InstrumentsFormValuesDtoFromJSON,
    InstrumentsFormValuesDtoFromJSONTyped,
    InstrumentsFormValuesDtoToJSON,
} from './InstrumentsFormValuesDto';
import type { InsuranceFormValuesDto } from './InsuranceFormValuesDto';
import {
    InsuranceFormValuesDtoFromJSON,
    InsuranceFormValuesDtoFromJSONTyped,
    InsuranceFormValuesDtoToJSON,
} from './InsuranceFormValuesDto';
import type { ModelBillingFormValuesDto } from './ModelBillingFormValuesDto';
import {
    ModelBillingFormValuesDtoFromJSON,
    ModelBillingFormValuesDtoFromJSONTyped,
    ModelBillingFormValuesDtoToJSON,
} from './ModelBillingFormValuesDto';
import type { ModelConditionsFormValuesDto } from './ModelConditionsFormValuesDto';
import {
    ModelConditionsFormValuesDtoFromJSON,
    ModelConditionsFormValuesDtoFromJSONTyped,
    ModelConditionsFormValuesDtoToJSON,
} from './ModelConditionsFormValuesDto';
import type { ModelCostsFormValuesDto } from './ModelCostsFormValuesDto';
import {
    ModelCostsFormValuesDtoFromJSON,
    ModelCostsFormValuesDtoFromJSONTyped,
    ModelCostsFormValuesDtoToJSON,
} from './ModelCostsFormValuesDto';
import type { ModelJobHistoryFormValuesDto } from './ModelJobHistoryFormValuesDto';
import {
    ModelJobHistoryFormValuesDtoFromJSON,
    ModelJobHistoryFormValuesDtoFromJSONTyped,
    ModelJobHistoryFormValuesDtoToJSON,
} from './ModelJobHistoryFormValuesDto';
import type { ModelingExperienceFormValuesDto } from './ModelingExperienceFormValuesDto';
import {
    ModelingExperienceFormValuesDtoFromJSON,
    ModelingExperienceFormValuesDtoFromJSONTyped,
    ModelingExperienceFormValuesDtoToJSON,
} from './ModelingExperienceFormValuesDto';
import type { NotesFormValuesDto } from './NotesFormValuesDto';
import {
    NotesFormValuesDtoFromJSON,
    NotesFormValuesDtoFromJSONTyped,
    NotesFormValuesDtoToJSON,
} from './NotesFormValuesDto';
import type { PersonalInfosFormValuesDto } from './PersonalInfosFormValuesDto';
import {
    PersonalInfosFormValuesDtoFromJSON,
    PersonalInfosFormValuesDtoFromJSONTyped,
    PersonalInfosFormValuesDtoToJSON,
} from './PersonalInfosFormValuesDto';
import type { ProtocolsFormValuesDto } from './ProtocolsFormValuesDto';
import {
    ProtocolsFormValuesDtoFromJSON,
    ProtocolsFormValuesDtoFromJSONTyped,
    ProtocolsFormValuesDtoToJSON,
} from './ProtocolsFormValuesDto';
import type { SpecialsFormValuesDto } from './SpecialsFormValuesDto';
import {
    SpecialsFormValuesDtoFromJSON,
    SpecialsFormValuesDtoFromJSONTyped,
    SpecialsFormValuesDtoToJSON,
} from './SpecialsFormValuesDto';
import type { SpokenLanguagesFormValuesDto } from './SpokenLanguagesFormValuesDto';
import {
    SpokenLanguagesFormValuesDtoFromJSON,
    SpokenLanguagesFormValuesDtoFromJSONTyped,
    SpokenLanguagesFormValuesDtoToJSON,
} from './SpokenLanguagesFormValuesDto';
import type { SportsFormValuesDto } from './SportsFormValuesDto';
import {
    SportsFormValuesDtoFromJSON,
    SportsFormValuesDtoFromJSONTyped,
    SportsFormValuesDtoToJSON,
} from './SportsFormValuesDto';
import type { TaxInformationFormValuesDto } from './TaxInformationFormValuesDto';
import {
    TaxInformationFormValuesDtoFromJSON,
    TaxInformationFormValuesDtoFromJSONTyped,
    TaxInformationFormValuesDtoToJSON,
} from './TaxInformationFormValuesDto';

/**
 * 
 * @export
 * @interface ModelMasterDataFormValuesDto
 */
export interface ModelMasterDataFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataFormValuesDto
     */
    modelId?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataFormValuesDto
     */
    activeState?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataFormValuesDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataFormValuesDto
     */
    image?: string;
    /**
     * 
     * @type {PersonalInfosFormValuesDto}
     * @memberof ModelMasterDataFormValuesDto
     */
    personalInfos?: PersonalInfosFormValuesDto;
    /**
     * 
     * @type {ContactInfoFormValuesDto}
     * @memberof ModelMasterDataFormValuesDto
     */
    contactInfo?: ContactInfoFormValuesDto;
    /**
     * 
     * @type {NotesFormValuesDto}
     * @memberof ModelMasterDataFormValuesDto
     */
    notes?: NotesFormValuesDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelMasterDataFormValuesDto
     */
    updateStates?: Array<ModelMasterDataFormValuesDtoUpdateStatesEnum>;
    /**
     * 
     * @type {AddressFormValuesDto}
     * @memberof ModelMasterDataFormValuesDto
     */
    livingAddress?: AddressFormValuesDto;
    /**
     * 
     * @type {AddressFormValuesDto}
     * @memberof ModelMasterDataFormValuesDto
     */
    notifyAddress?: AddressFormValuesDto;
    /**
     * 
     * @type {IdentificationFormValuesDto}
     * @memberof ModelMasterDataFormValuesDto
     */
    identification?: IdentificationFormValuesDto;
    /**
     * 
     * @type {SpecialsFormValuesDto}
     * @memberof ModelMasterDataFormValuesDto
     */
    specials?: SpecialsFormValuesDto;
    /**
     * 
     * @type {DietFormValuesDto}
     * @memberof ModelMasterDataFormValuesDto
     */
    diet?: DietFormValuesDto;
    /**
     * 
     * @type {BankAccountInformationFormValuesDto}
     * @memberof ModelMasterDataFormValuesDto
     */
    bankAccountInformation?: BankAccountInformationFormValuesDto;
    /**
     * 
     * @type {InsuranceFormValuesDto}
     * @memberof ModelMasterDataFormValuesDto
     */
    insurance?: InsuranceFormValuesDto;
    /**
     * 
     * @type {TaxInformationFormValuesDto}
     * @memberof ModelMasterDataFormValuesDto
     */
    taxInformation?: TaxInformationFormValuesDto;
    /**
     * 
     * @type {ColorsAndTypesFormValuesDto}
     * @memberof ModelMasterDataFormValuesDto
     */
    colorsAndTypes?: ColorsAndTypesFormValuesDto;
    /**
     * 
     * @type {BodyAccessoriesFormValuesDto}
     * @memberof ModelMasterDataFormValuesDto
     */
    bodyAccessories?: BodyAccessoriesFormValuesDto;
    /**
     * 
     * @type {ModelConditionsFormValuesDto}
     * @memberof ModelMasterDataFormValuesDto
     */
    conditions?: ModelConditionsFormValuesDto;
    /**
     * 
     * @type {InstrumentsFormValuesDto}
     * @memberof ModelMasterDataFormValuesDto
     */
    instruments?: InstrumentsFormValuesDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelMasterDataFormValuesDto
     */
    additionalAgencies?: Array<string>;
    /**
     * 
     * @type {DancesFormValuesDto}
     * @memberof ModelMasterDataFormValuesDto
     */
    dances: DancesFormValuesDto;
    /**
     * 
     * @type {SportsFormValuesDto}
     * @memberof ModelMasterDataFormValuesDto
     */
    sports: SportsFormValuesDto;
    /**
     * 
     * @type {BodyDetailsFormValuesDto}
     * @memberof ModelMasterDataFormValuesDto
     */
    bodyDetails?: BodyDetailsFormValuesDto;
    /**
     * 
     * @type {CastingBodyFormValuesDto}
     * @memberof ModelMasterDataFormValuesDto
     */
    castingBody?: CastingBodyFormValuesDto;
    /**
     * 
     * @type {ActingExperienceFormValuesDto}
     * @memberof ModelMasterDataFormValuesDto
     */
    actingExperience?: ActingExperienceFormValuesDto;
    /**
     * 
     * @type {ModelingExperienceFormValuesDto}
     * @memberof ModelMasterDataFormValuesDto
     */
    modelExperience?: ModelingExperienceFormValuesDto;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataFormValuesDto
     */
    parentAgency?: string;
    /**
     * 
     * @type {BahnCardFormValuesDto}
     * @memberof ModelMasterDataFormValuesDto
     */
    bahnCard?: BahnCardFormValuesDto;
    /**
     * 
     * @type {DriversLicenseFormValuesDto}
     * @memberof ModelMasterDataFormValuesDto
     */
    driversLicenses?: DriversLicenseFormValuesDto;
    /**
     * 
     * @type {SpokenLanguagesFormValuesDto}
     * @memberof ModelMasterDataFormValuesDto
     */
    spokenLanguages?: SpokenLanguagesFormValuesDto;
    /**
     * 
     * @type {CategoryFormValuesDto}
     * @memberof ModelMasterDataFormValuesDto
     */
    category?: CategoryFormValuesDto;
    /**
     * 
     * @type {ProtocolsFormValuesDto}
     * @memberof ModelMasterDataFormValuesDto
     */
    protocols?: ProtocolsFormValuesDto;
    /**
     * 
     * @type {ModelCostsFormValuesDto}
     * @memberof ModelMasterDataFormValuesDto
     */
    costReasons?: ModelCostsFormValuesDto;
    /**
     * 
     * @type {ModelBillingFormValuesDto}
     * @memberof ModelMasterDataFormValuesDto
     */
    billing?: ModelBillingFormValuesDto;
    /**
     * 
     * @type {ModelJobHistoryFormValuesDto}
     * @memberof ModelMasterDataFormValuesDto
     */
    jobHistory?: ModelJobHistoryFormValuesDto;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataFormValuesDto
     */
    personalNotice?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataFormValuesDto
     */
    oauthId?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelMasterDataFormValuesDto
     */
    userId?: number;
}


/**
 * @export
 */
export const ModelMasterDataFormValuesDtoUpdateStatesEnum = {
    Images: 'IMAGES',
    Measurements: 'MEASUREMENTS'
} as const;
export type ModelMasterDataFormValuesDtoUpdateStatesEnum = typeof ModelMasterDataFormValuesDtoUpdateStatesEnum[keyof typeof ModelMasterDataFormValuesDtoUpdateStatesEnum];


/**
 * Check if a given object implements the ModelMasterDataFormValuesDto interface.
 */
export function instanceOfModelMasterDataFormValuesDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "dances" in value;
    isInstance = isInstance && "sports" in value;

    return isInstance;
}

export function ModelMasterDataFormValuesDtoFromJSON(json: any): ModelMasterDataFormValuesDto {
    return ModelMasterDataFormValuesDtoFromJSONTyped(json, false);
}

export function ModelMasterDataFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelMasterDataFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelId': !exists(json, 'modelId') ? undefined : json['modelId'],
        'activeState': !exists(json, 'activeState') ? undefined : json['activeState'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'personalInfos': !exists(json, 'personalInfos') ? undefined : PersonalInfosFormValuesDtoFromJSON(json['personalInfos']),
        'contactInfo': !exists(json, 'contactInfo') ? undefined : ContactInfoFormValuesDtoFromJSON(json['contactInfo']),
        'notes': !exists(json, 'notes') ? undefined : NotesFormValuesDtoFromJSON(json['notes']),
        'updateStates': !exists(json, 'updateStates') ? undefined : json['updateStates'],
        'livingAddress': !exists(json, 'livingAddress') ? undefined : AddressFormValuesDtoFromJSON(json['livingAddress']),
        'notifyAddress': !exists(json, 'notifyAddress') ? undefined : AddressFormValuesDtoFromJSON(json['notifyAddress']),
        'identification': !exists(json, 'identification') ? undefined : IdentificationFormValuesDtoFromJSON(json['identification']),
        'specials': !exists(json, 'specials') ? undefined : SpecialsFormValuesDtoFromJSON(json['specials']),
        'diet': !exists(json, 'diet') ? undefined : DietFormValuesDtoFromJSON(json['diet']),
        'bankAccountInformation': !exists(json, 'bankAccountInformation') ? undefined : BankAccountInformationFormValuesDtoFromJSON(json['bankAccountInformation']),
        'insurance': !exists(json, 'insurance') ? undefined : InsuranceFormValuesDtoFromJSON(json['insurance']),
        'taxInformation': !exists(json, 'taxInformation') ? undefined : TaxInformationFormValuesDtoFromJSON(json['taxInformation']),
        'colorsAndTypes': !exists(json, 'colorsAndTypes') ? undefined : ColorsAndTypesFormValuesDtoFromJSON(json['colorsAndTypes']),
        'bodyAccessories': !exists(json, 'bodyAccessories') ? undefined : BodyAccessoriesFormValuesDtoFromJSON(json['bodyAccessories']),
        'conditions': !exists(json, 'conditions') ? undefined : ModelConditionsFormValuesDtoFromJSON(json['conditions']),
        'instruments': !exists(json, 'instruments') ? undefined : InstrumentsFormValuesDtoFromJSON(json['instruments']),
        'additionalAgencies': !exists(json, 'additionalAgencies') ? undefined : json['additionalAgencies'],
        'dances': DancesFormValuesDtoFromJSON(json['dances']),
        'sports': SportsFormValuesDtoFromJSON(json['sports']),
        'bodyDetails': !exists(json, 'bodyDetails') ? undefined : BodyDetailsFormValuesDtoFromJSON(json['bodyDetails']),
        'castingBody': !exists(json, 'castingBody') ? undefined : CastingBodyFormValuesDtoFromJSON(json['castingBody']),
        'actingExperience': !exists(json, 'actingExperience') ? undefined : ActingExperienceFormValuesDtoFromJSON(json['actingExperience']),
        'modelExperience': !exists(json, 'modelExperience') ? undefined : ModelingExperienceFormValuesDtoFromJSON(json['modelExperience']),
        'parentAgency': !exists(json, 'parentAgency') ? undefined : json['parentAgency'],
        'bahnCard': !exists(json, 'bahnCard') ? undefined : BahnCardFormValuesDtoFromJSON(json['bahnCard']),
        'driversLicenses': !exists(json, 'driversLicenses') ? undefined : DriversLicenseFormValuesDtoFromJSON(json['driversLicenses']),
        'spokenLanguages': !exists(json, 'spokenLanguages') ? undefined : SpokenLanguagesFormValuesDtoFromJSON(json['spokenLanguages']),
        'category': !exists(json, 'category') ? undefined : CategoryFormValuesDtoFromJSON(json['category']),
        'protocols': !exists(json, 'protocols') ? undefined : ProtocolsFormValuesDtoFromJSON(json['protocols']),
        'costReasons': !exists(json, 'costReasons') ? undefined : ModelCostsFormValuesDtoFromJSON(json['costReasons']),
        'billing': !exists(json, 'billing') ? undefined : ModelBillingFormValuesDtoFromJSON(json['billing']),
        'jobHistory': !exists(json, 'jobHistory') ? undefined : ModelJobHistoryFormValuesDtoFromJSON(json['jobHistory']),
        'personalNotice': !exists(json, 'personalNotice') ? undefined : json['personalNotice'],
        'oauthId': !exists(json, 'oauthId') ? undefined : json['oauthId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
    };
}

export function ModelMasterDataFormValuesDtoToJSON(value?: ModelMasterDataFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelId': value.modelId,
        'activeState': value.activeState,
        'email': value.email,
        'image': value.image,
        'personalInfos': PersonalInfosFormValuesDtoToJSON(value.personalInfos),
        'contactInfo': ContactInfoFormValuesDtoToJSON(value.contactInfo),
        'notes': NotesFormValuesDtoToJSON(value.notes),
        'updateStates': value.updateStates,
        'livingAddress': AddressFormValuesDtoToJSON(value.livingAddress),
        'notifyAddress': AddressFormValuesDtoToJSON(value.notifyAddress),
        'identification': IdentificationFormValuesDtoToJSON(value.identification),
        'specials': SpecialsFormValuesDtoToJSON(value.specials),
        'diet': DietFormValuesDtoToJSON(value.diet),
        'bankAccountInformation': BankAccountInformationFormValuesDtoToJSON(value.bankAccountInformation),
        'insurance': InsuranceFormValuesDtoToJSON(value.insurance),
        'taxInformation': TaxInformationFormValuesDtoToJSON(value.taxInformation),
        'colorsAndTypes': ColorsAndTypesFormValuesDtoToJSON(value.colorsAndTypes),
        'bodyAccessories': BodyAccessoriesFormValuesDtoToJSON(value.bodyAccessories),
        'conditions': ModelConditionsFormValuesDtoToJSON(value.conditions),
        'instruments': InstrumentsFormValuesDtoToJSON(value.instruments),
        'additionalAgencies': value.additionalAgencies,
        'dances': DancesFormValuesDtoToJSON(value.dances),
        'sports': SportsFormValuesDtoToJSON(value.sports),
        'bodyDetails': BodyDetailsFormValuesDtoToJSON(value.bodyDetails),
        'castingBody': CastingBodyFormValuesDtoToJSON(value.castingBody),
        'actingExperience': ActingExperienceFormValuesDtoToJSON(value.actingExperience),
        'modelExperience': ModelingExperienceFormValuesDtoToJSON(value.modelExperience),
        'parentAgency': value.parentAgency,
        'bahnCard': BahnCardFormValuesDtoToJSON(value.bahnCard),
        'driversLicenses': DriversLicenseFormValuesDtoToJSON(value.driversLicenses),
        'spokenLanguages': SpokenLanguagesFormValuesDtoToJSON(value.spokenLanguages),
        'category': CategoryFormValuesDtoToJSON(value.category),
        'protocols': ProtocolsFormValuesDtoToJSON(value.protocols),
        'costReasons': ModelCostsFormValuesDtoToJSON(value.costReasons),
        'billing': ModelBillingFormValuesDtoToJSON(value.billing),
        'jobHistory': ModelJobHistoryFormValuesDtoToJSON(value.jobHistory),
        'personalNotice': value.personalNotice,
        'oauthId': value.oauthId,
        'userId': value.userId,
    };
}

