import { Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { initialState, ProjectEditStateModel } from '../project-edit.model';
import { ProjectEditBaseActions } from '../actions/base.actions';
import { finalize, tap } from 'rxjs/operators';
import { ProjectFormService } from '../../../service/project.form.service';
import { ProjectFormValuesDto } from 'libs/api-typescript-angular/src/model/models';
import { ProjectEditAdditionalParticipantsActions } from '../actions/additional-participants.actions';

@Injectable()
export class ProjectEditBaseHandlers {
    constructor(
        private projectFormService: ProjectFormService
    ) { }

    @Action(ProjectEditBaseActions.SetLoading)
    setLoading(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditBaseActions.SetLoading) {
        ctx.patchState({ loading: action.loading });
    }

    @Action(ProjectEditBaseActions.UpdateLanguage)
    updateLanguage(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditBaseActions.UpdateLanguage) {
        ctx.patchState({ language: action.language });
    }

    @Action(ProjectEditBaseActions.UpdateState)
    updateState(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditBaseActions.UpdateState) {
        const previousState = ctx.getState();
        ctx.patchState({
            ...previousState,
            ...action.payload
        });
    }

    @Action(ProjectEditBaseActions.ResetState)
    resetState(ctx: StateContext<ProjectEditStateModel>) {
        ctx.setState(initialState);
    }

    @Action(ProjectEditBaseActions.LoadProject)
    loadProject(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditBaseActions.LoadProject) {
        ctx.patchState({ loading: true });
        return this.projectFormService.loadProjectFormValues2(action.projectId).pipe(
            tap((project: ProjectFormValuesDto) => {
                ctx.patchState({
                    ...project,
                    id: {
                        id: project.id?.id
                    },
                    name: project.name,
                    status: project.status,
                    creator: project.creator,
                    producer: project.producer,
                    createdAt: project.createdAt,
                    lastEditedAt: project.lastEditedAt,
                    lastWatchedBy: project.lastWatchedBy,
                    lastEditor: project.lastEditor,
                    finalCustomer: project.finalCustomer,
                    productionDetails: project.productionDetails,
                    budget: project.budget,
                    usageType: project.usageType,
                    executor: project.executor,
                    buyOut: project.buyOut,
                    casting: project.casting,
                    roles: project.roles,
                    selectedRole: undefined,
                    selectedRoleInProductionDetails: undefined,
                    rolesAsOptions: [],
                    projectRolesWithModels: project.projectRolesWithModels,
                    projectLocations: project.projectLocations,
                    selectedLocation: undefined,
                    selectedMotif: undefined,
                    bookingProducer: project.bookingProducer,
                    bookingModels: project.bookingModels,
                    billing: {
                        ...project.billing,
                        invoice: {
                            ...project.billing?.invoice,
                            paymentReceived: project.billing?.invoice?.paymentReceived ? new Date(project.billing?.invoice?.paymentReceived) : undefined
                        }
                    },
                    protocol: project.protocol?.protocols
                });
                ctx.patchState({ loading: false });
                ctx.dispatch(new ProjectEditAdditionalParticipantsActions.LoadAdditionalParticipants());
            })
        );
    }
}
