/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangeProductionDetailsEventCommand
 */
export interface ChangeProductionDetailsEventCommand {
    /**
     * 
     * @type {string}
     * @memberof ChangeProductionDetailsEventCommand
     */
    productionDetailsEventId?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeProductionDetailsEventCommand
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeProductionDetailsEventCommand
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeProductionDetailsEventCommand
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeProductionDetailsEventCommand
     */
    motif?: string;
}

/**
 * Check if a given object implements the ChangeProductionDetailsEventCommand interface.
 */
export function instanceOfChangeProductionDetailsEventCommand(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChangeProductionDetailsEventCommandFromJSON(json: any): ChangeProductionDetailsEventCommand {
    return ChangeProductionDetailsEventCommandFromJSONTyped(json, false);
}

export function ChangeProductionDetailsEventCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeProductionDetailsEventCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productionDetailsEventId': !exists(json, 'productionDetailsEventId') ? undefined : json['productionDetailsEventId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'startTime': !exists(json, 'startTime') ? undefined : json['startTime'],
        'motif': !exists(json, 'motif') ? undefined : json['motif'],
    };
}

export function ChangeProductionDetailsEventCommandToJSON(value?: ChangeProductionDetailsEventCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'productionDetailsEventId': value.productionDetailsEventId,
        'name': value.name,
        'description': value.description,
        'startTime': value.startTime,
        'motif': value.motif,
    };
}

