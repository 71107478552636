import { StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ProjectEditStateModel } from '../project-edit.model';
import { ProjectEditProjectActions } from '../actions/project.actions';
import { ProjectControllerService } from 'libs/api-typescript-angular/src/api/projectController.service';

@Injectable()
    export class ProjectEditProjectHandlers {
    constructor(
        private projectControllerService: ProjectControllerService
    ) {}

    updateName(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditProjectActions.UpdateName) {
        const previousState = ctx.getState();

        if (JSON.stringify(previousState.name) !== JSON.stringify(action.name)) {
            ctx.patchState({ name: action.name });

            this.projectControllerService.changeProjectName(
                previousState.id?.id!!,
                action.name
            ).subscribe();
        }
    }

    updateFinalCustomer(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditProjectActions.UpdateFinalCustomer) {
        const previousState = ctx.getState();

        if (JSON.stringify(previousState.finalCustomer) !== JSON.stringify(action.finalCustomer)) {

            ctx.patchState({ finalCustomer: action.finalCustomer });

            this.projectControllerService.changeFinalCustomer(
                previousState.id?.id!!,
                action.finalCustomer
            ).subscribe();

        }
    }

    updateProductionDetails(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditProjectActions.UpdateProductionDetails) {
        const previousState = ctx.getState();

        if (JSON.stringify(previousState.productionDetails) !== JSON.stringify(action.productionDetails)) {
            ctx.patchState({
                productionDetails: {
                    ...previousState.productionDetails,
                    ...action.productionDetails,
                    startDateIsoString: action.productionDetails.startDate ? new Date(action.productionDetails.startDate).toISOString() : undefined,
                    endDateIsoString: action.productionDetails.endDate ? new Date(action.productionDetails.endDate).toISOString() : undefined
                }
            });

            this.projectControllerService.changeProductionDetails(
                previousState.id?.id!!,
                action.productionDetails
            ).subscribe();
        }
    }

    updateBuyOut(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditProjectActions.UpdateBuyOut) {
        const previousState = ctx.getState();

        if (previousState.buyOut !== action.buyOut.buyOut) {
            ctx.patchState({ buyOut: action.buyOut.buyOut });

            this.projectControllerService.changeBuyOut(
                previousState.id?.id!!,
                action.buyOut
            ).subscribe();
        }
    }

    updateExecutor(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditProjectActions.UpdateExecutor) {
        const previousState = ctx.getState();

        if (JSON.stringify(previousState.executor) !== JSON.stringify(action.executor)) {
            ctx.patchState({ executor: action.executor });

            this.projectControllerService.changeExecutor(
                previousState.id?.id!!,
                action.executor
            ).subscribe();
        }
    }

    updateStatus(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditProjectActions.UpdateStatus) {
        const previousState = ctx.getState();

        if (JSON.stringify(previousState.status) !== JSON.stringify(action.status)) {
            ctx.patchState({ status: action.status });

            this.projectControllerService.changeProjectStatus(
                previousState.id?.id!!,
                action.status
            ).subscribe();
        }
    }
}
