import { AdminTranslations, TaskManagerTranslations } from '../../demo/service/translation.service';

export function createTaskManagerMenu(
    menuTranslations: TaskManagerTranslations,
    adminTranslations: AdminTranslations
    ): any[] {

    return [
        {
            label: menuTranslations.label,
            items: [
                {
                    label: menuTranslations.bills.label,
                    icon: 'pi pi-fw pi-money-bill',
                    routerLink: ['/taskmanager/bills']
                },
                {
                    label: menuTranslations.update_portfolio.label,
                    icon: 'pi pi-fw pi-image',
                    routerLink: ['/taskmanager/update-portfolio']
                },
                {
                    label: adminTranslations.label,
                    icon: 'pi pi-fw pi-cog',
                    items: [
                        {
                            label: adminTranslations.labelProfessions,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/professions']
                        },
                        {
                            label: adminTranslations.labelEthnicities,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/ethnicities']
                        },
                        {
                            label: adminTranslations.labelDiets,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/diets']
                        },
                        {
                            label: adminTranslations.labelDriverLicenses,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/driverlicenses']
                        },
                        {
                            label: adminTranslations.labelSex,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/sex']
                        },
                        {
                            label: adminTranslations.labelHairColors,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/haircolors']
                        },
                        {
                            label: adminTranslations.labelHairColorNuances,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/haircolornuances']
                        },
                        {
                            label: adminTranslations.labelHairLengths,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/hairlengths']
                        },
                        {
                            label: adminTranslations.labelHairStyles,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/hairstyles']
                        },
                        {
                            label: adminTranslations.labelSkinColors,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/skincolors']
                        },
                        {
                            label: adminTranslations.labelSkinTypes,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/skintypes']
                        },
                        {
                            label: adminTranslations.labelCostReasons,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/cost-reasons']
                        },
                        {
                            label: adminTranslations.labelCountries,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/countries']
                        },
                        {
                            label: adminTranslations.labelLevels,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/levels']
                        },
                        {
                            label: adminTranslations.labelCategories,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/categories']
                        },
                        {
                            label: adminTranslations.labelInstruments,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/instruments']
                        },
                        {
                            label: adminTranslations.labelPiercings,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/piercings']
                        },
                        {
                            label: adminTranslations.labelPositions,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/positions']
                        },
                        {
                            label: adminTranslations.labelExtraExpenses,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/extra-expenses']
                        },
                        {
                            label: adminTranslations.labelSpecials,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/specials']
                        },
                        {
                            label: adminTranslations.labelLanguages,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/spoken-languages']
                        },
                        {
                            label: adminTranslations.labelSportTypes,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/sporttypes']
                        },
                        {
                            label: adminTranslations.labelCities,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/cities']
                        },
                        {
                            label: adminTranslations.labelDanceStyles,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/dancestyles']
                        },
                        {
                            label: adminTranslations.labelTattoos,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/tattoos']
                        },
                        {
                            label: adminTranslations.labelActivityField,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/activity-field']
                        },
                        {
                            label: adminTranslations.labelIndustryFields,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/industry-fields']
                        },
                        {
                            label: adminTranslations.labelTransportationMeans,
                            icon: 'pi pi-fw pi-car',
                            routerLink: ['/admin/transportation-means']
                        }
                    ]
                }
            ]
        }
    ];
}
