/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ActingExperienceFormValuesDto,
  ActiveStateFormValuesDto,
  AddOptionsToRequestedTimeframesRequestDtoItem,
  AddOptionsToRequestedTimeframesResponseDtoItem,
  AdditionalAgenciesFormValuesDto,
  AddressFormValuesDto,
  AgencyBookerNamesForModelsDto,
  AgencyNamesForModelsDto,
  AnswerRequestCommand,
  Appointment,
  AppointmentChangeRequestDto,
  AppointmentCreateRequestDto,
  AppointmentDeleteRequestDto,
  AppointmentDto,
  AppointmentsResponseDto,
  BahnCardFormValuesDto,
  BankAccountInformationFormValuesDto,
  BillRequestDto,
  BodyAccessoriesFormValuesDto,
  BodyDetailsFormValuesDto,
  CastingBodyFormValuesDto,
  CategoryFormValuesDto,
  ChangeChargeableProjectPaidOnRequestDto,
  ChangeLivingAddressRequestDto,
  ChangeModelDataResponseDto,
  ColorsAndTypesFormValuesDto,
  ContactInfoFormValuesDto,
  CreateModelCostResponseDto,
  DancesFormValuesDto,
  DietFormValuesDto,
  DriversLicenseFormValuesDto,
  EmailFormValuesDto,
  GetAppointmentsRequestDto,
  IdentificationFormValuesDto,
  InstrumentsFormValuesDto,
  InsuranceFormValuesDto,
  JobFormValuesDto,
  ModelAgencyFormValuesDto,
  ModelAgencyListFormValuesDto,
  ModelConditionsFormValuesDto,
  ModelCostChangeRequestDto,
  ModelCostCreateRequestDto,
  ModelCostsResponseDto,
  ModelDataForMessenger,
  ModelMasterData,
  ModelMasterDataForMappingToProjectModel,
  ModelMasterDataFormValuesDto,
  ModelMasterDataSearchFilterDto,
  ModelMasterDataSearchFormValuesDto,
  ModelMasterDataSearchItemsDto,
  ModelMasterDataTableItemsDto,
  ModelOptionAndBookingRequestsResponseDto,
  ModelPersonalNoticeFormValuesDto,
  ModelProtocolEntryCreateRequestDto,
  ModelingExperienceFormValuesDto,
  ParentAgencyFormValuesDto,
  PersonalInfosFormValuesDto,
  ProtocolFormValuesDto,
  SexFormValuesDto,
  SpecialsFormValuesDto,
  SpokenLanguagesFormValuesDto,
  SportsFormValuesDto,
  TaxInformationFormValuesDto,
} from '../models';
import {
    ActingExperienceFormValuesDtoFromJSON,
    ActingExperienceFormValuesDtoToJSON,
    ActiveStateFormValuesDtoFromJSON,
    ActiveStateFormValuesDtoToJSON,
    AddOptionsToRequestedTimeframesRequestDtoItemFromJSON,
    AddOptionsToRequestedTimeframesRequestDtoItemToJSON,
    AddOptionsToRequestedTimeframesResponseDtoItemFromJSON,
    AddOptionsToRequestedTimeframesResponseDtoItemToJSON,
    AdditionalAgenciesFormValuesDtoFromJSON,
    AdditionalAgenciesFormValuesDtoToJSON,
    AddressFormValuesDtoFromJSON,
    AddressFormValuesDtoToJSON,
    AgencyBookerNamesForModelsDtoFromJSON,
    AgencyBookerNamesForModelsDtoToJSON,
    AgencyNamesForModelsDtoFromJSON,
    AgencyNamesForModelsDtoToJSON,
    AnswerRequestCommandFromJSON,
    AnswerRequestCommandToJSON,
    AppointmentFromJSON,
    AppointmentToJSON,
    AppointmentChangeRequestDtoFromJSON,
    AppointmentChangeRequestDtoToJSON,
    AppointmentCreateRequestDtoFromJSON,
    AppointmentCreateRequestDtoToJSON,
    AppointmentDeleteRequestDtoFromJSON,
    AppointmentDeleteRequestDtoToJSON,
    AppointmentDtoFromJSON,
    AppointmentDtoToJSON,
    AppointmentsResponseDtoFromJSON,
    AppointmentsResponseDtoToJSON,
    BahnCardFormValuesDtoFromJSON,
    BahnCardFormValuesDtoToJSON,
    BankAccountInformationFormValuesDtoFromJSON,
    BankAccountInformationFormValuesDtoToJSON,
    BillRequestDtoFromJSON,
    BillRequestDtoToJSON,
    BodyAccessoriesFormValuesDtoFromJSON,
    BodyAccessoriesFormValuesDtoToJSON,
    BodyDetailsFormValuesDtoFromJSON,
    BodyDetailsFormValuesDtoToJSON,
    CastingBodyFormValuesDtoFromJSON,
    CastingBodyFormValuesDtoToJSON,
    CategoryFormValuesDtoFromJSON,
    CategoryFormValuesDtoToJSON,
    ChangeChargeableProjectPaidOnRequestDtoFromJSON,
    ChangeChargeableProjectPaidOnRequestDtoToJSON,
    ChangeLivingAddressRequestDtoFromJSON,
    ChangeLivingAddressRequestDtoToJSON,
    ChangeModelDataResponseDtoFromJSON,
    ChangeModelDataResponseDtoToJSON,
    ColorsAndTypesFormValuesDtoFromJSON,
    ColorsAndTypesFormValuesDtoToJSON,
    ContactInfoFormValuesDtoFromJSON,
    ContactInfoFormValuesDtoToJSON,
    CreateModelCostResponseDtoFromJSON,
    CreateModelCostResponseDtoToJSON,
    DancesFormValuesDtoFromJSON,
    DancesFormValuesDtoToJSON,
    DietFormValuesDtoFromJSON,
    DietFormValuesDtoToJSON,
    DriversLicenseFormValuesDtoFromJSON,
    DriversLicenseFormValuesDtoToJSON,
    EmailFormValuesDtoFromJSON,
    EmailFormValuesDtoToJSON,
    GetAppointmentsRequestDtoFromJSON,
    GetAppointmentsRequestDtoToJSON,
    IdentificationFormValuesDtoFromJSON,
    IdentificationFormValuesDtoToJSON,
    InstrumentsFormValuesDtoFromJSON,
    InstrumentsFormValuesDtoToJSON,
    InsuranceFormValuesDtoFromJSON,
    InsuranceFormValuesDtoToJSON,
    JobFormValuesDtoFromJSON,
    JobFormValuesDtoToJSON,
    ModelAgencyFormValuesDtoFromJSON,
    ModelAgencyFormValuesDtoToJSON,
    ModelAgencyListFormValuesDtoFromJSON,
    ModelAgencyListFormValuesDtoToJSON,
    ModelConditionsFormValuesDtoFromJSON,
    ModelConditionsFormValuesDtoToJSON,
    ModelCostChangeRequestDtoFromJSON,
    ModelCostChangeRequestDtoToJSON,
    ModelCostCreateRequestDtoFromJSON,
    ModelCostCreateRequestDtoToJSON,
    ModelCostsResponseDtoFromJSON,
    ModelCostsResponseDtoToJSON,
    ModelDataForMessengerFromJSON,
    ModelDataForMessengerToJSON,
    ModelMasterDataFromJSON,
    ModelMasterDataToJSON,
    ModelMasterDataForMappingToProjectModelFromJSON,
    ModelMasterDataForMappingToProjectModelToJSON,
    ModelMasterDataFormValuesDtoFromJSON,
    ModelMasterDataFormValuesDtoToJSON,
    ModelMasterDataSearchFilterDtoFromJSON,
    ModelMasterDataSearchFilterDtoToJSON,
    ModelMasterDataSearchFormValuesDtoFromJSON,
    ModelMasterDataSearchFormValuesDtoToJSON,
    ModelMasterDataSearchItemsDtoFromJSON,
    ModelMasterDataSearchItemsDtoToJSON,
    ModelMasterDataTableItemsDtoFromJSON,
    ModelMasterDataTableItemsDtoToJSON,
    ModelOptionAndBookingRequestsResponseDtoFromJSON,
    ModelOptionAndBookingRequestsResponseDtoToJSON,
    ModelPersonalNoticeFormValuesDtoFromJSON,
    ModelPersonalNoticeFormValuesDtoToJSON,
    ModelProtocolEntryCreateRequestDtoFromJSON,
    ModelProtocolEntryCreateRequestDtoToJSON,
    ModelingExperienceFormValuesDtoFromJSON,
    ModelingExperienceFormValuesDtoToJSON,
    ParentAgencyFormValuesDtoFromJSON,
    ParentAgencyFormValuesDtoToJSON,
    PersonalInfosFormValuesDtoFromJSON,
    PersonalInfosFormValuesDtoToJSON,
    ProtocolFormValuesDtoFromJSON,
    ProtocolFormValuesDtoToJSON,
    SexFormValuesDtoFromJSON,
    SexFormValuesDtoToJSON,
    SpecialsFormValuesDtoFromJSON,
    SpecialsFormValuesDtoToJSON,
    SpokenLanguagesFormValuesDtoFromJSON,
    SpokenLanguagesFormValuesDtoToJSON,
    SportsFormValuesDtoFromJSON,
    SportsFormValuesDtoToJSON,
    TaxInformationFormValuesDtoFromJSON,
    TaxInformationFormValuesDtoToJSON,
} from '../models';

export interface AddOptionsToRequestedTimeframesRequest {
    addOptionsToRequestedTimeframesRequestDtoItem: Array<AddOptionsToRequestedTimeframesRequestDtoItem>;
}

export interface AnswerAppointmentRequestRequest {
    answerRequestCommand: AnswerRequestCommand;
}

export interface ChangeActingExperienceRequest {
    id: string;
    actingExperienceFormValuesDto: ActingExperienceFormValuesDto;
}

export interface ChangeAdditionalAgenciesRequest {
    id: string;
    additionalAgenciesFormValuesDto: AdditionalAgenciesFormValuesDto;
}

export interface ChangeAppointmentRequest {
    modelId: string;
    appointmentId: string;
    language: string;
    appointmentChangeRequestDto: AppointmentChangeRequestDto;
}

export interface ChangeBahnCardRequest {
    id: string;
    bahnCardFormValuesDto: BahnCardFormValuesDto;
}

export interface ChangeBankAccountInformationRequest {
    id: string;
    bankAccountInformationFormValuesDto: BankAccountInformationFormValuesDto;
}

export interface ChangeBodyAccessoriesRequest {
    id: string;
    bodyAccessoriesFormValuesDto: BodyAccessoriesFormValuesDto;
}

export interface ChangeCastingBodyRequest {
    id: string;
    castingBodyFormValuesDto: CastingBodyFormValuesDto;
}

export interface ChangeCategoryRequest {
    id: string;
    categoryFormValuesDto: CategoryFormValuesDto;
}

export interface ChangeChargeableProjectRequest {
    modelId: string;
    changeChargeableProjectPaidOnRequestDto: ChangeChargeableProjectPaidOnRequestDto;
}

export interface ChangeColorsAndTypesRequest {
    id: string;
    colorsAndTypesFormValuesDto: ColorsAndTypesFormValuesDto;
}

export interface ChangeContactInfoRequest {
    id: string;
    contactInfoFormValuesDto: ContactInfoFormValuesDto;
}

export interface ChangeDancesRequest {
    id: string;
    dancesFormValuesDto: DancesFormValuesDto;
}

export interface ChangeDietRequest {
    id: string;
    dietFormValuesDto: DietFormValuesDto;
}

export interface ChangeDriverLicenseRequest {
    id: string;
    driversLicenseFormValuesDto: DriversLicenseFormValuesDto;
}

export interface ChangeEmailRequest {
    id: string;
    emailFormValuesDto: EmailFormValuesDto;
}

export interface ChangeIdentificationRequest {
    id: string;
    identificationFormValuesDto: IdentificationFormValuesDto;
}

export interface ChangeInstrumentsRequest {
    id: string;
    instrumentsFormValuesDto: InstrumentsFormValuesDto;
}

export interface ChangeJobRequest {
    id: string;
    jobFormValuesDto: JobFormValuesDto;
}

export interface ChangeLivingAddressRequest {
    id: string;
    changeLivingAddressRequestDto: ChangeLivingAddressRequestDto;
}

export interface ChangeMeasurementRequest {
    id: string;
    bodyDetailsFormValuesDto: BodyDetailsFormValuesDto;
}

export interface ChangeModelAgencyDataRequest {
    modelId: string;
    modelAgencyFormValuesDto: ModelAgencyFormValuesDto;
}

export interface ChangeModelConditionsRequest {
    id: string;
    modelConditionsFormValuesDto: ModelConditionsFormValuesDto;
}

export interface ChangeModelCostRequest {
    modelCostChangeRequestDto: ModelCostChangeRequestDto;
}

export interface ChangeModelExperienceRequest {
    id: string;
    modelingExperienceFormValuesDto: ModelingExperienceFormValuesDto;
}

export interface ChangeModelInsuranceRequest {
    id: string;
    insuranceFormValuesDto: InsuranceFormValuesDto;
}

export interface ChangeModelPersonalNoticeRequest {
    id: string;
    modelPersonalNoticeFormValuesDto: ModelPersonalNoticeFormValuesDto;
}

export interface ChangeNameRequest {
    id: string;
    personalInfosFormValuesDto: PersonalInfosFormValuesDto;
}

export interface ChangeNotifyAddressRequest {
    id: string;
    addressFormValuesDto: AddressFormValuesDto;
}

export interface ChangeParentAgencyRequest {
    id: string;
    parentAgencyFormValuesDto: ParentAgencyFormValuesDto;
}

export interface ChangeSexRequest {
    id: string;
    sexFormValuesDto: SexFormValuesDto;
}

export interface ChangeSpecialsRequest {
    id: string;
    specialsFormValuesDto: SpecialsFormValuesDto;
}

export interface ChangeSpokenLanguagesRequest {
    id: string;
    spokenLanguagesFormValuesDto: SpokenLanguagesFormValuesDto;
}

export interface ChangeSportsRequest {
    id: string;
    sportsFormValuesDto: SportsFormValuesDto;
}

export interface ChangeTaxInformationRequest {
    id: string;
    taxInformationFormValuesDto: TaxInformationFormValuesDto;
}

export interface CreateAgencyRequest {
    modelId: string;
    language: string;
    modelAgencyFormValuesDto: ModelAgencyFormValuesDto;
}

export interface CreateAppointmentRequest {
    appointmentCreateRequestDto: AppointmentCreateRequestDto;
}

export interface CreateModelCostRequest {
    modelCostCreateRequestDto: ModelCostCreateRequestDto;
}

export interface CreateProjectRelatedAppointmentRequest {
    language: string;
    projectId: string;
    appointmentCreateRequestDto: AppointmentCreateRequestDto;
}

export interface CreateProtocolEntry1Request {
    modelProtocolEntryCreateRequestDto: ModelProtocolEntryCreateRequestDto;
}

export interface DeleteAppointmentRequest {
    appointmentDeleteRequestDto: AppointmentDeleteRequestDto;
}

export interface DeleteModelRequest {
    id: string;
}

export interface DeleteModelAgencyRequest {
    modelId: string;
    agencyId: string;
}

export interface GetAgenciesRequest {
    modelId: string;
    language: string;
}

export interface GetAgencyBookerNamesRequest {
    agencyId: string;
}

export interface GetAllModelsForMappingToProjectModelByModelIdRequest {
    requestBody: Array<string>;
}

export interface GetAppointmentByAppointmentIdRequest {
    id: string;
}

export interface GetBillPreview1Request {
    modelId: string;
    billRequestDto: BillRequestDto;
}

export interface GetJobsForModelRequest {
    modelId: string;
}

export interface GetModelAppointmentsRequest {
    modelId: string;
    getAppointmentsRequestDto: GetAppointmentsRequestDto;
}

export interface GetModelByBrodyReferenceIdRequest {
    modelId: number;
}

export interface GetModelByIdRequest {
    modelId: string;
}

export interface GetModelBySearchTermFormMessengerRequest {
    searchTerm: string;
}

export interface GetModelCostsRequest {
    modelId: string;
}

export interface GetModelFormValuesRequest {
    lang: string;
}

export interface GetModelFormValues1Request {
    lang: string;
    modelId: string;
}

export interface GetModelsForSearchRequest {
    modelMasterDataSearchFilterDto: ModelMasterDataSearchFilterDto;
}

export interface GetModelsSearchFormValues1Request {
    lang: string;
}

export interface SetModelStatusRequest {
    id: string;
    activeStateFormValuesDto: ActiveStateFormValuesDto;
}

/**
 * 
 */
export class ModelMasterDataControllerApi extends runtime.BaseAPI {

    /**
     */
    async addOptionsToRequestedTimeframesRaw(requestParameters: AddOptionsToRequestedTimeframesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AddOptionsToRequestedTimeframesResponseDtoItem>>> {
        if (requestParameters.addOptionsToRequestedTimeframesRequestDtoItem === null || requestParameters.addOptionsToRequestedTimeframesRequestDtoItem === undefined) {
            throw new runtime.RequiredError('addOptionsToRequestedTimeframesRequestDtoItem','Required parameter requestParameters.addOptionsToRequestedTimeframesRequestDtoItem was null or undefined when calling addOptionsToRequestedTimeframes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/jobs/add-option`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.addOptionsToRequestedTimeframesRequestDtoItem.map(AddOptionsToRequestedTimeframesRequestDtoItemToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AddOptionsToRequestedTimeframesResponseDtoItemFromJSON));
    }

    /**
     */
    async addOptionsToRequestedTimeframes(requestParameters: AddOptionsToRequestedTimeframesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AddOptionsToRequestedTimeframesResponseDtoItem>> {
        const response = await this.addOptionsToRequestedTimeframesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async answerAppointmentRequestRaw(requestParameters: AnswerAppointmentRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AppointmentDto>> {
        if (requestParameters.answerRequestCommand === null || requestParameters.answerRequestCommand === undefined) {
            throw new runtime.RequiredError('answerRequestCommand','Required parameter requestParameters.answerRequestCommand was null or undefined when calling answerAppointmentRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/appointment/answer`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AnswerRequestCommandToJSON(requestParameters.answerRequestCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppointmentDtoFromJSON(jsonValue));
    }

    /**
     */
    async answerAppointmentRequest(requestParameters: AnswerAppointmentRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AppointmentDto> {
        const response = await this.answerAppointmentRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeActingExperienceRaw(requestParameters: ChangeActingExperienceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeActingExperience.');
        }

        if (requestParameters.actingExperienceFormValuesDto === null || requestParameters.actingExperienceFormValuesDto === undefined) {
            throw new runtime.RequiredError('actingExperienceFormValuesDto','Required parameter requestParameters.actingExperienceFormValuesDto was null or undefined when calling changeActingExperience.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/change/acting-experience/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ActingExperienceFormValuesDtoToJSON(requestParameters.actingExperienceFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeActingExperience(requestParameters: ChangeActingExperienceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeActingExperienceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeAdditionalAgenciesRaw(requestParameters: ChangeAdditionalAgenciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeAdditionalAgencies.');
        }

        if (requestParameters.additionalAgenciesFormValuesDto === null || requestParameters.additionalAgenciesFormValuesDto === undefined) {
            throw new runtime.RequiredError('additionalAgenciesFormValuesDto','Required parameter requestParameters.additionalAgenciesFormValuesDto was null or undefined when calling changeAdditionalAgencies.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/change/additional-agencies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdditionalAgenciesFormValuesDtoToJSON(requestParameters.additionalAgenciesFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeAdditionalAgencies(requestParameters: ChangeAdditionalAgenciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeAdditionalAgenciesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeAppointmentRaw(requestParameters: ChangeAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AppointmentDto>> {
        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling changeAppointment.');
        }

        if (requestParameters.appointmentId === null || requestParameters.appointmentId === undefined) {
            throw new runtime.RequiredError('appointmentId','Required parameter requestParameters.appointmentId was null or undefined when calling changeAppointment.');
        }

        if (requestParameters.language === null || requestParameters.language === undefined) {
            throw new runtime.RequiredError('language','Required parameter requestParameters.language was null or undefined when calling changeAppointment.');
        }

        if (requestParameters.appointmentChangeRequestDto === null || requestParameters.appointmentChangeRequestDto === undefined) {
            throw new runtime.RequiredError('appointmentChangeRequestDto','Required parameter requestParameters.appointmentChangeRequestDto was null or undefined when calling changeAppointment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/{modelId}/appointment/{appointmentId}/change/{language}`.replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))).replace(`{${"appointmentId"}}`, encodeURIComponent(String(requestParameters.appointmentId))).replace(`{${"language"}}`, encodeURIComponent(String(requestParameters.language))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AppointmentChangeRequestDtoToJSON(requestParameters.appointmentChangeRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppointmentDtoFromJSON(jsonValue));
    }

    /**
     */
    async changeAppointment(requestParameters: ChangeAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AppointmentDto> {
        const response = await this.changeAppointmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeBahnCardRaw(requestParameters: ChangeBahnCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeBahnCard.');
        }

        if (requestParameters.bahnCardFormValuesDto === null || requestParameters.bahnCardFormValuesDto === undefined) {
            throw new runtime.RequiredError('bahnCardFormValuesDto','Required parameter requestParameters.bahnCardFormValuesDto was null or undefined when calling changeBahnCard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/change/bahncard/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BahnCardFormValuesDtoToJSON(requestParameters.bahnCardFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeBahnCard(requestParameters: ChangeBahnCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeBahnCardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeBankAccountInformationRaw(requestParameters: ChangeBankAccountInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeBankAccountInformation.');
        }

        if (requestParameters.bankAccountInformationFormValuesDto === null || requestParameters.bankAccountInformationFormValuesDto === undefined) {
            throw new runtime.RequiredError('bankAccountInformationFormValuesDto','Required parameter requestParameters.bankAccountInformationFormValuesDto was null or undefined when calling changeBankAccountInformation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/change/bank-account-information/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BankAccountInformationFormValuesDtoToJSON(requestParameters.bankAccountInformationFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeBankAccountInformation(requestParameters: ChangeBankAccountInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeBankAccountInformationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeBodyAccessoriesRaw(requestParameters: ChangeBodyAccessoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeBodyAccessories.');
        }

        if (requestParameters.bodyAccessoriesFormValuesDto === null || requestParameters.bodyAccessoriesFormValuesDto === undefined) {
            throw new runtime.RequiredError('bodyAccessoriesFormValuesDto','Required parameter requestParameters.bodyAccessoriesFormValuesDto was null or undefined when calling changeBodyAccessories.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/change/body-accessories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BodyAccessoriesFormValuesDtoToJSON(requestParameters.bodyAccessoriesFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeBodyAccessories(requestParameters: ChangeBodyAccessoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeBodyAccessoriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeCastingBodyRaw(requestParameters: ChangeCastingBodyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeCastingBody.');
        }

        if (requestParameters.castingBodyFormValuesDto === null || requestParameters.castingBodyFormValuesDto === undefined) {
            throw new runtime.RequiredError('castingBodyFormValuesDto','Required parameter requestParameters.castingBodyFormValuesDto was null or undefined when calling changeCastingBody.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/change/casting-body/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CastingBodyFormValuesDtoToJSON(requestParameters.castingBodyFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeCastingBody(requestParameters: ChangeCastingBodyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeCastingBodyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeCategoryRaw(requestParameters: ChangeCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeCategory.');
        }

        if (requestParameters.categoryFormValuesDto === null || requestParameters.categoryFormValuesDto === undefined) {
            throw new runtime.RequiredError('categoryFormValuesDto','Required parameter requestParameters.categoryFormValuesDto was null or undefined when calling changeCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/change/categories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CategoryFormValuesDtoToJSON(requestParameters.categoryFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeCategory(requestParameters: ChangeCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeChargeableProjectRaw(requestParameters: ChangeChargeableProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling changeChargeableProject.');
        }

        if (requestParameters.changeChargeableProjectPaidOnRequestDto === null || requestParameters.changeChargeableProjectPaidOnRequestDto === undefined) {
            throw new runtime.RequiredError('changeChargeableProjectPaidOnRequestDto','Required parameter requestParameters.changeChargeableProjectPaidOnRequestDto was null or undefined when calling changeChargeableProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/change/chargeable-project/{modelId}`.replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeChargeableProjectPaidOnRequestDtoToJSON(requestParameters.changeChargeableProjectPaidOnRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeChargeableProject(requestParameters: ChangeChargeableProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeChargeableProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeColorsAndTypesRaw(requestParameters: ChangeColorsAndTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeColorsAndTypes.');
        }

        if (requestParameters.colorsAndTypesFormValuesDto === null || requestParameters.colorsAndTypesFormValuesDto === undefined) {
            throw new runtime.RequiredError('colorsAndTypesFormValuesDto','Required parameter requestParameters.colorsAndTypesFormValuesDto was null or undefined when calling changeColorsAndTypes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/change/colors-and-types/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ColorsAndTypesFormValuesDtoToJSON(requestParameters.colorsAndTypesFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeColorsAndTypes(requestParameters: ChangeColorsAndTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeColorsAndTypesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeContactInfoRaw(requestParameters: ChangeContactInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeContactInfo.');
        }

        if (requestParameters.contactInfoFormValuesDto === null || requestParameters.contactInfoFormValuesDto === undefined) {
            throw new runtime.RequiredError('contactInfoFormValuesDto','Required parameter requestParameters.contactInfoFormValuesDto was null or undefined when calling changeContactInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/change/contact-info/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContactInfoFormValuesDtoToJSON(requestParameters.contactInfoFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeContactInfo(requestParameters: ChangeContactInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeContactInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeDancesRaw(requestParameters: ChangeDancesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeDances.');
        }

        if (requestParameters.dancesFormValuesDto === null || requestParameters.dancesFormValuesDto === undefined) {
            throw new runtime.RequiredError('dancesFormValuesDto','Required parameter requestParameters.dancesFormValuesDto was null or undefined when calling changeDances.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/change/dances/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DancesFormValuesDtoToJSON(requestParameters.dancesFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeDances(requestParameters: ChangeDancesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeDancesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeDietRaw(requestParameters: ChangeDietRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeDiet.');
        }

        if (requestParameters.dietFormValuesDto === null || requestParameters.dietFormValuesDto === undefined) {
            throw new runtime.RequiredError('dietFormValuesDto','Required parameter requestParameters.dietFormValuesDto was null or undefined when calling changeDiet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/change/diet/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DietFormValuesDtoToJSON(requestParameters.dietFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeDiet(requestParameters: ChangeDietRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeDietRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeDriverLicenseRaw(requestParameters: ChangeDriverLicenseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeDriverLicense.');
        }

        if (requestParameters.driversLicenseFormValuesDto === null || requestParameters.driversLicenseFormValuesDto === undefined) {
            throw new runtime.RequiredError('driversLicenseFormValuesDto','Required parameter requestParameters.driversLicenseFormValuesDto was null or undefined when calling changeDriverLicense.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/change/driver-license/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriversLicenseFormValuesDtoToJSON(requestParameters.driversLicenseFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeDriverLicense(requestParameters: ChangeDriverLicenseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeDriverLicenseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeEmailRaw(requestParameters: ChangeEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeEmail.');
        }

        if (requestParameters.emailFormValuesDto === null || requestParameters.emailFormValuesDto === undefined) {
            throw new runtime.RequiredError('emailFormValuesDto','Required parameter requestParameters.emailFormValuesDto was null or undefined when calling changeEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/change/email/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmailFormValuesDtoToJSON(requestParameters.emailFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeEmail(requestParameters: ChangeEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeIdentificationRaw(requestParameters: ChangeIdentificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeIdentification.');
        }

        if (requestParameters.identificationFormValuesDto === null || requestParameters.identificationFormValuesDto === undefined) {
            throw new runtime.RequiredError('identificationFormValuesDto','Required parameter requestParameters.identificationFormValuesDto was null or undefined when calling changeIdentification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/change/identification/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IdentificationFormValuesDtoToJSON(requestParameters.identificationFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeIdentification(requestParameters: ChangeIdentificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeIdentificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeInstrumentsRaw(requestParameters: ChangeInstrumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeInstruments.');
        }

        if (requestParameters.instrumentsFormValuesDto === null || requestParameters.instrumentsFormValuesDto === undefined) {
            throw new runtime.RequiredError('instrumentsFormValuesDto','Required parameter requestParameters.instrumentsFormValuesDto was null or undefined when calling changeInstruments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/change/instruments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InstrumentsFormValuesDtoToJSON(requestParameters.instrumentsFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeInstruments(requestParameters: ChangeInstrumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeInstrumentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeJobRaw(requestParameters: ChangeJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeJob.');
        }

        if (requestParameters.jobFormValuesDto === null || requestParameters.jobFormValuesDto === undefined) {
            throw new runtime.RequiredError('jobFormValuesDto','Required parameter requestParameters.jobFormValuesDto was null or undefined when calling changeJob.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/change/job/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JobFormValuesDtoToJSON(requestParameters.jobFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeJob(requestParameters: ChangeJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeJobRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeLivingAddressRaw(requestParameters: ChangeLivingAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChangeModelDataResponseDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeLivingAddress.');
        }

        if (requestParameters.changeLivingAddressRequestDto === null || requestParameters.changeLivingAddressRequestDto === undefined) {
            throw new runtime.RequiredError('changeLivingAddressRequestDto','Required parameter requestParameters.changeLivingAddressRequestDto was null or undefined when calling changeLivingAddress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/change/living-address/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeLivingAddressRequestDtoToJSON(requestParameters.changeLivingAddressRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChangeModelDataResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async changeLivingAddress(requestParameters: ChangeLivingAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChangeModelDataResponseDto> {
        const response = await this.changeLivingAddressRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeMeasurementRaw(requestParameters: ChangeMeasurementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeMeasurement.');
        }

        if (requestParameters.bodyDetailsFormValuesDto === null || requestParameters.bodyDetailsFormValuesDto === undefined) {
            throw new runtime.RequiredError('bodyDetailsFormValuesDto','Required parameter requestParameters.bodyDetailsFormValuesDto was null or undefined when calling changeMeasurement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/change/measurement/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BodyDetailsFormValuesDtoToJSON(requestParameters.bodyDetailsFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeMeasurement(requestParameters: ChangeMeasurementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeMeasurementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeModelAgencyDataRaw(requestParameters: ChangeModelAgencyDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling changeModelAgencyData.');
        }

        if (requestParameters.modelAgencyFormValuesDto === null || requestParameters.modelAgencyFormValuesDto === undefined) {
            throw new runtime.RequiredError('modelAgencyFormValuesDto','Required parameter requestParameters.modelAgencyFormValuesDto was null or undefined when calling changeModelAgencyData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/change/model-agency/{modelId}`.replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ModelAgencyFormValuesDtoToJSON(requestParameters.modelAgencyFormValuesDto),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async changeModelAgencyData(requestParameters: ChangeModelAgencyDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.changeModelAgencyDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeModelConditionsRaw(requestParameters: ChangeModelConditionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeModelConditions.');
        }

        if (requestParameters.modelConditionsFormValuesDto === null || requestParameters.modelConditionsFormValuesDto === undefined) {
            throw new runtime.RequiredError('modelConditionsFormValuesDto','Required parameter requestParameters.modelConditionsFormValuesDto was null or undefined when calling changeModelConditions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/change/model-conditions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ModelConditionsFormValuesDtoToJSON(requestParameters.modelConditionsFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeModelConditions(requestParameters: ChangeModelConditionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeModelConditionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeModelCostRaw(requestParameters: ChangeModelCostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.modelCostChangeRequestDto === null || requestParameters.modelCostChangeRequestDto === undefined) {
            throw new runtime.RequiredError('modelCostChangeRequestDto','Required parameter requestParameters.modelCostChangeRequestDto was null or undefined when calling changeModelCost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/model-cost/change`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ModelCostChangeRequestDtoToJSON(requestParameters.modelCostChangeRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeModelCost(requestParameters: ChangeModelCostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeModelCostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeModelExperienceRaw(requestParameters: ChangeModelExperienceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeModelExperience.');
        }

        if (requestParameters.modelingExperienceFormValuesDto === null || requestParameters.modelingExperienceFormValuesDto === undefined) {
            throw new runtime.RequiredError('modelingExperienceFormValuesDto','Required parameter requestParameters.modelingExperienceFormValuesDto was null or undefined when calling changeModelExperience.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/change/model-experience/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ModelingExperienceFormValuesDtoToJSON(requestParameters.modelingExperienceFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeModelExperience(requestParameters: ChangeModelExperienceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeModelExperienceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeModelInsuranceRaw(requestParameters: ChangeModelInsuranceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeModelInsurance.');
        }

        if (requestParameters.insuranceFormValuesDto === null || requestParameters.insuranceFormValuesDto === undefined) {
            throw new runtime.RequiredError('insuranceFormValuesDto','Required parameter requestParameters.insuranceFormValuesDto was null or undefined when calling changeModelInsurance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/change/insurance/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InsuranceFormValuesDtoToJSON(requestParameters.insuranceFormValuesDto),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async changeModelInsurance(requestParameters: ChangeModelInsuranceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.changeModelInsuranceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeModelPersonalNoticeRaw(requestParameters: ChangeModelPersonalNoticeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeModelPersonalNotice.');
        }

        if (requestParameters.modelPersonalNoticeFormValuesDto === null || requestParameters.modelPersonalNoticeFormValuesDto === undefined) {
            throw new runtime.RequiredError('modelPersonalNoticeFormValuesDto','Required parameter requestParameters.modelPersonalNoticeFormValuesDto was null or undefined when calling changeModelPersonalNotice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/change/model-personal-notice/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ModelPersonalNoticeFormValuesDtoToJSON(requestParameters.modelPersonalNoticeFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeModelPersonalNotice(requestParameters: ChangeModelPersonalNoticeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeModelPersonalNoticeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeNameRaw(requestParameters: ChangeNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeName.');
        }

        if (requestParameters.personalInfosFormValuesDto === null || requestParameters.personalInfosFormValuesDto === undefined) {
            throw new runtime.RequiredError('personalInfosFormValuesDto','Required parameter requestParameters.personalInfosFormValuesDto was null or undefined when calling changeName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/change/name/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PersonalInfosFormValuesDtoToJSON(requestParameters.personalInfosFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeName(requestParameters: ChangeNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeNotifyAddressRaw(requestParameters: ChangeNotifyAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeNotifyAddress.');
        }

        if (requestParameters.addressFormValuesDto === null || requestParameters.addressFormValuesDto === undefined) {
            throw new runtime.RequiredError('addressFormValuesDto','Required parameter requestParameters.addressFormValuesDto was null or undefined when calling changeNotifyAddress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/change/notify-address/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddressFormValuesDtoToJSON(requestParameters.addressFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeNotifyAddress(requestParameters: ChangeNotifyAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeNotifyAddressRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeParentAgencyRaw(requestParameters: ChangeParentAgencyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeParentAgency.');
        }

        if (requestParameters.parentAgencyFormValuesDto === null || requestParameters.parentAgencyFormValuesDto === undefined) {
            throw new runtime.RequiredError('parentAgencyFormValuesDto','Required parameter requestParameters.parentAgencyFormValuesDto was null or undefined when calling changeParentAgency.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/change/parent-agency/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ParentAgencyFormValuesDtoToJSON(requestParameters.parentAgencyFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeParentAgency(requestParameters: ChangeParentAgencyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeParentAgencyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeSexRaw(requestParameters: ChangeSexRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeSex.');
        }

        if (requestParameters.sexFormValuesDto === null || requestParameters.sexFormValuesDto === undefined) {
            throw new runtime.RequiredError('sexFormValuesDto','Required parameter requestParameters.sexFormValuesDto was null or undefined when calling changeSex.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/change/sex/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SexFormValuesDtoToJSON(requestParameters.sexFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeSex(requestParameters: ChangeSexRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeSexRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeSpecialsRaw(requestParameters: ChangeSpecialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeSpecials.');
        }

        if (requestParameters.specialsFormValuesDto === null || requestParameters.specialsFormValuesDto === undefined) {
            throw new runtime.RequiredError('specialsFormValuesDto','Required parameter requestParameters.specialsFormValuesDto was null or undefined when calling changeSpecials.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/change/specials/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SpecialsFormValuesDtoToJSON(requestParameters.specialsFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeSpecials(requestParameters: ChangeSpecialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeSpecialsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeSpokenLanguagesRaw(requestParameters: ChangeSpokenLanguagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeSpokenLanguages.');
        }

        if (requestParameters.spokenLanguagesFormValuesDto === null || requestParameters.spokenLanguagesFormValuesDto === undefined) {
            throw new runtime.RequiredError('spokenLanguagesFormValuesDto','Required parameter requestParameters.spokenLanguagesFormValuesDto was null or undefined when calling changeSpokenLanguages.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/change/spoken-language/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SpokenLanguagesFormValuesDtoToJSON(requestParameters.spokenLanguagesFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeSpokenLanguages(requestParameters: ChangeSpokenLanguagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeSpokenLanguagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeSportsRaw(requestParameters: ChangeSportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeSports.');
        }

        if (requestParameters.sportsFormValuesDto === null || requestParameters.sportsFormValuesDto === undefined) {
            throw new runtime.RequiredError('sportsFormValuesDto','Required parameter requestParameters.sportsFormValuesDto was null or undefined when calling changeSports.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/change/sports/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SportsFormValuesDtoToJSON(requestParameters.sportsFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeSports(requestParameters: ChangeSportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeSportsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeTaxInformationRaw(requestParameters: ChangeTaxInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeTaxInformation.');
        }

        if (requestParameters.taxInformationFormValuesDto === null || requestParameters.taxInformationFormValuesDto === undefined) {
            throw new runtime.RequiredError('taxInformationFormValuesDto','Required parameter requestParameters.taxInformationFormValuesDto was null or undefined when calling changeTaxInformation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/change/tax-information/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TaxInformationFormValuesDtoToJSON(requestParameters.taxInformationFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeTaxInformation(requestParameters: ChangeTaxInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeTaxInformationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createAgencyRaw(requestParameters: CreateAgencyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ModelAgencyFormValuesDto>> {
        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling createAgency.');
        }

        if (requestParameters.language === null || requestParameters.language === undefined) {
            throw new runtime.RequiredError('language','Required parameter requestParameters.language was null or undefined when calling createAgency.');
        }

        if (requestParameters.modelAgencyFormValuesDto === null || requestParameters.modelAgencyFormValuesDto === undefined) {
            throw new runtime.RequiredError('modelAgencyFormValuesDto','Required parameter requestParameters.modelAgencyFormValuesDto was null or undefined when calling createAgency.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/createAgency/{modelId}/{language}`.replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))).replace(`{${"language"}}`, encodeURIComponent(String(requestParameters.language))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ModelAgencyFormValuesDtoToJSON(requestParameters.modelAgencyFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelAgencyFormValuesDtoFromJSON(jsonValue));
    }

    /**
     */
    async createAgency(requestParameters: CreateAgencyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ModelAgencyFormValuesDto> {
        const response = await this.createAgencyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createAppointmentRaw(requestParameters: CreateAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AppointmentDto>> {
        if (requestParameters.appointmentCreateRequestDto === null || requestParameters.appointmentCreateRequestDto === undefined) {
            throw new runtime.RequiredError('appointmentCreateRequestDto','Required parameter requestParameters.appointmentCreateRequestDto was null or undefined when calling createAppointment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/appointment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AppointmentCreateRequestDtoToJSON(requestParameters.appointmentCreateRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppointmentDtoFromJSON(jsonValue));
    }

    /**
     */
    async createAppointment(requestParameters: CreateAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AppointmentDto> {
        const response = await this.createAppointmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createModelRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ModelMasterData>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/model-master-data/createModel`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelMasterDataFromJSON(jsonValue));
    }

    /**
     */
    async createModel(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ModelMasterData> {
        const response = await this.createModelRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async createModelCostRaw(requestParameters: CreateModelCostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateModelCostResponseDto>> {
        if (requestParameters.modelCostCreateRequestDto === null || requestParameters.modelCostCreateRequestDto === undefined) {
            throw new runtime.RequiredError('modelCostCreateRequestDto','Required parameter requestParameters.modelCostCreateRequestDto was null or undefined when calling createModelCost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/model-cost`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ModelCostCreateRequestDtoToJSON(requestParameters.modelCostCreateRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateModelCostResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async createModelCost(requestParameters: CreateModelCostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateModelCostResponseDto> {
        const response = await this.createModelCostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createProjectRelatedAppointmentRaw(requestParameters: CreateProjectRelatedAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AppointmentDto>> {
        if (requestParameters.language === null || requestParameters.language === undefined) {
            throw new runtime.RequiredError('language','Required parameter requestParameters.language was null or undefined when calling createProjectRelatedAppointment.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling createProjectRelatedAppointment.');
        }

        if (requestParameters.appointmentCreateRequestDto === null || requestParameters.appointmentCreateRequestDto === undefined) {
            throw new runtime.RequiredError('appointmentCreateRequestDto','Required parameter requestParameters.appointmentCreateRequestDto was null or undefined when calling createProjectRelatedAppointment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/appointment/{language}/{projectId}`.replace(`{${"language"}}`, encodeURIComponent(String(requestParameters.language))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AppointmentCreateRequestDtoToJSON(requestParameters.appointmentCreateRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppointmentDtoFromJSON(jsonValue));
    }

    /**
     */
    async createProjectRelatedAppointment(requestParameters: CreateProjectRelatedAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AppointmentDto> {
        const response = await this.createProjectRelatedAppointmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createProtocolEntry1Raw(requestParameters: CreateProtocolEntry1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProtocolFormValuesDto>> {
        if (requestParameters.modelProtocolEntryCreateRequestDto === null || requestParameters.modelProtocolEntryCreateRequestDto === undefined) {
            throw new runtime.RequiredError('modelProtocolEntryCreateRequestDto','Required parameter requestParameters.modelProtocolEntryCreateRequestDto was null or undefined when calling createProtocolEntry1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/create/protocol`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ModelProtocolEntryCreateRequestDtoToJSON(requestParameters.modelProtocolEntryCreateRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProtocolFormValuesDtoFromJSON(jsonValue));
    }

    /**
     */
    async createProtocolEntry1(requestParameters: CreateProtocolEntry1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProtocolFormValuesDto> {
        const response = await this.createProtocolEntry1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteAppointmentRaw(requestParameters: DeleteAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.appointmentDeleteRequestDto === null || requestParameters.appointmentDeleteRequestDto === undefined) {
            throw new runtime.RequiredError('appointmentDeleteRequestDto','Required parameter requestParameters.appointmentDeleteRequestDto was null or undefined when calling deleteAppointment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/appointment/delete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AppointmentDeleteRequestDtoToJSON(requestParameters.appointmentDeleteRequestDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteAppointment(requestParameters: DeleteAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAppointmentRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteModelRaw(requestParameters: DeleteModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteModel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/model-master-data/delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async deleteModel(requestParameters: DeleteModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.deleteModelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteModelAgencyRaw(requestParameters: DeleteModelAgencyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling deleteModelAgency.');
        }

        if (requestParameters.agencyId === null || requestParameters.agencyId === undefined) {
            throw new runtime.RequiredError('agencyId','Required parameter requestParameters.agencyId was null or undefined when calling deleteModelAgency.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/model-master-data/model-agency/{modelId}/{agencyId}`.replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))).replace(`{${"agencyId"}}`, encodeURIComponent(String(requestParameters.agencyId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async deleteModelAgency(requestParameters: DeleteModelAgencyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.deleteModelAgencyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAgenciesRaw(requestParameters: GetAgenciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ModelAgencyListFormValuesDto>> {
        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling getAgencies.');
        }

        if (requestParameters.language === null || requestParameters.language === undefined) {
            throw new runtime.RequiredError('language','Required parameter requestParameters.language was null or undefined when calling getAgencies.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/model-master-data/agency/{modelId}/{language}`.replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))).replace(`{${"language"}}`, encodeURIComponent(String(requestParameters.language))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelAgencyListFormValuesDtoFromJSON(jsonValue));
    }

    /**
     */
    async getAgencies(requestParameters: GetAgenciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ModelAgencyListFormValuesDto> {
        const response = await this.getAgenciesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAgencyBookerNamesRaw(requestParameters: GetAgencyBookerNamesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AgencyBookerNamesForModelsDto>>> {
        if (requestParameters.agencyId === null || requestParameters.agencyId === undefined) {
            throw new runtime.RequiredError('agencyId','Required parameter requestParameters.agencyId was null or undefined when calling getAgencyBookerNames.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/model-master-data/search/agency/booker/{agencyId}`.replace(`{${"agencyId"}}`, encodeURIComponent(String(requestParameters.agencyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AgencyBookerNamesForModelsDtoFromJSON));
    }

    /**
     */
    async getAgencyBookerNames(requestParameters: GetAgencyBookerNamesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AgencyBookerNamesForModelsDto>> {
        const response = await this.getAgencyBookerNamesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAgencyNamesForModelsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AgencyNamesForModelsDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/model-master-data/search/agencies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AgencyNamesForModelsDtoFromJSON));
    }

    /**
     */
    async getAgencyNamesForModels(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AgencyNamesForModelsDto>> {
        const response = await this.getAgencyNamesForModelsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getAllModelsForMappingToProjectModelByModelIdRaw(requestParameters: GetAllModelsForMappingToProjectModelByModelIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ModelMasterDataForMappingToProjectModel>>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling getAllModelsForMappingToProjectModelByModelId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/models/by-ids`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ModelMasterDataForMappingToProjectModelFromJSON));
    }

    /**
     */
    async getAllModelsForMappingToProjectModelByModelId(requestParameters: GetAllModelsForMappingToProjectModelByModelIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ModelMasterDataForMappingToProjectModel>> {
        const response = await this.getAllModelsForMappingToProjectModelByModelIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAppointmentByAppointmentIdRaw(requestParameters: GetAppointmentByAppointmentIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Appointment>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAppointmentByAppointmentId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/model-master-data/appointment/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppointmentFromJSON(jsonValue));
    }

    /**
     */
    async getAppointmentByAppointmentId(requestParameters: GetAppointmentByAppointmentIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Appointment> {
        const response = await this.getAppointmentByAppointmentIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getBillPreview1Raw(requestParameters: GetBillPreview1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling getBillPreview1.');
        }

        if (requestParameters.billRequestDto === null || requestParameters.billRequestDto === undefined) {
            throw new runtime.RequiredError('billRequestDto','Required parameter requestParameters.billRequestDto was null or undefined when calling getBillPreview1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/{modelId}/bill/preview`.replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BillRequestDtoToJSON(requestParameters.billRequestDto),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async getBillPreview1(requestParameters: GetBillPreview1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getBillPreview1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getJobsForModelRaw(requestParameters: GetJobsForModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ModelOptionAndBookingRequestsResponseDto>> {
        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling getJobsForModel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/model-master-data/jobs/{modelId}`.replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelOptionAndBookingRequestsResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async getJobsForModel(requestParameters: GetJobsForModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ModelOptionAndBookingRequestsResponseDto> {
        const response = await this.getJobsForModelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getModelAppointmentsRaw(requestParameters: GetModelAppointmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AppointmentsResponseDto>> {
        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling getModelAppointments.');
        }

        if (requestParameters.getAppointmentsRequestDto === null || requestParameters.getAppointmentsRequestDto === undefined) {
            throw new runtime.RequiredError('getAppointmentsRequestDto','Required parameter requestParameters.getAppointmentsRequestDto was null or undefined when calling getModelAppointments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/appointments/{modelId}`.replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAppointmentsRequestDtoToJSON(requestParameters.getAppointmentsRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppointmentsResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async getModelAppointments(requestParameters: GetModelAppointmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AppointmentsResponseDto> {
        const response = await this.getModelAppointmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getModelByBrodyReferenceIdRaw(requestParameters: GetModelByBrodyReferenceIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ModelMasterData>> {
        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling getModelByBrodyReferenceId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/model-master-data/model/by-brody-reference/{modelId}`.replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelMasterDataFromJSON(jsonValue));
    }

    /**
     */
    async getModelByBrodyReferenceId(requestParameters: GetModelByBrodyReferenceIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ModelMasterData> {
        const response = await this.getModelByBrodyReferenceIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getModelByIdRaw(requestParameters: GetModelByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ModelMasterData>> {
        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling getModelById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/model-master-data/model/{modelId}`.replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelMasterDataFromJSON(jsonValue));
    }

    /**
     */
    async getModelById(requestParameters: GetModelByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ModelMasterData> {
        const response = await this.getModelByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getModelBySearchTermFormMessengerRaw(requestParameters: GetModelBySearchTermFormMessengerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ModelDataForMessenger>>> {
        if (requestParameters.searchTerm === null || requestParameters.searchTerm === undefined) {
            throw new runtime.RequiredError('searchTerm','Required parameter requestParameters.searchTerm was null or undefined when calling getModelBySearchTermFormMessenger.');
        }

        const queryParameters: any = {};

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['searchTerm'] = requestParameters.searchTerm;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/model-master-data/model/messenger/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ModelDataForMessengerFromJSON));
    }

    /**
     */
    async getModelBySearchTermFormMessenger(requestParameters: GetModelBySearchTermFormMessengerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ModelDataForMessenger>> {
        const response = await this.getModelBySearchTermFormMessengerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getModelCostsRaw(requestParameters: GetModelCostsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ModelCostsResponseDto>> {
        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling getModelCosts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/model-master-data/model-costs/{modelId}`.replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelCostsResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async getModelCosts(requestParameters: GetModelCostsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ModelCostsResponseDto> {
        const response = await this.getModelCostsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getModelFormValuesRaw(requestParameters: GetModelFormValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ModelMasterDataFormValuesDto>> {
        if (requestParameters.lang === null || requestParameters.lang === undefined) {
            throw new runtime.RequiredError('lang','Required parameter requestParameters.lang was null or undefined when calling getModelFormValues.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/model-master-data/formValues/{lang}`.replace(`{${"lang"}}`, encodeURIComponent(String(requestParameters.lang))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelMasterDataFormValuesDtoFromJSON(jsonValue));
    }

    /**
     */
    async getModelFormValues(requestParameters: GetModelFormValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ModelMasterDataFormValuesDto> {
        const response = await this.getModelFormValuesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getModelFormValues1Raw(requestParameters: GetModelFormValues1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ModelMasterDataFormValuesDto>> {
        if (requestParameters.lang === null || requestParameters.lang === undefined) {
            throw new runtime.RequiredError('lang','Required parameter requestParameters.lang was null or undefined when calling getModelFormValues1.');
        }

        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling getModelFormValues1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/model-master-data/formValues/{lang}/{modelId}`.replace(`{${"lang"}}`, encodeURIComponent(String(requestParameters.lang))).replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelMasterDataFormValuesDtoFromJSON(jsonValue));
    }

    /**
     */
    async getModelFormValues1(requestParameters: GetModelFormValues1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ModelMasterDataFormValuesDto> {
        const response = await this.getModelFormValues1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getModelsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ModelMasterDataTableItemsDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/model-master-data/models`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelMasterDataTableItemsDtoFromJSON(jsonValue));
    }

    /**
     */
    async getModels(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ModelMasterDataTableItemsDto> {
        const response = await this.getModelsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getModelsForMessengerRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ModelDataForMessenger>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/model-master-data/model/messenger`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ModelDataForMessengerFromJSON));
    }

    /**
     */
    async getModelsForMessenger(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ModelDataForMessenger>> {
        const response = await this.getModelsForMessengerRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getModelsForSearchRaw(requestParameters: GetModelsForSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ModelMasterDataSearchItemsDto>> {
        if (requestParameters.modelMasterDataSearchFilterDto === null || requestParameters.modelMasterDataSearchFilterDto === undefined) {
            throw new runtime.RequiredError('modelMasterDataSearchFilterDto','Required parameter requestParameters.modelMasterDataSearchFilterDto was null or undefined when calling getModelsForSearch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/models/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ModelMasterDataSearchFilterDtoToJSON(requestParameters.modelMasterDataSearchFilterDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelMasterDataSearchItemsDtoFromJSON(jsonValue));
    }

    /**
     */
    async getModelsForSearch(requestParameters: GetModelsForSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ModelMasterDataSearchItemsDto> {
        const response = await this.getModelsForSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getModelsSearchFormValues1Raw(requestParameters: GetModelsSearchFormValues1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ModelMasterDataSearchFormValuesDto>> {
        if (requestParameters.lang === null || requestParameters.lang === undefined) {
            throw new runtime.RequiredError('lang','Required parameter requestParameters.lang was null or undefined when calling getModelsSearchFormValues1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/model-master-data/models/search/formValues/{lang}`.replace(`{${"lang"}}`, encodeURIComponent(String(requestParameters.lang))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelMasterDataSearchFormValuesDtoFromJSON(jsonValue));
    }

    /**
     */
    async getModelsSearchFormValues1(requestParameters: GetModelsSearchFormValues1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ModelMasterDataSearchFormValuesDto> {
        const response = await this.getModelsSearchFormValues1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setModelStatusRaw(requestParameters: SetModelStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling setModelStatus.');
        }

        if (requestParameters.activeStateFormValuesDto === null || requestParameters.activeStateFormValuesDto === undefined) {
            throw new runtime.RequiredError('activeStateFormValuesDto','Required parameter requestParameters.activeStateFormValuesDto was null or undefined when calling setModelStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/model-master-data/change/modelStatus/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ActiveStateFormValuesDtoToJSON(requestParameters.activeStateFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async setModelStatus(requestParameters: SetModelStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.setModelStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
