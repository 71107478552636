export const flattenObject = (obj: any, prefix = ''): any => {
    const flattenedObject: any = {};

    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const propName = prefix ? `${prefix}.${key}` : key;

            if (typeof obj[key] === 'object' && !Array.isArray(obj[key]) && !(obj[key] instanceof Date)) {
                const flattenedChild = flattenObject(obj[key], propName);
                Object.assign(flattenedObject, flattenedChild);
            } else {
                let strings = propName.split('.');
                if (strings[strings.length - 1] === 'key') {
                    strings.pop();
                    flattenedObject[strings.join('.')] = obj;
                } else {
                    flattenedObject[propName] = obj[key];
                }
            }
        }
    }

    return flattenedObject;
}

export const getUTCDate = (dateToConvert: Date | undefined | null | string) => {
    if (!dateToConvert) {
        return undefined;
    }

    if (typeof dateToConvert === 'string') {
        dateToConvert = new Date(dateToConvert);
    }

    return new Date(Date.UTC(dateToConvert.getFullYear(), dateToConvert.getMonth(), dateToConvert.getDate()));
}
export const getUTCDateString = (dateToConvert: Date | undefined | null | string) => {
  if (!dateToConvert) {
      return undefined;
  }

  if (typeof dateToConvert === 'string') {
      dateToConvert = new Date(dateToConvert);
  }

  return new Date(Date.UTC(dateToConvert.getFullYear(), dateToConvert.getMonth(), dateToConvert.getDate())).toISOString().split('T')[0];
}
export const addDaysToDate = (dateToConvert: Date, daysToAdd: number) => {
    return new Date(Date.UTC(dateToConvert.getFullYear(), dateToConvert.getMonth(), dateToConvert.getDate() + daysToAdd));
}

export const toISOStringWithLocalTime = (date: Date) => {
    const offset = date.getTimezoneOffset() * 60000; // Convert offset to milliseconds
    const adjustedDate = new Date(date.getTime() - offset); // Adjust the date
    return adjustedDate.toISOString().split('T')[0]; // Return the date portion of the ISO string
}

export const arraysEqual = (a: any[], b: any[]): boolean => {
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }

    return true;
}

export const urlBase64ToUint8Array = (base64String: string) => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

export function deepEqual(obj1: any, obj2: any): boolean {
  if (obj1 === obj2) {
    return true;
  }

  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}


export const securePassword = (length: number) => {
  const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+[]{}|;:,.<>?";
  let password = "";
  const crypto = window.crypto || (window as any).msCrypto;

  if (crypto && typeof crypto.getRandomValues === "function") {
    const array = new Uint8Array(length);
    crypto.getRandomValues(array);
    for (let i = 0; i < length; i++) {
      password += charset[array[i] % charset.length];
    }
  } else {
    // Fallback for environments without crypto API
    for (let i = 0; i < length; i++) {
      password += charset[Math.floor(Math.random() * charset.length)];
    }
  }

  return password;
}
