import { Component, Input, OnInit } from '@angular/core';
import { ImagePreviewService } from '../../../service/image-preview.service';

@Component({
  selector: 'app-image-preview',
  styleUrls: ['./image-preview.component.scss'],
  template: `
    <div *ngIf="isVisible" class="image-preview-overlay">
      <div class="image-preview-container">
        <span class="close-button" (click)="close()">×</span>
        <p-image
          [src]="images[currentImageIndex]"
          alt="Preview"
          width="100%"
        ></p-image>
        <div
          *ngIf="images.length > 1"
          class="preview-controls"
        >
          <span
            (click)="previousImage()"
            class="cursor-pointer pi pi-arrow-left"
          ></span>
          <span>{{ currentImageIndex + 1 }}/{{ images.length }}</span>
          <span
            (click)="nextImage()"
            class="cursor-pointer pi pi-arrow-right"
          ></span>
        </div>
      </div>
    </div>
  `
})
export class ImagePreviewComponent implements OnInit {
  @Input() images: string[] = [];
  currentImageIndex = 0;
  isVisible = false;

  constructor(private imagePreviewService: ImagePreviewService) {}

  ngOnInit() {
    this.imagePreviewService.state$.subscribe(state => {
      this.images = state.images;
      this.isVisible = state.isVisible;
      this.currentImageIndex = 0;
    });
  }

//   show(images: string[]) {
//     this.images = images;
//     this.currentImageIndex = 0;
//     this.isVisible = true;
//   }

  close() {
    this.imagePreviewService.close();
  }

  nextImage() {
    const nextImage = this.currentImageIndex + 1;
    this.currentImageIndex = nextImage > this.images.length - 1 ? 0 : nextImage;
  }

  previousImage() {
    const nextIndex = this.currentImageIndex - 1;
    this.currentImageIndex = nextIndex < 0 ? this.images.length - 1 : nextIndex;
  }
} 