import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, firstValueFrom, debounceTime, distinctUntilChanged, Subject, interval } from 'rxjs';
import {
  ConversationControllerService,
  ConversationsResponseDto,
  CommunicationUserDto,
  ConversationResponseDto
} from '@brody-bookings/api-v2';
import { MessageDto } from 'libs/api-typescript-angular/src/model/messageDto';
import { LanguageService } from '../../../../service/language.service';
import { MessageService, SelectItemGroup, TreeNode } from 'primeng/api';
import { AuthenticationService } from '../../../../../layout/service/app.auth.service';
import { WidgetConversationService } from './_service/widget.conversation.service';
import { ConfirmModalService } from '../../../../service/confirmModal.service';
import { MessageNotificationService } from '../../../../service/message-notification.service';
import { ConversationMessagesDto } from 'libs/api-typescript-angular/src/model/conversationMessagesDto';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

interface RoleParticipant {
  name: string | null;
  oauthId: string;
  numberOfNewMessages: number;
  totalNumberOfMessages: number;
  conversationId: string;
  isSelected?: boolean;
  projectId?: string;
  roleId?: string;
}

interface ProjectRole {
  roleId: string;
  name: string;
  participants: RoleParticipant[];
  isExpanded?: boolean;
  allSelected?: boolean;
}

interface ProjectConversation {
  projectId: string;
  name: string | null;
  producerOauthId: string | null;
  producerName: string;
  totalNumberOfMessages: number;
  roles: ProjectRole[];
  additionalParticipants: { 
    name: string | null; 
    oauthId: string;
    numberOfNewMessages: number;
    totalNumberOfMessages: number;
    conversationId: string;
    isSelected?: boolean;
  }[];
  isExpanded?: boolean;
  broadcastSelected?: boolean;
}

interface SearchResult {
  name: string;
  oauthId: string;
}

export interface MessageDtoWithDeletedFlag extends MessageDto {
  isDeleted?: boolean;
}

@Component({
  selector: 'app-conversation-widget',
  templateUrl: './conversation.widget.component.html',
  styleUrls: ['./conversation.widget.component.scss']
})
export class ConversationWidgetComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  conversations: ConversationsResponseDto | undefined;
  directConversations: any[] = [];
  projectConversations: ProjectConversation[] = [];
  knownMessageCounts: { [conversationId: string]: number } = {};

  isBroadCast = false;
  selectedReceiverConversationId: string | undefined = undefined;
  messageList: MessageDtoWithDeletedFlag[] = [];

  selectedConversation: any | null = null;
  selectedConversationId: string | null = null;
  selectedRecipient: string | null = null;
  selectedProjectId: string | undefined = undefined;
  currentUserOauthId = '';
  // Models

  showNoAccountMessage: boolean = false;

  selectedParticipant: { oauthId: string, roleId?: string } | null = null;

  searchQuery: string = '';
  searchResults: any[] = [];
  private directSearchSubject = new Subject<string>();
  private projectSearchSubject = new Subject<string>();

  filteredDirectConversations: any[] = [];
  showSearchResults: boolean = false;

  isLoading: boolean = true;

  projectSearchQuery: string = '';
  filteredProjectConversations: ProjectConversation[] = [];

  showBroadcastInterface: boolean = false;
  broadcastMessage: string = '';
  selectedRole: ProjectRole | null = null;
  selectedProjectIdForBroadcast: string | undefined;

  showModelMessageInterface: boolean = false;
  showModelSelection: boolean = false;
  modelMessage: string = '';
  selectedModels: any[] = [];

  showForwardDialog: boolean = false;
  selectedMessage: MessageDtoWithDeletedFlag | null = null;
  selectedForwardRecipients: any[] = [];

  forwardSearchQuery: string = '';
  forwardSearchResults: any[] = [];
  private forwardSearchSubject = new Subject<string>();

  constructor(
    private router: Router,
    private conversationControllerService: ConversationControllerService,
    private languageService: LanguageService,
    private authenticationService: AuthenticationService,
    private widgetConversationService: WidgetConversationService,
    private messageService: MessageService,
    private confirmModalService: ConfirmModalService,
    private messageNotificationService: MessageNotificationService,
    private dialogService: DialogService
  ) {

  }

  ngOnInit(): void {
    // this.loadModels();
    // this.loadProjectRelevantPersons('433279b3-c778-4bb2-b7da-58af625d617d');
    this.initCurrentUser();
    console.log('initCurrentUser');
    this.loadConversations();
    this.startCheckingNewMessages();

    this.setupDirectSearch();
    this.setupProjectSearch();
    this.setupForwardSearch();

    this.messageNotificationService.updateCheckInBackground(false);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.directSearchSubject.complete();
    this.projectSearchSubject.complete();
    this.forwardSearchSubject.complete();
    this.messageNotificationService.updateCheckInBackground(true);
  }

  loadConversations() {
    this.isLoading = true;
    firstValueFrom(this.conversationControllerService.getBookerConversations())
      .then((conversations: any) => {
        this.conversations = conversations;
        this.directConversations = conversations.directConversations || [];
        this.filteredDirectConversations = this.directConversations;
        this.projectConversations = (conversations.projectConversations || []).map((conv: ProjectConversation) => ({
          ...conv,
          isExpanded: false
        }));
        this.filteredProjectConversations = this.projectConversations;

        this.knownMessageCounts = this.directConversations.reduce((acc: any, curr: any) => ({ ...acc, [curr.conversationId]: curr.totalNumberOfMessages }), {});
        this.projectConversations.forEach(pc => {
          pc.roles.forEach(r => {
            r.participants.forEach(p => {
              if (p.conversationId) {
                this.knownMessageCounts[p.conversationId] = p.totalNumberOfMessages;
              }
            })
          })
        });

      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  startCheckingNewMessages() {
    this.subscriptions.push(interval(10000) // Check every 30 seconds
      .subscribe(() => {
        this.checkNewMessages();
      }));
  }

  checkNewMessages() {
    this.conversationControllerService.checkNewMessages(this.knownMessageCounts).subscribe(
      (result: string[]) => {
        if (result.length > 0) {
          this.updateSpecificConversations(result);
        }
      },
      (error: any) => {
        console.error('Error checking for new messages:', error);
      }
    );
  }

  updateSpecificConversations(conversationIds: string[]) {
    this.conversationControllerService.fetchConversations(conversationIds).subscribe(
      (conversations: ConversationMessagesDto[]) => {
        firstValueFrom(this.messageNotificationService.newMessagesCount$).then((count) => {
          this.messageNotificationService.updateNewMessagesCount(count + conversations.map((c) => c.newMessages?.length || 0).reduce((a, b) => a + b, 0));
        });
        
        conversations.forEach((conversation) => {
          // Zuerst prüfen ob es eine Projektkonversation ist
          if (conversation.projectId && conversation.roleId) {
            // Handle project conversation
            let projectConversation = this.projectConversations.find(pc => pc.projectId === conversation.projectId);

            if (!projectConversation) {
              return;
            }

            let role = projectConversation.roles.find(r => r.roleId === conversation.roleId);

            if (!role) {
              return;
            }

            let participant = role.participants.find(p => p.oauthId === conversation.participantOauthId);

            if (!participant) {
              return;
            }

            // Update participant data
            participant.numberOfNewMessages = conversation.newMessages?.length || 0;
            participant.totalNumberOfMessages = conversation.totalNumberOfMessages || 0;
            participant.conversationId = conversation.conversationId || '';
            this.knownMessageCounts[participant.conversationId] = conversation.totalNumberOfMessages || 0;
            
            // Sortiere Projektkonversationen neu
            this.sortProjectConversations();
          } else {
            // Handle direct conversation
            const directConversation = this.directConversations.find(dc => dc.conversationId === conversation.conversationId);

            if (directConversation) {
              // Update existing direct conversation
              directConversation.numberOfNewMessages = conversation.newMessages?.length || 0;
              if (this.selectedConversationId === conversation.conversationId) {
                this.messageList = [
                  ...this.messageList,
                  ...(conversation.newMessages?.map(msg => ({...msg, isDeleted: false})) || [])
                ];
              }
              this.knownMessageCounts[conversation.conversationId ?? ''] = conversation.totalNumberOfMessages || 0;
            }
          }
        });

        // After processing all conversations, sort directConversations by lastMessageTimeStamp
        this.directConversations.sort((a, b) => 
          new Date(b.lastMessageTimeStamp).getTime() - new Date(a.lastMessageTimeStamp).getTime()
        );
      },
      (error: any) => {
        console.error('Error updating specific conversations:', error);
      }
    );
  }

  toggleProjectConversation(conversation: ProjectConversation) {
    conversation.isExpanded = !conversation.isExpanded;
  }

  toggleRole(role: ProjectConversation['roles'][0]) {
    role.isExpanded = !role.isExpanded;
  }

  selectConversation(conversation: any) {
    this.showNoAccountMessage = false;
    this.selectedConversation = conversation;
    this.selectedConversationId = conversation.conversationId;
    this.selectedProjectId = undefined;
    this.selectedParticipant = null;
    this.loadMessagesForConversation(conversation.conversationId, undefined, undefined)
      .then(() => {
        // Mark messages as read
        this.markMessagesAsRead(conversation.conversationId);
      })
      .catch((error) => {
        console.error('Error loading messages:', error);
      });
  }

  selectParticipant(participant: any, projectId: string, roleId?: string) {
    // Reset broadcast selection if active
    this.clearModelSelection();
    this.showBroadcastInterface = false;
    this.selectedProjectId = projectId;
    this.selectedConversation = null;
    this.selectedConversationId = null;
    this.selectedParticipant = null;
    
    if (participant.oauthId) {
      this.showNoAccountMessage = false;
      // Speichern Sie sowohl oauthId als auch roleId
      this.selectedParticipant = {
        oauthId: participant.oauthId,
        roleId: roleId
      };
      this.loadMessagesForConversation(participant.oauthId, projectId, roleId)
        .then(() => {
          // Reset the new messages count for this participant
          const projectConversation = this.projectConversations.find(pc => pc.projectId === projectId);
          if (projectConversation) {
            const role = projectConversation.roles.find(r => r.roleId === roleId);
            if (role) {
              const participantToUpdate = role.participants.find(p => p.oauthId === participant.oauthId);
              if (participantToUpdate) {
                participantToUpdate.numberOfNewMessages = 0;
              }
            }
          }
        });
    } else {
      this.showNoAccountMessage = true;
      this.selectedConversation = null;
      this.selectedParticipant = null;
    }
  }

  private loadMessagesForConversation(participantIdOrConversationId: string, projectId: string | undefined, roleId?: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.isConversationId(participantIdOrConversationId)) {
        // For direct conversations
        firstValueFrom(this.conversationControllerService.getMessagesByConversationId(participantIdOrConversationId))
          .then((messages: any) => {
            this.messageList = messages.map((m:any) => {
              if (m.message === 'Message deleted') {
                return {...m, isDeleted: true};
              }
              return m;
            });
            this.selectedReceiverConversationId = participantIdOrConversationId;
            resolve();
          })
          .catch((error) => {
            console.error('Error loading messages:', error);
            this.messageList = [];
            this.selectedReceiverConversationId = undefined;
            reject(error);
          });
      } else {
        // For project-related conversations
        firstValueFrom(this.conversationControllerService.getConversation({
          senderId: this.currentUserOauthId,
          participantIds: [participantIdOrConversationId],
          projectId: projectId,
          roleId: roleId
        }))
          .then((conversation: any) => {
            this.selectedReceiverConversationId = conversation.conversationId.value;
            this.messageList = (conversation.messages || []).map((m: any) => ({
              messageId: m.messageId,
              senderId: m.senderId,
              senderName: m.senderName,
              message: m.message,
              imageUrl: m.imageUrl,
              videoUrl: m.videoUrl,
              timeStamp: m.timeStamp,
              formattedTimeStamp: m.formattedTimeStamp,
              isDeleted: m.message === 'Message deleted'
            }));
            this.selectedConversation = {
              conversationId: conversation.conversationId.value,
              name: conversation.participantName || 'Unknown',
              lastMessage: conversation.messages[conversation.messages.length - 1]?.message || '',
              lastMessageTimeStamp: conversation.messages[conversation.messages.length - 1]?.timeStamp || new Date(),
              hasNewMessages: false
            };
            this.selectedConversationId = conversation.conversationId.value;
            this.markMessagesAsRead(conversation.conversationId.value, projectId, roleId, participantIdOrConversationId);
            resolve();
          })
          .catch((error) => {
            console.error('Error loading messages:', error);
            if (error.status === 404) {
              this.messageList = [];
              this.selectedReceiverConversationId = undefined;
              this.selectedConversation = null;
              this.selectedConversationId = null;
            }
            reject(error);
          });
      }
    });
  }

  private isConversationId(id: string): boolean {
    // Implement logic to determine if the id is a conversation id
    // This could be based on the format of your ids or other criteria
    // For example, if conversation ids are always UUIDs:
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidRegex.test(id);
  }

  onSendMessage(newMessage: string, images: Array<Blob>, projectId?: string) {
    if (this.isBroadCast) {
      return this.sendBroadCastMessage(this.selectedRecipient!!, newMessage, images, projectId);
    } else {
      return this.sendDirectMessage(this.selectedRecipient!!, newMessage, images, projectId);
    }

  }


  onDeleteMessage(messageId: string) {
    if (!this.selectedReceiverConversationId) return;

    const conversationId = this.selectedReceiverConversationId;
    this.confirmModalService.showConfirmModal('Willst du diese Nachricht wirklich löschen?', () => {
      this.widgetConversationService.deleteMessage(conversationId, messageId).then(() => {
        // Find the message and update its text instead of removing it
        const messageIndex = this.messageList.findIndex(m => m.messageId === messageId);
        if (messageIndex !== -1) {
          this.messageList[messageIndex] = {
            ...this.messageList[messageIndex],
            message: "Message deleted",
            // Optionally, you can add a flag to indicate it's a deleted message
            isDeleted: true
          };
          // Trigger change detection
          this.messageList = [...this.messageList];
        }
      });
    });
  }

  onRefreshMessages() {
    if (!this.selectedReceiverConversationId) return;

    const conversationId = this.selectedReceiverConversationId;
    this.widgetConversationService.refreshMessages(conversationId).then((messages: unknown) => {
      this.messageList = messages as MessageDtoWithDeletedFlag[];
    });
  }

  onCameraButtonClick() {
  }

  private initCurrentUser() {
    this.subscriptions.push(this.authenticationService.frontendUser$.subscribe((currentUser) => {
      if (!currentUser) return;
      this.currentUserOauthId = currentUser.userId;
    }));
  }


  private resetMessages() {
    this.messageList = [];
    this.selectedReceiverConversationId = undefined;
  }

  private async sendDirectMessage(oauthId: string, newMessage: string, images: Array<Blob>, projectId?: string) {
    return this.widgetConversationService.sendMessage(oauthId, newMessage, false, images, this.selectedReceiverConversationId, projectId).then((response: any) => {
      response.messages?.forEach((message: any) => {
        this.messageList.push(message);
      });
      
      // Increase the knownMessageCount for the selected conversation
      if (this.selectedReceiverConversationId) {
        this.knownMessageCounts[this.selectedReceiverConversationId] = (this.knownMessageCounts[this.selectedReceiverConversationId] || 0) + 1;
      }
    });
  }

  private sendBroadCastMessage(oauthIds: string, newMessage: string, images: Array<Blob>, projectId?: string, roleId?: string) {
    return this.widgetConversationService.sendMessage(oauthIds, newMessage, true, [], this.selectedReceiverConversationId, projectId, roleId).then((response: any) => {
      response.messages?.forEach((message: any) => {
        this.messageList.push(message);
      });

      this.messageService.add({
        severity: 'success',
        summary: 'Nachricht erfolgreich versendet',
        detail: 'Nachricht an alle Teilnehmer gesendet'
      });
    });
  }

  private setupDirectSearch(): void {
    this.directSearchSubject.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe((query) => {
      this.performDirectSearch(query);
    });
  }

  async onSearch() {
    if (this.searchQuery && this.searchQuery.length > 0) {
      try {
        // Filter existing conversations based on the search query
        const filteredExistingConversations = this.filteredDirectConversations.filter(conv =>
          conv.otherParticipantName.toLowerCase().includes(this.searchQuery.toLowerCase())
        );

        // Wait for the API results
        const apiResults = await firstValueFrom(this.conversationControllerService.searchUsers(this.searchQuery));

        // Add this type assertion
        const typedApiResults = apiResults as CommunicationUserDto[];

        // Combine filtered existing conversations with API results
        this.searchResults = [
          ...filteredExistingConversations.map(conv => ({
            name: conv.name,
            firstName: conv.firstName,
            lastName: conv.lastName,
            oauthId: conv.oauthId,
            roles: conv.roles,
            conversationId: conv.conversationId
          })),
          ...typedApiResults.filter((result) => 
            !filteredExistingConversations.some(conv => 
              conv.name === result.formattedNameWithRoles
            )
          ).map((result) => ({
            name: result.formattedNameWithRoles,
            firstName: result.firstName,
            lastName: result.lastName,
            oauthId: result.oauthId,
            roles: result.roles
          }))
        ];

      } catch (error) {
        console.error('Error searching users:', error);
        this.searchResults = [];
      }
    } else {
      this.searchResults = [];
    }
  }

  isExistingConversation(result: any): boolean {
    return this.filteredDirectConversations.some(conv => 
      conv.otherParticipantName === result.name
    );
  }

  private performDirectSearch(query: string): void {
    if (query.length < 2) {
      this.filteredDirectConversations = this.directConversations;
      this.searchResults = [];
      this.showSearchResults = false;
      return;
    }

    // Filter existing direct conversations
    this.filteredDirectConversations = this.directConversations.filter(conv =>
      conv.name.toLowerCase().includes(query.toLowerCase())
    );

    // If no results in direct conversations, call the API
    if (this.filteredDirectConversations.length === 0) {
      this.conversationControllerService.searchUsers(query).subscribe(
        (results: CommunicationUserDto[]) => {
          this.searchResults = results;
          this.showSearchResults = true;
        },
        (error: any) => {
          console.error('Error searching users:', error);
          this.searchResults = [];
          this.showSearchResults = false;
        }
      );
    } else {
      this.searchResults = [];
      this.showSearchResults = false;
    }
  }

  selectSearchResult(result: any): void {
    if (result.conversationId) {
      // It's an existing conversation
      this.selectConversation(result);
    } else {
      // It's a new user from API search
      const existingConversation = this.directConversations.find(
        conv => conv.otherParticipantName == result.name
      );

      if (existingConversation) {
        this.selectConversation(existingConversation);
      } else {
        this.createNewConversation(result);
      }
    }

    this.searchQuery = '';
    this.filteredDirectConversations = this.directConversations;
    this.searchResults = [];
    this.showSearchResults = false;
  }

  private createNewConversation(participant: CommunicationUserDto): void {
    this.conversationControllerService.createConversation('de', {
      conversationTitle: participant.firstName + ' ' + participant.lastName + ' (' + this.getRole(participant.roles) + ')',
      participantIds: [participant.oauthId!!]
    }).subscribe(
      (newConversation: ConversationResponseDto) => {
        const formattedConversation = {
          conversationId: newConversation.conversationId,
          name: newConversation.conversationTitle,
          participantOauthId: participant.oauthId,
          lastMessage: '',
          lastMessageTimeStamp: newConversation.lastEditedAt,
          hasNewMessages: false,
          otherParticipantName: newConversation.conversationTitle
        };
        this.directConversations.unshift(formattedConversation);
        this.selectConversation(formattedConversation);
      },
      (error: any) => {
        console.error('Error creating new conversation:', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to create new conversation. Please try again.'
        });
      }
    );
  }

  private setupProjectSearch(): void {
    this.projectSearchSubject.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(() => {
      this.performProjectSearch();
    });
  }

  onProjectSearch(): void {
    this.projectSearchSubject.next(this.projectSearchQuery);
  }

  private performProjectSearch(): void {
    if (this.projectSearchQuery.length === 0) {
      this.filteredProjectConversations = this.projectConversations;
      return;
    }

    const query = this.projectSearchQuery.toLowerCase();
    this.filteredProjectConversations = this.projectConversations.filter(conv =>
      conv.producerName.toLowerCase().includes(query) ||
      conv.name?.toLowerCase().includes(query)
    );
  }

  openBroadcastInterface(role: any, projectId: string | undefined) {
    this.selectedRole = role;
    this.selectedProjectIdForBroadcast = projectId;
    this.showBroadcastInterface = true;
    this.broadcastMessage = '';
    this.selectedConversation = null;
    this.selectedConversationId = null;
    this.selectedParticipant = null;
    this.showNoAccountMessage = false;
    this.selectedReceiverConversationId = undefined;
  }

  sendBroadcastMessage() {
    if (!this.broadcastMessage.trim()) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please enter a message' });
      return;
    }

    const participantIds = this.selectedRole!!.participants
      .filter((participant: any) => participant.oauthId)
      .map((participant: any) => participant.oauthId);

    if (participantIds.length === 0) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No valid participants found' });
      return;
    }

    this.sendBroadCastMessage(
      participantIds.join(','),
      this.broadcastMessage,
      [],
      this.selectedProjectIdForBroadcast,
      this.selectedRole!!.roleId
    ).then(() => {
      //this.showBroadcastInterface = false;
      this.broadcastMessage = '';
    }).catch(error => {
      console.error('Error sending broadcast message:', error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to send broadcast message' });
    });
  }

  // Add this new method
  private markMessagesAsRead(conversationId: string, projectId?: string, roleId?: string, participantIdOrConversationId?: string): void {
    this.conversationControllerService.autoUpdateTracking(conversationId).subscribe(
      () => {
        const updatedConversation = this.directConversations.find(c => c.conversationId === conversationId);
        if (updatedConversation) {
          updatedConversation.numberOfNewMessages = 0;
        } else {
          if (projectId && roleId && participantIdOrConversationId) {
            const projectConversation = this.projectConversations.find(pc => pc.projectId === projectId);
            if (projectConversation) {
              const role = projectConversation.roles.find(r => r.roleId === roleId);
              if (role) {
                const participant = role.participants.find(p => p.oauthId === participantIdOrConversationId);
                if (participant) {
                  participant.numberOfNewMessages = 0;
                }
              }
            }
          }
        }

        // Calculate total number of new messages across all conversations
        const directConversationTotalMessages = this.directConversations.reduce((sum, conv) => sum + (conv.numberOfNewMessages || 0), 0);
        let projectConversationTotalMessages = 0;
        this.projectConversations.forEach(pc => {
          pc.roles.forEach(r => {
            r.participants.forEach(p => {
              projectConversationTotalMessages += p.numberOfNewMessages || 0;
            })
          })
        });
    
        const totalNewMessages = directConversationTotalMessages + projectConversationTotalMessages;
          
        // Update the message notification service
        this.messageNotificationService.updateNewMessagesCount(totalNewMessages);
      },
      (error: any) => {
        console.error('Error marking messages as read:', error);
      }
    );
  }

  getRole(roles?: string) {
    if (!roles) return undefined;
    return roles.split(',').map((role) => role.charAt(0).toUpperCase()).join(', ');
  }

  getTotalParticipants(conversation: any): number {
    const roleParticipants = conversation.roles.reduce((sum: number, role: any) => sum + role.participants.length, 0);
    return roleParticipants + conversation.additionalParticipants.length;
  }

  getRoleNewMessageCount(role: any): number {
    return role.participants.reduce((sum: number, participant: any) => sum + (participant.numberOfNewMessages || 0), 0);
  }

  getProjectNewMessageCount(conversation: ProjectConversation): number {
    let totalNewMessages = 0;
    
    // Sum new messages from roles
    conversation.roles.forEach(role => {
      totalNewMessages += this.getRoleNewMessageCount(role);
    });
    
    // Sum new messages from additional participants
    conversation.additionalParticipants.forEach(participant => {
      totalNewMessages += participant.numberOfNewMessages || 0;
    });
    
    return totalNewMessages;
  }

  openModelSelectionInterface(role: any, projectId: string | undefined) {
    this.selectedRole = role;
    this.selectedProjectIdForBroadcast = projectId;
    this.showModelMessageInterface = true;
    this.showModelSelection = true;
    this.modelMessage = '';
    this.selectedModels = [];
    this.resetChatState();
  }

  closeModelMessageInterface() {
    this.showModelMessageInterface = false;
    this.showModelSelection = false;
    this.modelMessage = '';
    this.selectedModels = [];
  }

  onModelSelectionChange(role: ProjectRole | null, projectId: string, participant: any) {
    // Reset broadcast selection if active
    if (this.selectedRole?.roleId === 'broadcast') {
        this.selectedRole = null;
        this.selectedModels = [];
        this.projectConversations.forEach(project => {
            project.broadcastSelected = false;
        });
    }

    if (participant.isSelected) {
        // Add to selectedModels if not already present
        if (!this.selectedModels.some(m => m.oauthId === participant.oauthId && m.roleId === (role?.roleId || null))) {
            this.selectedModels.push({
                ...participant,
                projectId,
                roleId: role?.roleId || null
            });
        }
    } else {
        // Remove from selectedModels
        this.selectedModels = this.selectedModels.filter(m => 
            !(m.oauthId === participant.oauthId && m.roleId === (role?.roleId || null))
        );
    }

    // Update allSelected state for the role if it exists
    if (role && this.selectedRole) {
        const validParticipants = this.selectedRole.participants.filter(p => p.oauthId);
        const allSelected = validParticipants.every(p => p.isSelected);
        this.selectedRole.allSelected = allSelected;
    }
  }

  clearModelSelection() {
    this.selectedModels = [];
    this.modelMessage = '';
    this.projectConversations.forEach(project => {
      project.roles.forEach((role: ProjectRole) => {
        role.allSelected = false;
        role.participants.forEach(participant => {
          participant.isSelected = false;
        });
      });
      project.broadcastSelected = false;
    });
    if (this.selectedRole?.roleId === 'broadcast') {
      this.selectedRole = null;
    }
  }

  sendModelMessage() {
    if (!this.modelMessage.trim()) {
      this.messageService.add({ 
        severity: 'error', 
        summary: 'Error', 
        detail: 'Bitte geben Sie eine Nachricht ein' 
      });
      return;
    }

    this.selectedReceiverConversationId = undefined;

    const participantGroups: { [key: string]: string[] } = this.selectedModels.reduce((acc, model) => {
      const key = `${model.projectId}|${model.roleId}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(model.oauthId);
      return acc;
    }, {} as { [key: string]: string[] });

    const promises = Object.entries(participantGroups).map(([key, participantIds]) => {
      const [projectId, roleId] = key.split('|');
      return this.sendBroadCastMessage(
        participantIds.join(','),
        this.modelMessage,
        [],
        projectId,
        roleId
      );
    });

    Promise.all(promises)
      .then(() => {
        //this.clearModelSelection();
        this.modelMessage = '';
        this.messageService.add({ 
          severity: 'success', 
          summary: 'Erfolg', 
          detail: 'Nachricht erfolgreich gesendet'
        });
      })
      .catch(error => {
        console.error('Error sending message:', error);
        this.messageService.add({ 
          severity: 'error', 
          summary: 'Error', 
          detail: 'Fehler beim Senden der Nachricht' 
        });
      });
  }

  private resetChatState() {
    this.selectedConversation = null;
    this.selectedConversationId = null;
    this.selectedParticipant = null;
    this.showNoAccountMessage = false;
    this.selectedReceiverConversationId = undefined;
  }

  removeModel(model: any) {
    this.selectedModels = this.selectedModels.filter(m => m !== model);
    if (this.selectedModels.length === 0) {
      this.modelMessage = '';
    }
  }

  toggleAllModels(role: ProjectRole, projectId: string) {
    // Reset broadcast selection if active
    if (this.selectedRole?.roleId === 'broadcast') {
      this.selectedRole = role;
      this.selectedModels = [];
    }

    const validParticipants = role.participants.filter(p => p.oauthId);
    
    validParticipants.forEach(participant => {
      participant.isSelected = role.allSelected;
      
      if (role.allSelected) {
        // Add if not already present in this role
        if (!this.selectedModels.some(m => m.oauthId === participant.oauthId && m.roleId === role.roleId)) {
          this.selectedModels.push({
            ...participant,
            projectId,
            roleId: role.roleId
          });
        }
      } else {
        // Remove only from this specific role
        this.selectedModels = this.selectedModels.filter(m => 
          !(m.oauthId === participant.oauthId && m.roleId === role.roleId)
        );
      }
    });
  }

  openProjectBroadcastInterface(conversation: ProjectConversation) {
    // Reset any existing selections
    this.clearModelSelection();
    
    // Mark this conversation as broadcast selected
    conversation.broadcastSelected = true;
    
    // Collect all participants with valid oauthIds
    const allParticipants: RoleParticipant[] = [];
    
    // Collect all models from all roles (with roleId)
    conversation.roles.forEach(role => {
        const validParticipants = role.participants.filter(p => p.oauthId);
        validParticipants.forEach(participant => {
            participant.isSelected = true; // Mark each participant as selected
            allParticipants.push({
                ...participant,
                projectId: conversation.projectId,
                roleId: role.roleId
            });
        });
    });
    
    // Add additional participants with valid oauthIds (without roleId)
    const validAdditionalParticipants = conversation.additionalParticipants
        .filter(p => p.oauthId)
        .map(p => ({
            ...p,
            projectId: conversation.projectId,
            isSelected: true
        }));
    allParticipants.push(...validAdditionalParticipants);

    // Set the selections
    this.selectedModels = allParticipants;
    this.selectedProjectIdForBroadcast = conversation.projectId;
    this.modelMessage = '';
    
    this.selectedRole = {
        roleId: 'broadcast',
        name: 'Projekt-Broadcast',
        participants: allParticipants,
        isExpanded: true,
        allSelected: true
    };

    // Reset chat state
    this.resetChatState();
  }

  toggleProjectBroadcast(conversation: ProjectConversation) {
    if (conversation.broadcastSelected) {
        // Clear any existing model selections first
        this.clearModelSelection();
        
        // Then open broadcast interface
        this.openProjectBroadcastInterface(conversation);
    } else {
        // Just clear selections when deselecting broadcast
        this.clearModelSelection();
        this.selectedRole = null;
        this.selectedProjectIdForBroadcast = undefined;
    }
  }

  private sortProjectConversations() {
    this.projectConversations.sort((a, b) => {
      const aNewMessages = this.getProjectNewMessageCount(a);
      const bNewMessages = this.getProjectNewMessageCount(b);
      
      // Zuerst nach neuen Nachrichten sortieren
      if (aNewMessages !== bNewMessages) {
        return bNewMessages - aNewMessages;
      }
      
      // Bei gleicher Anzahl neuer Nachrichten nach Namen sortieren
      return (a.producerName || '').localeCompare(b.producerName || '');
    });
    
    // Update filtered conversations
    this.filteredProjectConversations = [...this.projectConversations];
  }

  forwardMessage(message: MessageDtoWithDeletedFlag) {
    this.selectedMessage = message;
    this.showForwardDialog = true;
    this.selectedForwardRecipients = [];
  }

  sendForwardedMessage() {
    if (!this.selectedMessage || (!this.selectedMessage.message && !this.selectedMessage.imageUrl) || this.selectedForwardRecipients.length === 0) return;

    // Speichere die aktuelle conversationId
    const originalConversationId = this.selectedReceiverConversationId;

    // Gruppiere Empfänger nach Typ (direkt vs. projekt-spezifisch)
    const directRecipients = this.selectedForwardRecipients.filter(r => r.conversationId);
    const projectRecipients = this.selectedForwardRecipients.filter(r => !r.conversationId);

    // Gruppiere Projekt-Empfänger nach Projekt und Rolle
    const projectGroups: { [key: string]: string[] } = projectRecipients.reduce((acc, recipient) => {
        const key = `${recipient.projectId}|${recipient.roleId}`;
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(recipient.oauthId);
        return acc;
    }, {} as { [key: string]: string[] });

    const promises = [
        // Direkte Nachrichten
        ...directRecipients.map(recipient => {
            // Setze die conversationId für jeden Empfänger
            this.selectedReceiverConversationId = recipient.conversationId;
            return this.sendDirectMessage(
                recipient.participantOauthId,
                this.selectedMessage?.imageUrl ? this.selectedMessage.imageUrl : this.selectedMessage?.message!!,
                [],
                undefined
            );
        }),
        // Projekt-Nachrichten
        ...Object.entries(projectGroups).map(([key, participantIds]) => {
            const [projectId, roleId] = key.split('|');
            return this.sendBroadCastMessage(
                participantIds.join(','),
                this.selectedMessage?.imageUrl ? this.selectedMessage.imageUrl : this.selectedMessage?.message!!,
                [],
                projectId,
                roleId
            );
        })
    ];

    Promise.all(promises)
        .then(() => {
            // Stelle die ursprüngliche conversationId wieder her
            this.selectedReceiverConversationId = originalConversationId;
            this.messageService.add({
                severity: 'success',
                summary: 'Erfolg',
                detail: 'Nachricht erfolgreich weitergeleitet'
            });
            this.closeForwardDialog();
        })
        .catch(error => {
            // Stelle die ursprüngliche conversationId auch im Fehlerfall wieder her
            this.selectedReceiverConversationId = originalConversationId;
            console.error('Error forwarding message:', error);
            this.messageService.add({
                severity: 'error',
                summary: 'Fehler',
                detail: 'Fehler beim Weiterleiten der Nachricht'
            });
        });
  }

  closeForwardDialog() {
    this.showForwardDialog = false;
    this.selectedMessage = null;
    this.selectedForwardRecipients = [];
    this.forwardSearchQuery = '';
    this.forwardSearchResults = [];
    // Reset all checkboxes
    this.directConversations.forEach(conv => conv.isSelected = false);
  }

  getValidParticipants(participants: any[]): any[] {
    return participants.filter(participant => participant.oauthId);
  }

  getValidRoles(roles: ProjectRole[]): ProjectRole[] {
    return roles.filter(role => 
      role.participants.some(participant => participant.oauthId)
    );
  }

  getValidProjects(projects: ProjectConversation[]): ProjectConversation[] {
    return projects.filter(project => 
      this.getValidRoles(project.roles).length > 0 || 
      this.getValidParticipants(project.additionalParticipants).length > 0
    );
  }

  private setupForwardSearch(): void {
    this.forwardSearchSubject.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(() => {
      this.onForwardSearch();
    });
  }

  async onForwardSearch() {
    if (this.forwardSearchQuery && this.forwardSearchQuery.length > 0) {
      try {
        // Filter existing conversations
        const filteredExistingConversations = this.directConversations.filter(conv =>
          conv.otherParticipantName.toLowerCase().includes(this.forwardSearchQuery.toLowerCase())
        );

        // Get API results
        const apiResults = await firstValueFrom(this.conversationControllerService.searchUsers(this.forwardSearchQuery));
        const typedApiResults = apiResults as CommunicationUserDto[];

        // Combine results
        this.forwardSearchResults = [
          ...filteredExistingConversations.map(conv => ({
            name: conv.otherParticipantName,
            oauthId: conv.participantOauthId,
            conversationId: conv.conversationId
          })),
          ...typedApiResults.filter((result) => 
            !filteredExistingConversations.some(conv => 
              conv.otherParticipantName === result.formattedNameWithRoles
            )
          ).map((result) => ({
            name: result.formattedNameWithRoles,
            oauthId: result.oauthId
          }))
        ];
      } catch (error) {
        console.error('Error searching users:', error);
        this.forwardSearchResults = [];
      }
    } else {
      this.forwardSearchResults = [];
    }
  }

  selectForwardSearchResult(result: any) {
    // Suche nach einem passenden direkten Kontakt
    const existingDirectContact = this.directConversations.find(conv => 
        conv.participantOauthId === result.oauthId || 
        conv.conversationId === result.conversationId
    );

    if (existingDirectContact) {
        // Wenn ein direkter Kontakt gefunden wurde
        existingDirectContact.isSelected = true;
        if (!this.isRecipientSelected(existingDirectContact)) {
            this.selectedForwardRecipients = [...this.selectedForwardRecipients, existingDirectContact];
        }
    } else {
        // Wenn kein direkter Kontakt existiert
        if (!this.isRecipientSelected(result)) {
            this.selectedForwardRecipients = [...this.selectedForwardRecipients, result];
        }
    }

    // Zurücksetzen der Suche
    this.forwardSearchQuery = '';
    this.forwardSearchResults = [];
  }

  removeForwardRecipient(recipient: any) {
    // Entferne aus selectedForwardRecipients
    this.selectedForwardRecipients = this.selectedForwardRecipients.filter(r => r !== recipient);
    
    // Finde und deselektiere die entsprechende Checkbox
    const directConversation = this.directConversations.find(conv => 
        conv.conversationId === recipient.conversationId ||
        conv.participantOauthId === recipient.oauthId ||
        conv.participantOauthId === recipient.participantOauthId
    );
    if (directConversation) {
        directConversation.isSelected = false;
    }
  }

  isRecipientSelected(conversation: any): boolean {
    return this.selectedForwardRecipients.some(recipient => 
        recipient.conversationId === conversation.conversationId ||
        recipient.otherParticipantId === conversation.otherParticipantId
    );
  }

  toggleRecipientSelection(conversation: any): void {
    if (conversation.isSelected) {
        // Wenn ausgewählt wurde, zum Array hinzufügen
        if (!this.isRecipientSelected(conversation)) {
            this.selectedForwardRecipients = [...this.selectedForwardRecipients, conversation];
        }

    } else {
        // Wenn abgewählt wurde, aus Array entfernen
        this.selectedForwardRecipients = this.selectedForwardRecipients.filter(recipient => 
            recipient.conversationId !== conversation.conversationId &&
            recipient.otherParticipantId !== conversation.otherParticipantId
        );
    }
  }
}
