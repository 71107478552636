import { Component, Input } from '@angular/core';
import { LayoutService } from "../service/app.layout.service";
import { MenuService } from "../app.menu.service";
import { Subscription } from 'rxjs';
import { MessageNotificationService } from '../../demo/service/message-notification.service';

@Component({
    selector: 'app-config',
    templateUrl: './app.config.component.html',
    styleUrls: ['./app.config.component.scss']
})
export class AppConfigComponent {

    subscriptions: Subscription[] = []
    hasLoadedConversations = false;
    numberOfNewMessages: string | undefined = undefined;
    userLoaded = false;
    private messageCheckInterval: Subscription | null = null;

    constructor(
        public layoutService: LayoutService,
        public menuService: MenuService,
        private messageNotificationService: MessageNotificationService
    ) {


    }

    get visible(): boolean {
        return this.layoutService.state.configSidebarVisible;
    }

    set visible(_val: boolean) {
        this.layoutService.state.configSidebarVisible = _val;
    }

    onConfigButtonClick() {
        this.layoutService.showConfigSidebar();
    }

}
