import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
    CustomerMasterDataControllerApi,
    CustomerTableItemDto,
    CustomerTableItemsDto
} from '@brody-bookings/api';
import {BehaviorSubject, Observable} from 'rxjs';

type CustomerMaster = { name: string };
type Customer = { name: string }

@Injectable()
export class CustomerService {
    private customersTableSubject: BehaviorSubject<CustomerTableItemsDto> = new BehaviorSubject<CustomerTableItemsDto>({
        customerTableItems: []
    });
    private customersTable$ = this.customersTableSubject.asObservable()

    constructor(
        private http: HttpClient,
        private customerMasterDataControllerApi: CustomerMasterDataControllerApi
    ) {
    }

    getCustomersForTable() {

        this.customerMasterDataControllerApi.getCustomers().then((customerTableItemsDto) => {
            const { customerTableItems} = customerTableItemsDto;
            this.customersTableSubject.next({customerTableItems});
        });
        return this.customersTable$;
    }

    createCustomer(name: string) {
        return new Observable<CustomerTableItemDto>(observer => {
            this.customerMasterDataControllerApi.createCustomer({
                customerMasterDataCreateRequestDto: {
                    name: {
                        customerName: name
                    }
                }
            }).then((customer) => {
                const currentCustomers = this.customersTableSubject.getValue()
                this.customersTableSubject.next({
                    ...currentCustomers,
                    customerTableItems: currentCustomers.customerTableItems 
                      ? [customer, ...currentCustomers.customerTableItems]
                      : [customer]
                });
                observer.next(customer);
                observer.complete();
            }).catch(error => {
                observer.error(error);
            });
        });
    }

    deleteCustomer(id: string){
        this.customerMasterDataControllerApi.deleteCustomer({id})
            .then(() => {
                const currentCustomers = this.customersTableSubject.getValue()
                this.customersTableSubject.next({
                    ...currentCustomers,
                    customerTableItems: currentCustomers.customerTableItems.filter((customer:CustomerTableItemDto) => {
                        return customer.customerId.value != id
                    })
                })
            })
    }


}
