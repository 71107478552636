/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectModelEntity } from './ProjectModelEntity';
import {
    ProjectModelEntityFromJSON,
    ProjectModelEntityFromJSONTyped,
    ProjectModelEntityToJSON,
} from './ProjectModelEntity';

/**
 * 
 * @export
 * @interface ProjectModelProductionDetailsEventEntity
 */
export interface ProjectModelProductionDetailsEventEntity {
    /**
     * 
     * @type {number}
     * @memberof ProjectModelProductionDetailsEventEntity
     */
    id?: number;
    /**
     * 
     * @type {ProjectModelEntity}
     * @memberof ProjectModelProductionDetailsEventEntity
     */
    projectModel?: ProjectModelEntity;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelProductionDetailsEventEntity
     */
    projectModelProductionDetailsEventId: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelProductionDetailsEventEntity
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof ProjectModelProductionDetailsEventEntity
     */
    startDate: Date;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelProductionDetailsEventEntity
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelProductionDetailsEventEntity
     */
    startTime: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelProductionDetailsEventEntity
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelProductionDetailsEventEntity
     */
    motif?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectModelProductionDetailsEventEntity
     */
    modelConfirmed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProjectModelProductionDetailsEventEntity
     */
    durationInMinutes?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelProductionDetailsEventEntity
     */
    customer: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelProductionDetailsEventEntity
     */
    booker: string;
}

/**
 * Check if a given object implements the ProjectModelProductionDetailsEventEntity interface.
 */
export function instanceOfProjectModelProductionDetailsEventEntity(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "projectModelProductionDetailsEventId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "startDate" in value;
    isInstance = isInstance && "startTime" in value;
    isInstance = isInstance && "location" in value;
    isInstance = isInstance && "customer" in value;
    isInstance = isInstance && "booker" in value;

    return isInstance;
}

export function ProjectModelProductionDetailsEventEntityFromJSON(json: any): ProjectModelProductionDetailsEventEntity {
    return ProjectModelProductionDetailsEventEntityFromJSONTyped(json, false);
}

export function ProjectModelProductionDetailsEventEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectModelProductionDetailsEventEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'projectModel': !exists(json, 'projectModel') ? undefined : ProjectModelEntityFromJSON(json['projectModel']),
        'projectModelProductionDetailsEventId': json['projectModelProductionDetailsEventId'],
        'name': json['name'],
        'startDate': (new Date(json['startDate'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'startTime': json['startTime'],
        'location': json['location'],
        'motif': !exists(json, 'motif') ? undefined : json['motif'],
        'modelConfirmed': !exists(json, 'modelConfirmed') ? undefined : json['modelConfirmed'],
        'durationInMinutes': !exists(json, 'durationInMinutes') ? undefined : json['durationInMinutes'],
        'customer': json['customer'],
        'booker': json['booker'],
    };
}

export function ProjectModelProductionDetailsEventEntityToJSON(value?: ProjectModelProductionDetailsEventEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'projectModel': ProjectModelEntityToJSON(value.projectModel),
        'projectModelProductionDetailsEventId': value.projectModelProductionDetailsEventId,
        'name': value.name,
        'startDate': (value.startDate.toISOString().substr(0,10)),
        'description': value.description,
        'startTime': value.startTime,
        'location': value.location,
        'motif': value.motif,
        'modelConfirmed': value.modelConfirmed,
        'durationInMinutes': value.durationInMinutes,
        'customer': value.customer,
        'booker': value.booker,
    };
}

