/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BookingModelEntity } from './BookingModelEntity';
import {
    BookingModelEntityFromJSON,
    BookingModelEntityFromJSONTyped,
    BookingModelEntityToJSON,
} from './BookingModelEntity';
import type { DetailsCastingEntity } from './DetailsCastingEntity';
import {
    DetailsCastingEntityFromJSON,
    DetailsCastingEntityFromJSONTyped,
    DetailsCastingEntityToJSON,
} from './DetailsCastingEntity';
import type { ProjectModelBillingEntity } from './ProjectModelBillingEntity';
import {
    ProjectModelBillingEntityFromJSON,
    ProjectModelBillingEntityFromJSONTyped,
    ProjectModelBillingEntityToJSON,
} from './ProjectModelBillingEntity';
import type { ProjectModelCastingEntity } from './ProjectModelCastingEntity';
import {
    ProjectModelCastingEntityFromJSON,
    ProjectModelCastingEntityFromJSONTyped,
    ProjectModelCastingEntityToJSON,
} from './ProjectModelCastingEntity';
import type { ProjectModelProductionDetailsEntity } from './ProjectModelProductionDetailsEntity';
import {
    ProjectModelProductionDetailsEntityFromJSON,
    ProjectModelProductionDetailsEntityFromJSONTyped,
    ProjectModelProductionDetailsEntityToJSON,
} from './ProjectModelProductionDetailsEntity';
import type { RoleDetailsEntity } from './RoleDetailsEntity';
import {
    RoleDetailsEntityFromJSON,
    RoleDetailsEntityFromJSONTyped,
    RoleDetailsEntityToJSON,
} from './RoleDetailsEntity';
import type { TravelCostsEntity } from './TravelCostsEntity';
import {
    TravelCostsEntityFromJSON,
    TravelCostsEntityFromJSONTyped,
    TravelCostsEntityToJSON,
} from './TravelCostsEntity';

/**
 * 
 * @export
 * @interface ProjectModelEntity
 */
export interface ProjectModelEntity {
    /**
     * 
     * @type {number}
     * @memberof ProjectModelEntity
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelEntity
     */
    projectModelId?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectModelEntity
     */
    projectId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelEntity
     */
    modelId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelEntity
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelEntity
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelEntity
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelEntity
     */
    mobile?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelEntity
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelEntity
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelEntity
     */
    status?: ProjectModelEntityStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof ProjectModelEntity
     */
    dailyRate?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectModelEntity
     */
    fee?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelEntity
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelEntity
     */
    buyoutType?: ProjectModelEntityBuyoutTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ProjectModelEntity
     */
    buyoutValue?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelEntity
     */
    externalNote?: string;
    /**
     * 
     * @type {Date}
     * @memberof ProjectModelEntity
     */
    addedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelEntity
     */
    roleId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelEntity
     */
    roleName?: string;
    /**
     * 
     * @type {BookingModelEntity}
     * @memberof ProjectModelEntity
     */
    bookingModel?: BookingModelEntity;
    /**
     * 
     * @type {ProjectModelCastingEntity}
     * @memberof ProjectModelEntity
     */
    casting?: ProjectModelCastingEntity;
    /**
     * 
     * @type {ProjectModelProductionDetailsEntity}
     * @memberof ProjectModelEntity
     */
    productionDetails?: ProjectModelProductionDetailsEntity;
    /**
     * 
     * @type {RoleDetailsEntity}
     * @memberof ProjectModelEntity
     */
    roleDetails?: RoleDetailsEntity;
    /**
     * 
     * @type {DetailsCastingEntity}
     * @memberof ProjectModelEntity
     */
    detailsCasting?: DetailsCastingEntity;
    /**
     * 
     * @type {ProjectModelBillingEntity}
     * @memberof ProjectModelEntity
     */
    projectModelBilling?: ProjectModelBillingEntity;
    /**
     * 
     * @type {Array<TravelCostsEntity>}
     * @memberof ProjectModelEntity
     */
    travelCosts?: Array<TravelCostsEntity>;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelEntity
     */
    oauthUserId?: string;
}


/**
 * @export
 */
export const ProjectModelEntityStatusEnum = {
    Rejected: 'REJECTED',
    Shortlist: 'SHORTLIST',
    OptRequested: 'OPT_REQUESTED',
    RejectedByModel: 'REJECTED_BY_MODEL',
    Optioned: 'OPTIONED',
    Casting: 'CASTING',
    RejectedByCustomer: 'REJECTED_BY_CUSTOMER',
    Selected: 'SELECTED'
} as const;
export type ProjectModelEntityStatusEnum = typeof ProjectModelEntityStatusEnum[keyof typeof ProjectModelEntityStatusEnum];

/**
 * @export
 */
export const ProjectModelEntityBuyoutTypeEnum = {
    Incl: 'INCL',
    Flat: 'FLAT',
    Percentage: 'PERCENTAGE'
} as const;
export type ProjectModelEntityBuyoutTypeEnum = typeof ProjectModelEntityBuyoutTypeEnum[keyof typeof ProjectModelEntityBuyoutTypeEnum];


/**
 * Check if a given object implements the ProjectModelEntity interface.
 */
export function instanceOfProjectModelEntity(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectModelEntityFromJSON(json: any): ProjectModelEntity {
    return ProjectModelEntityFromJSONTyped(json, false);
}

export function ProjectModelEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectModelEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'projectModelId': !exists(json, 'projectModelId') ? undefined : json['projectModelId'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'modelId': !exists(json, 'modelId') ? undefined : json['modelId'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'mobile': !exists(json, 'mobile') ? undefined : json['mobile'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'dailyRate': !exists(json, 'dailyRate') ? undefined : json['dailyRate'],
        'fee': !exists(json, 'fee') ? undefined : json['fee'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'buyoutType': !exists(json, 'buyoutType') ? undefined : json['buyoutType'],
        'buyoutValue': !exists(json, 'buyoutValue') ? undefined : json['buyoutValue'],
        'externalNote': !exists(json, 'externalNote') ? undefined : json['externalNote'],
        'addedAt': !exists(json, 'addedAt') ? undefined : (new Date(json['addedAt'])),
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
        'roleName': !exists(json, 'roleName') ? undefined : json['roleName'],
        'bookingModel': !exists(json, 'bookingModel') ? undefined : BookingModelEntityFromJSON(json['bookingModel']),
        'casting': !exists(json, 'casting') ? undefined : ProjectModelCastingEntityFromJSON(json['casting']),
        'productionDetails': !exists(json, 'productionDetails') ? undefined : ProjectModelProductionDetailsEntityFromJSON(json['productionDetails']),
        'roleDetails': !exists(json, 'roleDetails') ? undefined : RoleDetailsEntityFromJSON(json['roleDetails']),
        'detailsCasting': !exists(json, 'detailsCasting') ? undefined : DetailsCastingEntityFromJSON(json['detailsCasting']),
        'projectModelBilling': !exists(json, 'projectModelBilling') ? undefined : ProjectModelBillingEntityFromJSON(json['projectModelBilling']),
        'travelCosts': !exists(json, 'travelCosts') ? undefined : ((json['travelCosts'] as Array<any>).map(TravelCostsEntityFromJSON)),
        'oauthUserId': !exists(json, 'oauthUserId') ? undefined : json['oauthUserId'],
    };
}

export function ProjectModelEntityToJSON(value?: ProjectModelEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'projectModelId': value.projectModelId,
        'projectId': value.projectId,
        'modelId': value.modelId,
        'image': value.image,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'mobile': value.mobile,
        'phone': value.phone,
        'email': value.email,
        'status': value.status,
        'dailyRate': value.dailyRate,
        'fee': value.fee,
        'note': value.note,
        'buyoutType': value.buyoutType,
        'buyoutValue': value.buyoutValue,
        'externalNote': value.externalNote,
        'addedAt': value.addedAt === undefined ? undefined : (value.addedAt.toISOString()),
        'roleId': value.roleId,
        'roleName': value.roleName,
        'bookingModel': BookingModelEntityToJSON(value.bookingModel),
        'casting': ProjectModelCastingEntityToJSON(value.casting),
        'productionDetails': ProjectModelProductionDetailsEntityToJSON(value.productionDetails),
        'roleDetails': RoleDetailsEntityToJSON(value.roleDetails),
        'detailsCasting': DetailsCastingEntityToJSON(value.detailsCasting),
        'projectModelBilling': ProjectModelBillingEntityToJSON(value.projectModelBilling),
        'travelCosts': value.travelCosts === undefined ? undefined : ((value.travelCosts as Array<any>).map(TravelCostsEntityToJSON)),
        'oauthUserId': value.oauthUserId,
    };
}

