import { ModelMasterDataSearchFilterDto } from 'libs/api-typescript-angular/src/model/modelMasterDataSearchFilterDto';
import { ProjectModelFormValuesDto } from 'libs/api-typescript-angular/src/model/projectModelFormValuesDto';

export namespace ProjectEditModelActions {
    export class UpdateSelectedProjectRoleForModelSelection {
        static readonly type = '[ProjectEdit] Update Selected Project Role For Model Selection';
        constructor(public roleId: string) { }
    }

    export class LoadProjectModels {
        static readonly type = '[ProjectEdit] Load Project Models';
        constructor(public projectId: string, public roleId: string) { }
    }

    export class ChangeProjectModel {
        static readonly type = '[ProjectEdit] Change Project Model';
        constructor(
            public projectId: string, 
            public roleId: string, 
            public modelId: string, 
            public model: ProjectModelFormValuesDto
        ) { }
    }

    export class ChangeProjectModelBuyout { 
        static readonly type = '[ProjectEdit] Change Project Model Buyout';
        constructor(
            public projectId: string,
            public roleId: string,
            public buyoutType: string,
            public buyoutValue: number
        ) { }
    }

    export class AddProductionDays {
        static readonly type = '[ProjectEdit] Add Production Days';
        constructor(
            public projectId: string,
            public roleId: string,
            public projectModelId: string,
            public events: any[],
            public productionDay: string
        ) { }
    }

    export class ChangeProductionDay {
        static readonly type = '[ProjectEdit] Change Production Day';
        constructor(
            public projectId: string,
            public roleId: string,
            public projectModelId: string,
            public eventId: string,
            public name: string,
            public description: string,
            public startTime: string,
            public locationId: string,
            public motifId: string
        ) { }
    }

    export class DeleteProductionDayEvent {
        static readonly type = '[ProjectEdit] Delete Production Day Event';
        constructor(
            public projectId: string,
            public roleId: string,
            public projectModelId: string,
            public eventId: string
        ) { }
    }

    export class SetProductionDay {
        static readonly type = '[ProjectEdit] Set Production Day';
        constructor(public productionDay: Date) { }
    }

    export class LoadProductionDayEvents {
        static readonly type = '[ProjectEdit] Load Production Day Events of Model and Day';
        constructor(
            public projectModelId: string,
            public productionDay: Date
        ) { }
    }


    export class AddModelToProjectRole {
        static readonly type = '[ProjectEdit] Add Model To Project Role';
        constructor(public projectId: string, public roleId: string, public modelId: string) { }
    }

    export class LoadSearchModels {
        static readonly type = '[ProjectEdit] Load Search Models';
        constructor(public filters: ModelMasterDataSearchFilterDto) { }
    }

    export class DeleteProjectModel {
        static readonly type = '[ProjectEdit] Delete Project Model';
        constructor(
            public projectId: string,
            public roleId: string,
            public modelId: string
        ) { }
    }

}
