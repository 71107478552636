/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BodyAccessories
 */
export interface BodyAccessories {
    /**
     * 
     * @type {boolean}
     * @memberof BodyAccessories
     */
    earHole: boolean;
    /**
     * 
     * @type {string}
     * @memberof BodyAccessories
     */
    piercings: string;
    /**
     * 
     * @type {string}
     * @memberof BodyAccessories
     */
    tattoos: string;
    /**
     * 
     * @type {string}
     * @memberof BodyAccessories
     */
    scars: string;
    /**
     * 
     * @type {string}
     * @memberof BodyAccessories
     */
    earHoleString: string;
    /**
     * 
     * @type {string}
     * @memberof BodyAccessories
     */
    allergies: string;
}

/**
 * Check if a given object implements the BodyAccessories interface.
 */
export function instanceOfBodyAccessories(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "earHole" in value;
    isInstance = isInstance && "piercings" in value;
    isInstance = isInstance && "tattoos" in value;
    isInstance = isInstance && "scars" in value;
    isInstance = isInstance && "earHoleString" in value;
    isInstance = isInstance && "allergies" in value;

    return isInstance;
}

export function BodyAccessoriesFromJSON(json: any): BodyAccessories {
    return BodyAccessoriesFromJSONTyped(json, false);
}

export function BodyAccessoriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): BodyAccessories {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'earHole': json['earHole'],
        'piercings': json['piercings'],
        'tattoos': json['tattoos'],
        'scars': json['scars'],
        'earHoleString': json['earHoleString'],
        'allergies': json['allergies'],
    };
}

export function BodyAccessoriesToJSON(value?: BodyAccessories | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'earHole': value.earHole,
        'piercings': value.piercings,
        'tattoos': value.tattoos,
        'scars': value.scars,
        'earHoleString': value.earHoleString,
        'allergies': value.allergies,
    };
}

