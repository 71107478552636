import { NgModule } from '@angular/core';
import { CommonModule, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppLayoutModule } from './layout/app.layout.module';
import { NotfoundComponent } from './demo/components/notfound/notfound.component';
import { ProductService } from './demo/service/product.service';
import { CountryService } from './demo/service/country.service';
import { CustomerService } from './demo/service/customer.service';
import { EventService } from './demo/service/event.service';
import { IconService } from './demo/service/icon.service';
import { NodeService } from './demo/service/node.service';
import { PhotoService } from './demo/service/photo.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { LanguageService } from './demo/service/language.service';
import { ModelService } from './demo/service/model.service';
import { PlaceholderModelsService } from './demo/service/placeholderModels.service';
import { AuthenticationControllerApi, ModelMasterDataControllerApi, ProjectControllerApi } from '@brody-bookings/api';
import { TranslationService } from './demo/service/translation.service';
import { AuthModule } from '@auth0/auth0-angular';
import { environment as env } from '../environments/environment';
import { AppLoadingComponent } from './layout/app.loading.component';
import { ButtonModule } from 'primeng/button';
import { LoginComponent } from './layout/app.login.component';
import { AuthenticationService } from './layout/service/app.auth.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FinalInterceptor } from './FinalInterceptor';
import { ErrorModalComponent } from './demo/components/errorModal/error.modal.component';
import { DialogModule } from 'primeng/dialog';
import { ConfirmModalComponent } from './demo/components/confirmModal/confirm-modal.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SpeedDialModule } from 'primeng/speeddial';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { TreeModule } from 'primeng/tree';
import { TreeSelectModule } from 'primeng/treeselect';
import { ToastModule } from 'primeng/toast';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { SkeletonModule } from 'primeng/skeleton';
import { ImagePreviewComponent } from './demo/components/shared/image-preview/image-preview.component';
import { ImageModule } from 'primeng/image';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { ProjectEditState } from './demo/state/project-edit/project-edit.state';
import { ProjectFormService } from './demo/service/project.form.service';
import { ModelSearchService } from './demo/service/model.search.service';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { LOCALE_ID } from '@angular/core';

registerLocaleData(localeDe);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    NotfoundComponent,
    AppLoadingComponent,
    LoginComponent,
    ErrorModalComponent,
    ConfirmModalComponent,
    ImagePreviewComponent
  ],
  imports: [
    ButtonModule,
    CommonModule,
    AppRoutingModule,
    AppLayoutModule,
    HttpClientModule,
    DialogModule,
    ConfirmDialogModule,
    SpeedDialModule,
    TreeModule,
    TreeSelectModule,
    ToastModule,
    DropdownModule,
    SkeletonModule,
    TableModule,
    FormsModule,
    CheckboxModule,
    AuthModule.forRoot({
      ...env.auth
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'de'
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: false,
      registrationStrategy: 'registerWhenStable:30000'
    }),
    FormsModule,
    ImageModule,
    NgxsModule.forRoot([], {
      developmentMode: !env.production
    }),
    !env.production ? NgxsLoggerPluginModule.forRoot() : [],
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsModule.forFeature([ProjectEditState])
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: FinalInterceptor, multi: true },
    CountryService,
    CustomerService,
    EventService,
    IconService,
    NodeService,
    PhotoService,
    ProductService,
    LanguageService,
    ModelService,
    PlaceholderModelsService,
    ModelMasterDataControllerApi,
    TranslationService,
    AuthenticationService,
    AuthenticationControllerApi,
    ConfirmationService,
    DialogService,
    MessageService,
    ProjectFormService,
    ProjectControllerApi,
    ModelSearchService,
    { 
      provide: LOCALE_ID, 
      useValue: 'de-DE' 
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
