import {Injectable} from "@angular/core";
// @ts-ignore
import {
    ModelMasterDataControllerApi,
    ModelMasterDataSearchItemsDto,
    ModelMasterDataTableItemDto,
    ModelMasterDataTableItemsDto,
    ProjectSearchItemDto,
    ProjectRoleSearchItemsDto,
    ModelMasterDataSearchFilterDto,
    ModelMasterDataSearchFormValuesDto, ProjectControllerApi
} from '@brody-bookings/api'
import {BehaviorSubject} from "rxjs";
import {LanguageService} from "./language.service";

@Injectable()
export class ModelSearchService {

    private usedLanguage: string | null = null;

    private modelsSearchSubject: BehaviorSubject<ModelMasterDataSearchItemsDto> = new BehaviorSubject<ModelMasterDataSearchItemsDto>({
        modelMasterDataSearchItems: []
    })
    private modelsSearch$ = this.modelsSearchSubject.asObservable()

    private modelsSearchFormValuesSubject: BehaviorSubject<ModelMasterDataSearchFormValuesDto> = new BehaviorSubject<ModelMasterDataSearchFormValuesDto>({})
    private modelsSearchFormValues$ = this.modelsSearchFormValuesSubject.asObservable()

    private projectsSearchSubject: BehaviorSubject<ProjectSearchItemDto[]> = new BehaviorSubject<ProjectSearchItemDto[]>([])
    private projectsSearch$ = this.projectsSearchSubject.asObservable()
    private projectRolesSearchSubject: BehaviorSubject<ProjectRoleSearchItemsDto> = new BehaviorSubject<ProjectRoleSearchItemsDto>({
        roles: []
    })
    private projectRolesSearch$ = this.projectRolesSearchSubject.asObservable()


    constructor(
        private modelMasterDataControllerApi: ModelMasterDataControllerApi,
        private projectControllerApi: ProjectControllerApi,
        private languageService: LanguageService
    ) {
    }

    getModelSearchFormValues() {
        this.languageService.getLanguage$().subscribe((currentLanguage) => {
            const currentValue = this.modelsSearchFormValuesSubject.value

            // fetch filter form values only if it's not fetched yet or if language has changed

            if (JSON.stringify(currentValue) === JSON.stringify({}) || currentLanguage !== this.usedLanguage) {
                this.modelMasterDataControllerApi.getModelsSearchFormValues1({
                    lang: currentLanguage
                }).then((modelMasterDataSearchFormValuesDto) => {
                    this.modelsSearchFormValuesSubject.next(modelMasterDataSearchFormValuesDto)
                })
            }

            this.usedLanguage = currentLanguage;
        });

        return this.modelsSearchFormValues$;
    }

    getSearchedModels(filters: ModelMasterDataSearchFilterDto) {
        this.modelMasterDataControllerApi.getModelsForSearch({
            modelMasterDataSearchFilterDto: filters
        }).then((modelMasterDataSearchItemsDto: ModelMasterDataSearchItemsDto) => {
            const {modelMasterDataSearchItems} = modelMasterDataSearchItemsDto;
            this.modelsSearchSubject.next({
                modelMasterDataSearchItems
            })
        });

        return this.modelsSearch$;
    }

    getSearchedProjects() {
        this.projectControllerApi.getProjectAsSearchItemList().then((projectSearchItems) => {
            this.projectsSearchSubject.next(projectSearchItems.projectSearchItems)
        })
        return this.projectsSearch$
    }

}
