import { Component, OnInit } from '@angular/core';
import {ConfirmModalService} from "../../service/confirmModal.service";
import { ConfirmationService } from "primeng/api";

@Component({
    selector: "app-confirm-modal",
    templateUrl: "./confirm.modal.html"
})
export class ConfirmModalComponent implements OnInit{
    display = false;
    confirmationText = "";
    callback: Function = () => {

    }

    ngOnInit(): void {
    }

    constructor(
        private confirmModalService: ConfirmModalService,
        private confirmationService: ConfirmationService
    ) {

        this.confirmModalService.showConfirmModal$.subscribe((val) => {
            this.showConfirmation(val.message);
            this.callback = val.callback;

            this.confirmationService.confirm({
                key: 'confirm1',
                message: this.confirmationText,
                accept: () => {
                    this.callback();
                },
            });
        })

    }

    public showConfirmation(message: string): void {
        this.confirmationText = message;
        this.display = true;
    }

}
