/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectModelProductionDetailsEventEntity } from './ProjectModelProductionDetailsEventEntity';
import {
    ProjectModelProductionDetailsEventEntityFromJSON,
    ProjectModelProductionDetailsEventEntityFromJSONTyped,
    ProjectModelProductionDetailsEventEntityToJSON,
} from './ProjectModelProductionDetailsEventEntity';

/**
 * 
 * @export
 * @interface ProjectModelProductionDetailsEntity
 */
export interface ProjectModelProductionDetailsEntity {
    /**
     * 
     * @type {string}
     * @memberof ProjectModelProductionDetailsEntity
     */
    infoArrival?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelProductionDetailsEntity
     */
    infoSleepOver?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelProductionDetailsEntity
     */
    infoAdditional?: string;
    /**
     * 
     * @type {Array<ProjectModelProductionDetailsEventEntity>}
     * @memberof ProjectModelProductionDetailsEntity
     */
    events?: Array<ProjectModelProductionDetailsEventEntity>;
}

/**
 * Check if a given object implements the ProjectModelProductionDetailsEntity interface.
 */
export function instanceOfProjectModelProductionDetailsEntity(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectModelProductionDetailsEntityFromJSON(json: any): ProjectModelProductionDetailsEntity {
    return ProjectModelProductionDetailsEntityFromJSONTyped(json, false);
}

export function ProjectModelProductionDetailsEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectModelProductionDetailsEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'infoArrival': !exists(json, 'infoArrival') ? undefined : json['infoArrival'],
        'infoSleepOver': !exists(json, 'infoSleepOver') ? undefined : json['infoSleepOver'],
        'infoAdditional': !exists(json, 'infoAdditional') ? undefined : json['infoAdditional'],
        'events': !exists(json, 'events') ? undefined : ((json['events'] as Array<any>).map(ProjectModelProductionDetailsEventEntityFromJSON)),
    };
}

export function ProjectModelProductionDetailsEntityToJSON(value?: ProjectModelProductionDetailsEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'infoArrival': value.infoArrival,
        'infoSleepOver': value.infoSleepOver,
        'infoAdditional': value.infoAdditional,
        'events': value.events === undefined ? undefined : ((value.events as Array<any>).map(ProjectModelProductionDetailsEventEntityToJSON)),
    };
}

