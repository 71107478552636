import { Component, OnInit } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { createProjectsMenu } from './menu/createProjectsModel';
import { createAddressBookMenu } from './menu/createAddressBookMenu';
import { TranslationService } from '../demo/service/translation.service';
import { createAdminMenu } from './menu/createAdminMenu';
import { LanguageService } from '../demo/service/language.service';
import { createHomeMenu } from './menu/createHomeMenu';
import { AuthenticationService } from './service/app.auth.service';
import { createModelMenu } from './menu/createModelMenu';
import { createTaskManagerMenu } from './menu/createTaskmanagerMenu';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

  model: any[] = [];

  constructor(
    private authenticationService: AuthenticationService,
    public layoutService: LayoutService,
    private translationService: TranslationService,
    private languageService: LanguageService
  ) {
  }


  ngOnInit() {

    this.authenticationService.frontendUser$.subscribe((user) => {
      if (!user) return;

      this.languageService.getLanguage$().subscribe(() => {
        this.translationService.getMenuTranslations().then(menuTranslations => {

          if (user.userRoles.includes('Model')) {
            const modelHomeMenu = createModelMenu(menuTranslations.HOME, user.userId);

            this.model = [
              { ...modelHomeMenu[0] }
            ];
          } else {
            const homeMenu = createHomeMenu(menuTranslations.HOME);
            //const adminMenu = createAdminMenu(menuTranslations.ADMIN);
            const projectMenu = createProjectsMenu(menuTranslations.PROJECTS);
            const customerMenu = createAddressBookMenu(menuTranslations.ADDRESS_BOOK);
            const taskManagerMenu = createTaskManagerMenu(menuTranslations.TASK_MANAGER, menuTranslations.ADMIN);
            //const standingDataMenu = createStandingDataMenu(menuTranslations.STANDING_DATA);
            //const projectLocationsMenu = createProjectLocationsMenu(menuTranslations.PROJECT_LOCATIONS);

            this.model = [
              { ...projectMenu[0] },
              { ...customerMenu[0] },
              { ...taskManagerMenu[0] },
              //  { ...standingDataMenu[0] },
              //{ ...projectLocationsMenu[0] },
              // {
              //     label: 'Model Application',
              //     items: [
              //         {label: 'Application', icon: 'pi pi-fw pi-file', routerLink: ['/application']},
              //     ]
              // },
              // { ...adminMenu[0] }
            ];
          }

        });
      });
    });


  }
}
