/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';

/**
 * 
 * @export
 * @interface PersonalInfosFormValuesDto
 */
export interface PersonalInfosFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof PersonalInfosFormValuesDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonalInfosFormValuesDto
     */
    firstName?: string;
    /**
     * 
     * @type {AdminValue}
     * @memberof PersonalInfosFormValuesDto
     */
    sex?: AdminValue;
    /**
     * 
     * @type {Date}
     * @memberof PersonalInfosFormValuesDto
     */
    birthday?: Date;
    /**
     * 
     * @type {string}
     * @memberof PersonalInfosFormValuesDto
     */
    alias?: string;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof PersonalInfosFormValuesDto
     */
    sexOptions?: Array<AdminValue>;
    /**
     * 
     * @type {AdminValue}
     * @memberof PersonalInfosFormValuesDto
     */
    currentJob?: AdminValue;
    /**
     * 
     * @type {AdminValue}
     * @memberof PersonalInfosFormValuesDto
     */
    learnedJob?: AdminValue;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof PersonalInfosFormValuesDto
     */
    prefessionOptions?: Array<AdminValue>;
}

/**
 * Check if a given object implements the PersonalInfosFormValuesDto interface.
 */
export function instanceOfPersonalInfosFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PersonalInfosFormValuesDtoFromJSON(json: any): PersonalInfosFormValuesDto {
    return PersonalInfosFormValuesDtoFromJSONTyped(json, false);
}

export function PersonalInfosFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonalInfosFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'sex': !exists(json, 'sex') ? undefined : AdminValueFromJSON(json['sex']),
        'birthday': !exists(json, 'birthday') ? undefined : (new Date(json['birthday'])),
        'alias': !exists(json, 'alias') ? undefined : json['alias'],
        'sexOptions': !exists(json, 'sexOptions') ? undefined : ((json['sexOptions'] as Array<any>).map(AdminValueFromJSON)),
        'currentJob': !exists(json, 'currentJob') ? undefined : AdminValueFromJSON(json['currentJob']),
        'learnedJob': !exists(json, 'learnedJob') ? undefined : AdminValueFromJSON(json['learnedJob']),
        'prefessionOptions': !exists(json, 'prefessionOptions') ? undefined : ((json['prefessionOptions'] as Array<any>).map(AdminValueFromJSON)),
    };
}

export function PersonalInfosFormValuesDtoToJSON(value?: PersonalInfosFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'firstName': value.firstName,
        'sex': AdminValueToJSON(value.sex),
        'birthday': value.birthday === undefined ? undefined : (value.birthday.toISOString().substr(0,10)),
        'alias': value.alias,
        'sexOptions': value.sexOptions === undefined ? undefined : ((value.sexOptions as Array<any>).map(AdminValueToJSON)),
        'currentJob': AdminValueToJSON(value.currentJob),
        'learnedJob': AdminValueToJSON(value.learnedJob),
        'prefessionOptions': value.prefessionOptions === undefined ? undefined : ((value.prefessionOptions as Array<any>).map(AdminValueToJSON)),
    };
}

