import { enableProdMode, isDevMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .then(() => {
        //console.log(navigator)
        //console.log(environment)
      if ('serviceWorker' in navigator && environment.production) {
        // navigator.serviceWorker.register('sw.js').then(registration => {
        //   console.log('Custom Service Worker registered with scope:', registration.scope);
        // }).catch(err => {
        //   console.error('Second Service Worker registration failed:', err);
        // });
      }
    })
  .catch(err => console.error(err));
