/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectModelEntity } from './ProjectModelEntity';
import {
    ProjectModelEntityFromJSON,
    ProjectModelEntityFromJSONTyped,
    ProjectModelEntityToJSON,
} from './ProjectModelEntity';

/**
 * 
 * @export
 * @interface TravelCostsEntity
 */
export interface TravelCostsEntity {
    /**
     * 
     * @type {number}
     * @memberof TravelCostsEntity
     */
    id?: number;
    /**
     * 
     * @type {ProjectModelEntity}
     * @memberof TravelCostsEntity
     */
    projectModel?: ProjectModelEntity;
    /**
     * 
     * @type {string}
     * @memberof TravelCostsEntity
     */
    transportationMeans?: string;
    /**
     * 
     * @type {string}
     * @memberof TravelCostsEntity
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof TravelCostsEntity
     */
    costs?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TravelCostsEntity
     */
    paid?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TravelCostsEntity
     */
    tax?: boolean;
}

/**
 * Check if a given object implements the TravelCostsEntity interface.
 */
export function instanceOfTravelCostsEntity(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TravelCostsEntityFromJSON(json: any): TravelCostsEntity {
    return TravelCostsEntityFromJSONTyped(json, false);
}

export function TravelCostsEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): TravelCostsEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'projectModel': !exists(json, 'projectModel') ? undefined : ProjectModelEntityFromJSON(json['projectModel']),
        'transportationMeans': !exists(json, 'transportationMeans') ? undefined : json['transportationMeans'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'costs': !exists(json, 'costs') ? undefined : json['costs'],
        'paid': !exists(json, 'paid') ? undefined : json['paid'],
        'tax': !exists(json, 'tax') ? undefined : json['tax'],
    };
}

export function TravelCostsEntityToJSON(value?: TravelCostsEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'projectModel': ProjectModelEntityToJSON(value.projectModel),
        'transportationMeans': value.transportationMeans,
        'description': value.description,
        'costs': value.costs,
        'paid': value.paid,
        'tax': value.tax,
    };
}

