import { StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ProjectEditStateModel } from '../project-edit.model';
import { ProjectEditModelActions } from '../actions/model.actions';
import { tap } from 'rxjs/operators';
import { ProjectControllerService } from 'libs/api-typescript-angular/src/api/projectController.service';
import { ProjectModelListFormValuesDto } from 'libs/api-typescript-angular/src/model/projectModelListFormValuesDto';
import { ProjectEditAppointmentActions } from '../actions/appointment.actions';
import { ProjectModelProductionDetailsEvent } from 'libs/api-typescript-angular/src/model/projectModelProductionDetailsEvent';
import { ProjectEditBaseActions } from '../actions/base.actions';
import { AddModelToProjectRoleResponseDto } from 'libs/api-typescript-angular/src/model/addModelToProjectRoleResponseDto';
import { ModelSearchService } from '../../../service/model.search.service';
import { ModelMasterDataSearchItemsDto } from 'libs/api-typescript-angular/src/model/models';
import { ProjectEditState } from '../project-edit.state';


@Injectable()
export class ProjectEditModelHandlers {
    constructor(
        private projectControllerService: ProjectControllerService,
        private modelSearchService: ModelSearchService
    ) { }

    @Action(ProjectEditModelActions.LoadProjectModels)
    loadProjectModels(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditModelActions.LoadProjectModels) {
        const previousState = ctx.getState();
        const existingRole = previousState.projectRolesWithModels?.projectRoleWithModels?.find(
            (role) => role.roleId === action.roleId
        );

        if (existingRole) {
            return;
        }

        ctx.dispatch(new ProjectEditBaseActions.SetLoading(true));

        return this.projectControllerService.getProjectModelsByProjectIdAndRoleId(ctx.getState().language!!, action.projectId, action.roleId).pipe(
            tap((models: ProjectModelListFormValuesDto) => {
                const rolesList = Array.isArray(previousState.roles) ? previousState.roles : Object.values(previousState.roles || {});
                const roleName = rolesList.find((role: any) => role.id === action.roleId)?.name;

                // Nur hinzufügen wenn die Rolle noch nicht existiert
                const existingRoles = previousState.projectRolesWithModels?.projectRoleWithModels ?? [];
                const updatedRoles = existingRoles.some(r => r.roleId === action.roleId)
                    ? existingRoles
                    : [...existingRoles, {
                        roleId: action.roleId,
                        roleName: roleName,
                        projectModels: models.projectModelFormValuesDtoList,
                        requestDates: models.requestDates
                    }];

                ctx.patchState({
                    projectRolesWithModels: {
                        ...(previousState.projectRolesWithModels ?? {}),
                        projectRoleWithModels: updatedRoles
                    }
                });

                ctx.dispatch(new ProjectEditAppointmentActions.LoadAppointments(
                    ctx.getState().id?.id!!,
                    action.roleId,
                    ctx.getState().productionDetails?.startDateIsoString ? new Date(ctx.getState().productionDetails!!.startDateIsoString!!) : new Date(),
                    ctx.getState().productionDetails?.endDateIsoString ? new Date(ctx.getState().productionDetails!!.endDateIsoString!!) : new Date()
                ));

                ctx.dispatch(new ProjectEditBaseActions.SetLoading(false));
            })
        ).subscribe();
    }

    @Action(ProjectEditModelActions.ChangeProjectModel)
    changeProjectModel(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditModelActions.ChangeProjectModel) {
        return this.projectControllerService.changeProjectModel(
            ctx.getState().id?.id!!,
            action.modelId,
            action.roleId,
            {
                buyoutType: action.model.buyoutType === '-' ? undefined : action.model.buyoutType,
                buyoutValue: action.model.buyoutValue,
                salary: action.model.fee,
                note: action.model.note,
                externalNote: action.model.externalNote,
                status: action.model.status,
                dailyRate: action.model.dailyRate,
                language: ctx.getState().language
            }
        ).pipe(
            tap(() => {
                const state = ctx.getState();
                const projectRolesWithModels = state.projectRolesWithModels;

                if (projectRolesWithModels?.projectRoleWithModels) {
                    // Erstelle neue Arrays/Objekte mit den aktualisierten Werten
                    const updatedRoleWithModels = projectRolesWithModels.projectRoleWithModels.map(role => {
                        if (role.roleId === action.roleId) {
                            return {
                                ...role,
                                projectModels: role.projectModels?.map(model =>
                                    model.modelId === action.modelId
                                        ? { ...model, ...action.model }
                                        : model
                                )
                            };
                        }
                        return role;
                    });

                    ctx.patchState({
                        projectRolesWithModels: {
                            ...projectRolesWithModels,
                            projectRoleWithModels: updatedRoleWithModels
                        }
                    });
                }
            })
        );
    }

    @Action(ProjectEditModelActions.UpdateSelectedProjectRoleForModelSelection)
    updateSelectedProjectRoleForModelSelection(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditModelActions.UpdateSelectedProjectRoleForModelSelection
    ) {
        const previousState = ctx.getState();

        if (previousState.projectRolesWithModels?.selectedRoleId === action.roleId) {
            return;
        }

        // Entferne die Markierung von allen Models der vorherigen Rolle
        const clearedRoleWithModels = previousState.projectRolesWithModels?.projectRoleWithModels?.map(role => ({
            ...role,
            projectModels: role.projectModels?.map(model => ({
                ...model,
                isNewlyAdded: false
            }))
        }));

        ctx.patchState({
            projectRolesWithModels: {
                ...previousState.projectRolesWithModels,
                projectRoleWithModels: clearedRoleWithModels,
                selectedRoleId: action.roleId
            }
        });

        return ctx.dispatch(new ProjectEditModelActions.LoadProjectModels(previousState.id?.id!!, action.roleId));
    }

    @Action(ProjectEditModelActions.ChangeProjectModelBuyout)
    changeProjectModelBuyout(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditModelActions.ChangeProjectModelBuyout) {
        const state = ctx.getState();
        const projectRolesWithModels = state.projectRolesWithModels;

        if (projectRolesWithModels?.projectRoleWithModels) {
            // Erstelle neue Arrays/Objekte mit den aktualisierten Werten
            const updatedRoleWithModels = projectRolesWithModels.projectRoleWithModels.map(role => {
                if (role.roleId === action.roleId) {
                    return {
                        ...role,
                        projectModels: role.projectModels?.map(model => {
                            return {
                                ...model,
                                buyoutType: action.buyoutType,
                                buyoutValue: (action.buyoutType === 'inkl.' || action.buyoutType === 'incl.') ? 0 : action.buyoutValue
                            }
                        })
                    };
                }
                return role;
            });

            ctx.patchState({
                projectRolesWithModels: {
                    ...projectRolesWithModels,
                    projectRoleWithModels: updatedRoleWithModels
                }
            });
        }
    }

    @Action(ProjectEditModelActions.AddProductionDays)
    addProductionDays(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditModelActions.AddProductionDays) {
        return this.projectControllerService.addProductionDaysToProjectModel(
            action.projectId,
            action.projectModelId,
            {
                events: action.events,
                productionDate: action.productionDay
            }
        ).pipe(
            tap((res: ProjectModelProductionDetailsEvent[]) => {
                const state = ctx.getState();
                const projectRolesWithModels = state.projectRolesWithModels;

                if (projectRolesWithModels?.projectRoleWithModels) {
                    // Erstelle neue Arrays/Objekte mit den aktualisierten Werten
                    const updatedRoleWithModels = projectRolesWithModels.projectRoleWithModels.map(role => {
                        if (role.roleId === action.roleId) {
                            return {
                                ...role,
                                projectModels: role.projectModels?.map(model => {
                                    if (model.projectModelId === action.projectModelId) {
                                        const updatedEvents = [
                                            ...(model.modelProductionDetailsFormValuesDto?.events ?? []),
                                            ...res.map(event => ({
                                                modelProductionDetailsEventId: event.projectModelProductionDetailsEventId,
                                                name: event.name,
                                                startDate: event.startDate,
                                                description: event.description,
                                                startTime: event.startTime,
                                                location: event.location,
                                                motif: event.motif
                                            }))
                                        ];

                                        // Check if there's only one event for the new event's date
                                        const firstNewEvent = res[0];
                                        if (firstNewEvent) {
                                            const eventsOnSameDay = updatedEvents.filter(event =>
                                                new Date(event.startDate).toDateString() === new Date(firstNewEvent.startDate).toDateString()
                                            );

                                            if (eventsOnSameDay.length === 1) {
                                                setTimeout(() => {
                                                    ctx.dispatch(new ProjectEditAppointmentActions.LoadAppointmentsOfModel(
                                                        action.projectId,
                                                        action.roleId,
                                                        model.modelId!!,
                                                        ctx.getState().productionDetails?.startDate ? new Date(ctx.getState().productionDetails!!.startDate!!) : new Date(),
                                                        ctx.getState().productionDetails?.endDate ? new Date(ctx.getState().productionDetails!!.endDate!!) : new Date()
                                                    ));
                                                }, 1000);
                                            }
                                        }

                                        console.log('updatedEvents', updatedEvents);

                                        return {
                                            ...model,
                                            modelProductionDetailsFormValuesDto: {
                                                ...model.modelProductionDetailsFormValuesDto,
                                                events: updatedEvents
                                            }
                                        };
                                    }
                                    return model;
                                })
                            };
                        }
                        return role;
                    });

                    ctx.patchState({
                        projectRolesWithModels: {
                            ...projectRolesWithModels,
                            projectRoleWithModels: updatedRoleWithModels
                        }
                    });
                }
            })
        );
    }

    @Action(ProjectEditModelActions.ChangeProductionDay)
    changeProductionDay(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditModelActions.ChangeProductionDay) {
        console.log('action', action);
        return this.projectControllerService.updateProductionDaysToProjectModel(
            action.projectId,
            action.projectModelId,
            {
                productionDetailsEventId: action.eventId,
                name: action.name || "",
                description: action.description || "",
                startTime: action.startTime,
                motif: action.motifId || ""
            }
        ).pipe(
            tap((res: ProjectModelProductionDetailsEvent) => {
                const state = ctx.getState();
                console.log('res', res);
                const projectRolesWithModels = state.projectRolesWithModels;

                if (projectRolesWithModels?.projectRoleWithModels) {
                    // Erstelle neue Arrays/Objekte mit den aktualisierten Werten
                    const updatedRoleWithModels = projectRolesWithModels.projectRoleWithModels.map(role => {
                        if (role.roleId === action.roleId) {
                            return {
                                ...role,
                                projectModels: role.projectModels?.map(model => {
                                    if (model.projectModelId === action.projectModelId) {
                                        const updatedEvents = [
                                            ...(model.modelProductionDetailsFormValuesDto?.events ?? []).map((event: any) => {
                                                if (event.modelProductionDetailsEventId === action.eventId) {
                                                    return {
                                                        ...event,
                                                        name: action.name,
                                                        description: action.description,
                                                        startTime: action.startTime,
                                                        location: action.locationId,
                                                        motif: action.motifId
                                                    };
                                                }
                                                return event;
                                            })
                                        ];

                                        return {
                                            ...model,
                                            modelProductionDetailsFormValuesDto: {
                                                ...model.modelProductionDetailsFormValuesDto,
                                                events: updatedEvents
                                            }
                                        };
                                    }
                                    return model;
                                })
                            };
                        }
                        return role;
                    });

                    console.log('updatedRoleWithModels', updatedRoleWithModels);

                    ctx.patchState({
                        projectRolesWithModels: {
                            ...projectRolesWithModels,
                            projectRoleWithModels: updatedRoleWithModels
                        }
                    });
                }
            })
        );
    }

    @Action(ProjectEditModelActions.DeleteProductionDayEvent)
    deleteProductionDayEvent(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditModelActions.DeleteProductionDayEvent) {
        return this.projectControllerService.deleteProductionDayEventFromProjectModel(
            action.projectId,
            action.projectModelId,
            {
                eventId: action.eventId
            }
        ).pipe(
            tap(() => {
                const state = ctx.getState();
                const projectRolesWithModels = state.projectRolesWithModels;

                if (projectRolesWithModels?.projectRoleWithModels) {
                    // Get the deleted event's date before updating state
                    const roleWithModel = projectRolesWithModels.projectRoleWithModels.find(r => r.roleId === action.roleId);
                    const model = roleWithModel?.projectModels?.find(m => m.projectModelId === action.projectModelId);
                    const deletedEvent = model?.modelProductionDetailsFormValuesDto?.events?.find(e => e.modelProductionDetailsEventId === action.eventId);
                    const deletedEventDate = deletedEvent?.startDate ? new Date(deletedEvent.startDate) : null;

                    // Update state as before
                    const updatedRoleWithModels = projectRolesWithModels.projectRoleWithModels.map(role => {
                        if (role.roleId === action.roleId) {
                            return {
                                ...role,
                                projectModels: role.projectModels?.map(model => {
                                    if (model.projectModelId === action.projectModelId) {
                                        const updatedEvents = model.modelProductionDetailsFormValuesDto?.events?.filter(
                                            event => event.modelProductionDetailsEventId !== action.eventId
                                        ) ?? [];

                                        // Check if there are any remaining events on the same day
                                        if (deletedEventDate) {
                                            const eventsOnSameDay = updatedEvents.filter(event =>
                                                new Date(event.startDate).toDateString() === deletedEventDate.toDateString()
                                            );

                                            if (eventsOnSameDay.length === 0) {
                                                setTimeout(() => {
                                                    ctx.dispatch(new ProjectEditAppointmentActions.LoadAppointmentsOfModel(
                                                        action.projectId,
                                                        action.roleId,
                                                        model.modelId!!,
                                                        ctx.getState().productionDetails?.startDate ? new Date(ctx.getState().productionDetails!!.startDate!!) : new Date(),
                                                        ctx.getState().productionDetails?.endDate ? new Date(ctx.getState().productionDetails!!.endDate!!) : new Date()
                                                    ));
                                                }, 1000);
                                            }
                                        }

                                        return {
                                            ...model,
                                            modelProductionDetailsFormValuesDto: {
                                                ...model.modelProductionDetailsFormValuesDto,
                                                events: updatedEvents
                                            }
                                        };
                                    }
                                    return model;
                                })
                            };
                        }
                        return role;
                    });

                    ctx.patchState({
                        projectRolesWithModels: {
                            ...projectRolesWithModels,
                            projectRoleWithModels: updatedRoleWithModels
                        }
                    });
                }
            })
        );
    }

    @Action(ProjectEditModelActions.SetProductionDay)
    setProductionDay(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditModelActions.SetProductionDay) {
        const state = ctx.getState();
        const projectRolesWithModels = state.projectRolesWithModels;

        if (projectRolesWithModels?.projectRoleWithModels) {
            // Erstelle neue Arrays/Objekte mit den aktualisierten Werten
            const updatedRoleWithModels = projectRolesWithModels.projectRoleWithModels.map(role => {
                if (role.roleId === ctx.getState().selectedRoleInProductionDetails?.projectRoleId) {
                    return {
                        ...role,
                        projectModels: role.projectModels?.map(model => {
                            return {
                                ...model,
                                productionDayEvents: {
                                    ...model.productionDayEvents,
                                    productionDay: action.productionDay
                                }
                            }
                        })
                    };
                }
                return role;
            });

            ctx.patchState({
                projectRolesWithModels: {
                    ...projectRolesWithModels,
                    projectRoleWithModels: updatedRoleWithModels
                }
            });
        }
    }

    @Action(ProjectEditModelActions.LoadProductionDayEvents)
    loadProductionDayEvents(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditModelActions.LoadProductionDayEvents) {
        const state = ctx.getState();
        const projectRolesWithModels = state.projectRolesWithModels;

        const role = projectRolesWithModels?.projectRoleWithModels?.find((role) => role.roleId === ctx.getState().selectedRoleInProductionDetails?.projectRoleId);
        const projectModel = role?.projectModels?.find((model) => model.projectModelId === action.projectModelId);

        if (projectModel?.modelProductionDetailsFormValuesDto?.events) {
            return [];
        }

        return projectModel?.modelProductionDetailsFormValuesDto?.events?.filter((event) => {
            const eventDate = new Date(event.startDate);
            eventDate.setHours(12);
            const productionDay = action.productionDay;
            productionDay.setHours(12);

            return eventDate.toISOString() === productionDay.toISOString();
        });
    }

    @Action(ProjectEditModelActions.AddModelToProjectRole)
    addModelToProjectRole(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditModelActions.AddModelToProjectRole) {
        return this.projectControllerService.addModelToProjectRole(action.projectId, ctx.getState().language!!, action.roleId, action.modelId).pipe(
            tap((res: AddModelToProjectRoleResponseDto) => {
                const projectRolesWithModels = ctx.getState().projectRolesWithModels;
                const updatedRoleWithModels = projectRolesWithModels?.projectRoleWithModels?.map(role => {
                    if (role.roleId === action.roleId) {
                        return {
                            ...role,
                            projectModels: [...(role.projectModels ?? []), {
                                ...res.projectModel,
                                isNewlyAdded: true  // Markierung für neu hinzugefügte Models
                            }]
                        };
                    }
                    return role;
                });

                ctx.patchState({
                    projectRolesWithModels: {
                        ...projectRolesWithModels,
                        projectRoleWithModels: updatedRoleWithModels
                    }
                });

                ctx.dispatch(new ProjectEditAppointmentActions.LoadAppointmentsOfModel(
                    action.projectId,
                    action.roleId,
                    action.modelId,
                    ctx.getState().productionDetails?.startDate ? new Date(ctx.getState().productionDetails!!.startDate!!) : new Date(),
                    ctx.getState().productionDetails?.endDate ? new Date(ctx.getState().productionDetails!!.endDate!!) : new Date()
                ));
            })
        );
    }

    @Action(ProjectEditModelActions.LoadSearchModels)
    loadSearchModels(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditModelActions.LoadSearchModels) {
        const state = ctx.getState();

        ctx.patchState({
            modelSearch: {
                ...state.modelSearch,
                loading: true
            }
        });

        this.modelSearchService.getSearchedModels(action.filters).subscribe((formValues: ModelMasterDataSearchItemsDto) => {
            ctx.patchState({
                modelSearch: {
                    ...state.modelSearch,
                    allModels: formValues.modelMasterDataSearchItems,
                    loading: false
                }
            });
        });

    }

    @Action(ProjectEditModelActions.DeleteProjectModel)
    deleteProjectModel(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditModelActions.DeleteProjectModel) {
        return this.projectControllerService.deleteModelFromProjectRole(
            ctx.getState().id?.id!!,
            action.roleId,
            action.modelId
        ).pipe(
            tap((response) => {
                if (response.result === 'SUCCESS') {
                    const state = ctx.getState();
                    const projectRolesWithModels = state.projectRolesWithModels;

                    if (projectRolesWithModels?.projectRoleWithModels) {
                        const updatedRoleWithModels = projectRolesWithModels.projectRoleWithModels.map(role => {
                            if (role.roleId === action.roleId) {
                                return {
                                    ...role,
                                    projectModels: role.projectModels?.filter(
                                        model => model.modelId !== action.modelId
                                    )
                                };
                            }
                            return role;
                        });

                        ctx.patchState({
                            projectRolesWithModels: {
                                ...projectRolesWithModels,
                                projectRoleWithModels: updatedRoleWithModels
                            }
                        });
                    }
                }
                return response;
            })
        );
    }

}
