/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';

/**
 * 
 * @export
 * @interface BahnCardFormValuesDto
 */
export interface BahnCardFormValuesDto {
    /**
     * 
     * @type {AdminValue}
     * @memberof BahnCardFormValuesDto
     */
    bahncardType?: AdminValue;
    /**
     * 
     * @type {boolean}
     * @memberof BahnCardFormValuesDto
     */
    bahncardClass?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BahnCardFormValuesDto
     */
    cardNumber?: string;
    /**
     * 
     * @type {Date}
     * @memberof BahnCardFormValuesDto
     */
    expirationDate?: Date;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof BahnCardFormValuesDto
     */
    bahnCardTypeOptions?: Array<AdminValue>;
}

/**
 * Check if a given object implements the BahnCardFormValuesDto interface.
 */
export function instanceOfBahnCardFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BahnCardFormValuesDtoFromJSON(json: any): BahnCardFormValuesDto {
    return BahnCardFormValuesDtoFromJSONTyped(json, false);
}

export function BahnCardFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BahnCardFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bahncardType': !exists(json, 'bahncardType') ? undefined : AdminValueFromJSON(json['bahncardType']),
        'bahncardClass': !exists(json, 'bahncardClass') ? undefined : json['bahncardClass'],
        'cardNumber': !exists(json, 'cardNumber') ? undefined : json['cardNumber'],
        'expirationDate': !exists(json, 'expirationDate') ? undefined : (new Date(json['expirationDate'])),
        'bahnCardTypeOptions': !exists(json, 'bahnCardTypeOptions') ? undefined : ((json['bahnCardTypeOptions'] as Array<any>).map(AdminValueFromJSON)),
    };
}

export function BahnCardFormValuesDtoToJSON(value?: BahnCardFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bahncardType': AdminValueToJSON(value.bahncardType),
        'bahncardClass': value.bahncardClass,
        'cardNumber': value.cardNumber,
        'expirationDate': value.expirationDate === undefined ? undefined : (value.expirationDate.toISOString().substr(0,10)),
        'bahnCardTypeOptions': value.bahnCardTypeOptions === undefined ? undefined : ((value.bahnCardTypeOptions as Array<any>).map(AdminValueToJSON)),
    };
}

