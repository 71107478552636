import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject, firstValueFrom, Observable, Subject, switchMap } from 'rxjs';

import { AddNewBuyOutPerModelRequest } from 'libs/api-typescript-angular/src/model/addNewBuyOutPerModelRequest';
import { AddProductionDetailsEventDto } from 'libs/api-typescript-angular/src/model/addProductionDetailsEventDto';
import { BookingProducerFormValuesDto } from 'libs/api-typescript-angular/src/model/bookingProducerFormValuesDto';
import { BudgetFormValuesDto } from 'libs/api-typescript-angular/src/model/budgetFormValuesDto';
import { BuyOutPerModelFormValuesDto } from 'libs/api-typescript-angular/src/model/buyOutPerModelFormValuesDto';
import { BuyOutPerModelListFormValuesDto } from 'libs/api-typescript-angular/src/model/buyOutPerModelListFormValuesDto';
import { ChangeModelBookingInformationRequestDto } from 'libs/api-typescript-angular/src/model/changeModelBookingInformationRequestDto';
import { ChangeRoleDetailsRequestDto } from 'libs/api-typescript-angular/src/model/changeRoleDetailsRequestDto';
import { ChangeUsageTypeRequestDto } from 'libs/api-typescript-angular/src/model/changeUsageTypeRequestDto';
import { DemoPdfRequestDtoData } from 'libs/api-typescript-angular/src/model/demoPdfRequestDtoData';
import { DetailsCastingFormValuesDtoList } from 'libs/api-typescript-angular/src/model/detailsCastingFormValuesDtoList';
import { ExecutorFormValuesDto } from 'libs/api-typescript-angular/src/model/executorFormValuesDto';
import { ExternalParticipantFormValuesDto } from 'libs/api-typescript-angular/src/model/externalParticipantFormValuesDto';
import { ExtraCostsListFormValuesDto } from 'libs/api-typescript-angular/src/model/extraCostsListFormValuesDto';
import { FinalCustomerFormValuesDto } from 'libs/api-typescript-angular/src/model/finalCustomerFormValuesDto';
import { ProductionDetailsFormValuesDto } from 'libs/api-typescript-angular/src/model/productionDetailsFormValuesDto';
import { ProjectAdditionalParticipantFormValuesDto } from 'libs/api-typescript-angular/src/model/projectAdditionalParticipantFormValuesDto';
import { ProjectBillingAddresseeFormValuesDto } from 'libs/api-typescript-angular/src/model/projectBillingAddresseeFormValuesDto';
import { ProjectBillingInvoiceFormValuesDto } from 'libs/api-typescript-angular/src/model/projectBillingInvoiceFormValuesDto';
import { ProjectBillingSumFormValuesDto } from 'libs/api-typescript-angular/src/model/projectBillingSumFormValuesDto';
import { ProjectBuyOutFromValuesDto } from 'libs/api-typescript-angular/src/model/projectBuyOutFromValuesDto';
import { ProjectCreatorFormValuesDto } from 'libs/api-typescript-angular/src/model/projectCreatorFormValuesDto';
import { ProjectFormValuesDto } from 'libs/api-typescript-angular/src/model/projectFormValuesDto';
import { ProjectIdFormValuesDto } from 'libs/api-typescript-angular/src/model/projectIdFormValuesDto';
import { ProjectLocation } from 'libs/api-typescript-angular/src/model/projectLocation';
import { ProjectLocationFormValuesDto } from 'libs/api-typescript-angular/src/model/projectLocationFormValuesDto';
import { ProjectLocationsAndMotifsFormValuesDto } from 'libs/api-typescript-angular/src/model/projectLocationsAndMotifsFormValuesDto';
import { ProjectModelBillingFormValuesDto } from 'libs/api-typescript-angular/src/model/projectModelBillingFormValuesDto';
import { ProjectModelFormValuesDto } from 'libs/api-typescript-angular/src/model/projectModelFormValuesDto';
import { ProjectModelListFormValuesDto } from 'libs/api-typescript-angular/src/model/projectModelListFormValuesDto';
import { ProjectMotif } from 'libs/api-typescript-angular/src/model/projectMotif';
import { ProjectMotifFormValuesDto } from 'libs/api-typescript-angular/src/model/projectMotifFormValuesDto';
import { ProjectNameFormValuesDto } from 'libs/api-typescript-angular/src/model/projectNameFormValuesDto';
import { ProjectProducerFormValueDto } from 'libs/api-typescript-angular/src/model/projectProducerFormValueDto';
import { ProjectRole } from 'libs/api-typescript-angular/src/model/projectRole';
import { ProjectRoleForSelectionDto } from 'libs/api-typescript-angular/src/model/projectRoleForSelectionDto';
import { ProjectRoleFormValuesDto } from 'libs/api-typescript-angular/src/model/projectRoleFormValuesDto';
import { ProjectRoleSearchItemsDto } from 'libs/api-typescript-angular/src/model/projectRoleSearchItemsDto';
import { ProjectRolesFormValuesDto } from 'libs/api-typescript-angular/src/model/projectRolesFormValuesDto';
import { ProjectSearchItemDto } from 'libs/api-typescript-angular/src/model/projectSearchItemDto';
import { ProjectStatusFormValuesDto } from 'libs/api-typescript-angular/src/model/projectStatusFormValuesDto';
import { ProjectTotalModelFees } from 'libs/api-typescript-angular/src/model/projectTotalModelFees';
import { ProtocolEntryCreateRequestDto } from 'libs/api-typescript-angular/src/model/protocolEntryCreateRequestDto';
import { TravelCostListFormValuesDto } from 'libs/api-typescript-angular/src/model/travelCostListFormValuesDto';
import { UsageTypesFormValuesDto } from 'libs/api-typescript-angular/src/model/usageTypesFormValuesDto';
import { UseCaseResult } from 'libs/api-typescript-angular/src/model/useCaseResult';
import { ProjectCastingFormValuesDto } from 'libs/api-typescript-angular/src/model/projectCastingFormValuesDto';
import { AddAdditionalParticipantRequestDto } from 'libs/api-typescript-angular/src/model/addAdditionalParticipantRequestDto';

import { ProjectControllerService } from 'libs/api-typescript-angular/src/api/projectController.service';
import { GenerateDocumentDemoRequest, ProjectControllerApi } from 'libs/api-fetch/src/apis/ProjectControllerApi';
import { ProjectQueryControllerService } from 'libs/api-typescript-angular/src/api/projectQueryController.service';

import { LanguageService } from './language.service';
import { toISOStringWithLocalTime } from '../utils';
import { ProjectEditModelActions } from '../state/project-edit/actions/model.actions';
import { ProjectEditLocationActions } from '../state/project-edit/actions/location.actions';


type ProjectEditState = {
  id: ProjectIdFormValuesDto | undefined,
  name: ProjectNameFormValuesDto | undefined,
  status: ProjectStatusFormValuesDto | undefined,
  creator: ProjectCreatorFormValuesDto | undefined,
  producer: ProjectProducerFormValueDto | undefined,
  createdAt: string | undefined,
  lastEditedAt: string | Date | undefined,
  lastWatchedBy: string | undefined,
  lastEditor: string | undefined,
  finalCustomer: FinalCustomerFormValuesDto | undefined,
  productionDetails: ProductionDetailsFormValuesDto | undefined,
  budget: BudgetFormValuesDto | undefined,
  usageType: UsageTypesFormValuesDto | undefined,
  executor: ExecutorFormValuesDto | undefined,
  buyOut: string | undefined,
  casting: ProjectCastingFormValuesDto | undefined,
  additionalParticipantList: ProjectAdditionalParticipantFormValuesDto[],
  externalParticipantList: ExternalParticipantFormValuesDto[],
  roles: ProjectRolesFormValuesDto | undefined,

  rolesAsOptions: ProjectRoleForSelectionDto[] | undefined,
}

@Injectable()
export class ProjectFormService {

  private currentLanguage = 'de';

  // initial projectEditState
  private initialProjectEditState: ProjectEditState = {
    id: undefined,
    name: undefined,
    status: undefined,
    creator: undefined,
    producer: undefined,
    createdAt: undefined,
    lastEditedAt: undefined,
    lastWatchedBy: undefined,
    lastEditor: undefined,
    finalCustomer: undefined,
    productionDetails: undefined,
    budget: undefined,
    usageType: undefined,
    executor: undefined,
    buyOut: undefined,
    casting: undefined,
    additionalParticipantList: [],
    externalParticipantList: [],
    roles: undefined,
    rolesAsOptions: undefined
  }

  // Project Edit State
  private projectEditStateSubject: BehaviorSubject<ProjectEditState> = new BehaviorSubject<ProjectEditState>(this.initialProjectEditState);
  public projectEditState$: Observable<ProjectEditState> = this.projectEditStateSubject.asObservable();

  private selectedProjectUuidSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public selectedProjectUuid$: Observable<string | null> = this.selectedProjectUuidSubject.asObservable();


  /**
   * Loads the project form values for the given project id.
   * @param id - The project id.
   * @returns A promise that resolves to the project form values.
   */
  async loadProjectFormValues(id: string): Promise<ProjectFormValuesDto> {

    const lang = await firstValueFrom(this.languageService.getLanguage$());
    
    const request = this.projectControllerService.getProjectFormValues(lang, id);

    return firstValueFrom(request).then((result: ProjectFormValuesDto) => {
      this.projectEditStateSubject.next({ ...this.projectEditStateSubject.value, ...result });
      return result;
    });
  }

  loadProjectFormValues2(projectId: string) {
    return this.languageService.getLanguage$().pipe(
      switchMap((lang) => this.projectControllerService.getProjectFormValues(lang, projectId))
    );
  }

  getRolesAsOptions(projectId: string) {
    firstValueFrom(
      this.projectQueryControllerService.getProjectRolesForSelection(projectId) as Observable<ProjectRoleForSelectionDto[]>
    ).then((roles: ProjectRoleForSelectionDto[]) => {
      this.projectEditStateSubject.next({ ...this.projectEditStateSubject.value, rolesAsOptions: roles });
    });
  }







  public initialProjectFormValuesState = { roles: {} } as ProjectFormValuesDto;

  private projectFormSubject: BehaviorSubject<ProjectFormValuesDto> = new BehaviorSubject<ProjectFormValuesDto>(this.initialProjectFormValuesState);
  public projectForm$: Observable<ProjectFormValuesDto> = this.projectFormSubject.asObservable();

  private externalParticipantsSubject: BehaviorSubject<ExternalParticipantFormValuesDto[]> = new BehaviorSubject<ExternalParticipantFormValuesDto[]>([]);
  public externalParticipants$: Observable<ExternalParticipantFormValuesDto[]> = this.externalParticipantsSubject.asObservable();

  private additionalParticipantsSubject: BehaviorSubject<ProjectAdditionalParticipantFormValuesDto[]> = new BehaviorSubject<ProjectAdditionalParticipantFormValuesDto[]>([]);
  public additionalParticipants$: Observable<ProjectAdditionalParticipantFormValuesDto[]> = this.additionalParticipantsSubject.asObservable();

  private locationsSubject: BehaviorSubject<ProjectLocationsAndMotifsFormValuesDto> = new BehaviorSubject<ProjectLocationsAndMotifsFormValuesDto>({
    locations: [],
    motifs: []
  });
  public locations$: Observable<ProjectLocationsAndMotifsFormValuesDto> = this.locationsSubject.asObservable();

  private projectRolesSubject: BehaviorSubject<ProjectRoleSearchItemsDto> = new BehaviorSubject<ProjectRoleSearchItemsDto>({ roles: [] });
  public projectRoles$: Observable<ProjectRoleSearchItemsDto> = this.projectRolesSubject.asObservable();

  private detailsCastingSubject: BehaviorSubject<DetailsCastingFormValuesDtoList> = new BehaviorSubject<DetailsCastingFormValuesDtoList>({});
  public detailsCasting$: Observable<DetailsCastingFormValuesDtoList> = this.detailsCastingSubject.asObservable();

  private projectModelSubject: BehaviorSubject<ProjectModelListFormValuesDto> = new BehaviorSubject<ProjectModelListFormValuesDto>({
    projectModelFormValuesDtoList: [
      {
        firstName: '',
        lastName: '',
        modelId: '',
        mobile: '',
        phone: '',
        email: '',
        status: '',
        dailyRate: 0,
        fee: 0,
        note: '',
        buyoutType: '',
        buyoutValue: 0,
        externalNote: ''
      }
    ]
  });
  private projectModel$ = this.projectModelSubject.asObservable();

  private projectModelsByProjectAndRoleSubject: BehaviorSubject<{
    [key: string]: { projectModelFormValuesDto: ProjectModelFormValuesDto[], requestDates: string[] }
  }> = new BehaviorSubject<{
    [roleId: string]: { projectModelFormValuesDto: ProjectModelFormValuesDto[], requestDates: string[] }
  }>({});

  private projectModelsByRoleSubject: BehaviorSubject<{
    projectModelFormValuesDto: ProjectModelFormValuesDto[],
    requestDates: string[]
  } | null> = new BehaviorSubject<{
    projectModelFormValuesDto: ProjectModelFormValuesDto[],
    requestDates: string[]
  } | null>(null);
  private projectModelsByRole$ = this.projectModelsByRoleSubject.asObservable();

  private bookedModelByProjectSubject: BehaviorSubject<ProjectModelListFormValuesDto> = new BehaviorSubject<ProjectModelListFormValuesDto>({});
  private bookedModelByProject$ = this.bookedModelByProjectSubject.asObservable();

  private buyOutPerModelSubject: BehaviorSubject<BuyOutPerModelListFormValuesDto> = new BehaviorSubject<BuyOutPerModelListFormValuesDto>({
    buyOutPerModelFormValuesDtoList: []
  });
  private buyOutPerModel$ = this.buyOutPerModelSubject.asObservable();


  private modelsByRoleResetSubject = new Subject<void>();
  modelsByRoleReset$ = this.modelsByRoleResetSubject.asObservable();

  private modelAddedSubject = new Subject<string>();
  modelAdded$ = this.modelAddedSubject.asObservable();

  setSelectedProjectUuid(uuid: string) {
    this.selectedProjectUuidSubject.next(uuid);
  }

  constructor(
    private projectControllerApi: ProjectControllerService,
    private projectControllerApi2: ProjectControllerApi,
    private projectControllerService: ProjectControllerService,
    private languageService: LanguageService,
    private projectQueryControllerService: ProjectQueryControllerService,
    private store: Store
  ) {
  }

  getProjectForm(): Observable<ProjectFormValuesDto> {
    return this.projectForm$;
  }

  getTotalModelFees(projectId: string): Observable<ProjectTotalModelFees> {
    return this.projectControllerService.getProjectTotalModelFees(projectId);
  }

  /* Load collections of project form values */



  resetAdditionalParticipantsCache() {
    this.additionalParticipantsSubject.next([]);
  }

  /* Locations */

  loadLocations(projectId: string) {
    const currentValue = this.locationsSubject.getValue();

    firstValueFrom(this.projectControllerService.getProjectLocations(projectId)).then((locations) => {
      this.locationsSubject.next(locations);
    });
  }

  async changeProjectLocation(projectLocationFormValuesDto: ProjectLocationFormValuesDto, projectId: string): Promise<ProjectLocationFormValuesDto> {

    if (projectLocationFormValuesDto.locationId === null) {
      return projectLocationFormValuesDto;
    }

    const currentValue = this.locationsSubject.getValue();

    const updatedLocation = await firstValueFrom(this.projectControllerService.changeLocation(
      projectId, projectLocationFormValuesDto
    ));

    const updatedLocations = currentValue?.locations?.map((existingLocation: ProjectLocationFormValuesDto) => {
      if (existingLocation.locationId === updatedLocation.locationId) {
        return updatedLocation;
      }
      return existingLocation;
    });

    this.locationsSubject.next({
      ...currentValue,
      locations: updatedLocations
    });

    return updatedLocation;
  }

  addProjectLocation(projectId: string, name: string) {
    return firstValueFrom(this.projectControllerService.createProjectLocation(projectId, {
      locationName: name
    })).then((location: ProjectLocation) => {
      const currentValue = this.locationsSubject.value;

      const newLocation = {
        locationId: location.locationId,
        locationName: location.locationName
      };

      this.locationsSubject.next({
        ...currentValue,
        locations: [...currentValue.locations || [], newLocation]
      });

      // Return the new location so we can select it in the component
      return newLocation;
    });
  }

  deleteProjectLocation(projectId: string, locationId: string) {
    const currentValue = this.locationsSubject.getValue();

    this.projectControllerService.deleteProjectLocation(projectId, locationId).subscribe(() => {
      this.locationsSubject.next({
        ...currentValue,
        locations: currentValue?.locations?.filter((location) => location.locationId !== locationId) ?? []
      });
    });
  }

  addProjectMotif(projectId: string, name: string, locationName: string) {
    this.projectControllerService.createProjectMotif(projectId, {
      motifName: name,
      locationName: locationName
    }).subscribe((motif: ProjectMotif) => {
      const currentValue: ProjectLocationsAndMotifsFormValuesDto = this.locationsSubject.value;

      this.locationsSubject.next({
        ...currentValue,
        motifs: [...currentValue.motifs || [], {
          motifId: motif.motifId,
          motifName: motif.motifName
        }]
      })

    });
  }

  deleteProjectMotif(projectId: string, motifId: string) {
    const currentValue = this.locationsSubject.value;

    this.projectControllerService.deleteProjectMotif(projectId, motifId).subscribe(() => {
      this.locationsSubject.next({
        ...currentValue,
        motifs: [...currentValue?.motifs?.filter((motif: ProjectMotifFormValuesDto) => motif.motifId !== motifId) ?? []]
      });
    });
  }

  /* Project Model Production Day events */
  loadProjectModelProductionDayEvents(projectId: string, projectModelId: string, productionDayDate: Date) {
    return firstValueFrom(this.projectControllerService.getProjectModelProductionDayEvents(projectId, projectModelId, toISOStringWithLocalTime(productionDayDate))).then((events) => {
      return events;
    });
  }

  async addAdditionalParticipant(addAdditionalParticipantRequest: AddAdditionalParticipantRequestDto, projectId: string) {
    const currentValue = this.projectFormSubject.value;
    const currentAdditionalParticipantsValue = this.additionalParticipantsSubject.getValue();

    await firstValueFrom(this.projectControllerService.addAdditionalParticipant(projectId, addAdditionalParticipantRequest)).then((res) => {

      this.additionalParticipantsSubject.next([...currentAdditionalParticipantsValue, res]);

      this.projectFormSubject.next({
        ...currentValue,
        bookingProducer: {
          ...currentValue.bookingProducer,
          bookingTargetOptions: currentValue.bookingProducer?.bookingTargetOptions?.map(ele => {
            if (ele.targetPerson === 'Producer') {
              return ele;
            } else {
              return {
                ...ele, addresseeOptions: [...(ele.addresseeOptions ?? []), {
                  addressee: addAdditionalParticipantRequest.employeeFirstName + ' ' + addAdditionalParticipantRequest.employeeLastName,
                  company: addAdditionalParticipantRequest.customerName,
                  phone: addAdditionalParticipantRequest.employeePhone,
                  mobile: addAdditionalParticipantRequest.employeeMobile,
                  email: addAdditionalParticipantRequest.employeeEmail,
                  customerStreet: res.customerStreet,
                  customerZipCode: res.customerZipCode,
                  customerCity: res.customerCity
                }]
              };
            }
          })
        }
      });
    });
  }



  loadProjectRoles(projectId: string) {
    // firstValueFrom(this.languageService.getLanguage$()).then((lang) => {
    //     firstValueFrom(this.projectQueryControllerService.getProjectRoles(lang, projectId)).then((_roles) => {
    //         const currentValue = this.projectFormSubject.getValue();
    //         this.projectFormSubject.next({
    //             ...currentValue,
    //             roles: _roles
    //         })
    //     })
    // });


  }

  async getProjectRoleFormValues(projectId: string, projectRoleId: string) {

    const currentFormValues = this.projectFormSubject.getValue();

    const existingRoleInState = currentFormValues.roles?.roles?.find(
      (r) => r.projectId === projectId && r.projectRoleId === projectRoleId
    );

    if (existingRoleInState) {
      return existingRoleInState;
    }

    const currentLang = await firstValueFrom(this.languageService.getLanguage$());

    return firstValueFrom(
      this.projectControllerService.getProjectRoleFormValues(projectId, projectRoleId, currentLang)
    ).then((result) => {
      const updatedState = {
        ...currentFormValues,
        roles: {
          ...currentFormValues.roles,
          roles: currentFormValues.roles?.roles ? [
            ...currentFormValues.roles.roles,
            result
          ] : [result]
        }
      };
      this.projectFormSubject.next(updatedState);
      return result;
    }).catch(error => {
      console.error('Error fetching project role form values:', error);
      throw error;
    });
  }


  getProjectAsSearchItem(projectId: string): Promise<ProjectSearchItemDto> {
    return firstValueFrom(this.projectControllerService.getProjectAsSearchItem(projectId));
  }

  fetchProjectRoles(projectId: string) {
    firstValueFrom(this.projectControllerService.getProjectRoleAsSearchItemList(projectId)).then((roles) => {
      this.projectRolesSubject.next(roles);
    });
  }

  getProjectRoles(): Observable<ProjectRoleSearchItemsDto> {
    return this.projectRoles$;
  }

  fetchModelsForDetailsCasting(projectId: string) {
    firstValueFrom(this.projectControllerService.getModelsForCasting(projectId)).then((models) => {
      this.detailsCastingSubject.next(models);
    });
  } 

  getModelsForDetailsCasting(): Observable<DetailsCastingFormValuesDtoList> {
    return this.detailsCasting$;
  }


  fetchModelsOfRole(projectId: string, roleId: string) {
    this.resetProjectModelsByRole();
    const value = this.projectModelsByProjectAndRoleSubject.value;
    // if (value[roleId]) {
    //   this.projectModelsByRoleSubject.next({ projectModelFormValuesDto: value[roleId].projectModelFormValuesDto, requestDates: value[roleId].requestDates });
    // } else {
    //   this._fetchModelsOfRole(projectId, roleId);
    // }
    this._fetchModelsOfRole(projectId, roleId);
  }

  fetchBookedModels(projectId: string) {
    this.languageService.getLanguage$().subscribe((language: string) => {
      firstValueFrom(this.projectControllerService.getBookedModelsByProjectId(projectId, language)).then((res) => this.bookedModelByProjectSubject.next(res));
    });

  }

  resetModelsOfRole() {
    this.projectModelsByRoleSubject.next({ projectModelFormValuesDto: [], requestDates: [] });
  }

  private _fetchModelsOfRole(projectId: string, roleId: string) {
    this.languageService.getLanguage$().subscribe((language) => {

      firstValueFrom(this.projectControllerService.getProjectModelsByProjectIdAndRoleId(language, projectId, roleId)).then((res: ProjectModelListFormValuesDto) => {
        const projectModels = res.projectModelFormValuesDtoList || [];
        const requestDates = res.requestDates || [];
        const currentValue: {
          [key: string]: { projectModelFormValuesDto: ProjectModelFormValuesDto[], requestDates: string[] }
        } = this.projectModelsByProjectAndRoleSubject.value;

        this.projectModelsByProjectAndRoleSubject.next({
          ...currentValue,
          [roleId]: { projectModelFormValuesDto: projectModels, requestDates: requestDates }
        });
        this.projectModelsByRoleSubject.next({
          projectModelFormValuesDto: projectModels,
          requestDates: requestDates
        });
      });

      // const currentValue: {
      //   [key: string]: { projectModelFormValuesDto: ProjectModelFormValuesDto[], requestDates: string[] }
      // } = this.projectModelsByProjectAndRoleSubject.value;

      // if (!currentValue[roleId] || this.currentLanguage !== language) {
      //   this.projectControllerApi.getProjectModelsByProjectIdAndRoleId({
      //     lang: this.currentLanguage,
      //     projectId,
      //     roleId
      //   }).then((res: ProjectModelListFormValuesDto) => {
      //     const projectModels = res.projectModelFormValuesDtoList || [];
      //     const requestDates = res.requestDates || [];
      //     const currentValue: {
      //       [key: string]: { projectModelFormValuesDto: ProjectModelFormValuesDto[], requestDates: string[] }
      //     } = this.projectModelsByProjectAndRoleSubject.value;

      //     this.projectModelsByProjectAndRoleSubject.next({
      //       ...currentValue,
      //       [roleId]: { projectModelFormValuesDto: projectModels, requestDates: requestDates }
      //     });
      //     this.projectModelsByRoleSubject.next({
      //       projectModelFormValuesDto: projectModels,
      //       requestDates: requestDates
      //     });
      //   });
      // }
    });

  }

  getModelsOfRole() {
    return this.projectModelsByRole$;
  }

  getBookedModels() {
    return this.bookedModelByProject$;
  }

  async changeProjectModel(projectId: string, roleId: string, model: ProjectModelFormValuesDto) {
    if (model.modelId) {
      this.store.dispatch(new ProjectEditModelActions.ChangeProjectModel(projectId, roleId, model.modelId, {
        buyoutType: model.buyoutType === '-' ? undefined : model.buyoutType,
        buyoutValue: model.buyoutValue,
        fee: model.fee,
        note: model.note, 
        externalNote: model.externalNote,
        status: model.status,
        dailyRate: model.dailyRate
      }));

      // const currentProjectModelsByProjectAndRoleSubject = this.projectModelsByProjectAndRoleSubject.value;
      // const currentProjectModelsByRoleSubject = [...this.projectModelsByRoleSubject.value?.projectModelFormValuesDto || []];
      // const updatedProjectModels = currentProjectModelsByRoleSubject.map((projectModel) => {
      //   if (projectModel.modelId === modelResult.modelId) {
      //     projectModel.buyoutType = modelResult.buyoutType;
      //     projectModel.buyoutValue = modelResult.buyoutValue;
      //     projectModel.fee = modelResult.fee;
      //     projectModel.note = modelResult.note;
      //     projectModel.externalNote = modelResult.externalNote;
      //     projectModel.status = modelResult.status;
      //     projectModel.dailyRate = modelResult.dailyRate;
      //   }
      //   return projectModel;
      // });
      // const currentValue = this.projectModelsByRoleSubject.value;
      // this.projectModelsByRoleSubject.next({ ...currentValue, projectModelFormValuesDto: updatedProjectModels, requestDates: currentValue?.requestDates || [] });
      // this.projectModelsByProjectAndRoleSubject.next({
      //   ...currentProjectModelsByProjectAndRoleSubject,
      //   [roleId]: { projectModelFormValuesDto: [...updatedProjectModels], requestDates: currentProjectModelsByProjectAndRoleSubject[roleId]?.requestDates || [] }
      // });
    }
  }

  async addProjectRole(projectId: string, name: string): Promise<ProjectRole> {
    const role = await firstValueFrom(this.projectControllerService.addRole(projectId, {
        name: name
      }
    ));

    // Fetch the complete role form values
    const currentLang = await firstValueFrom(this.languageService.getLanguage$());
    const roleFormValues = await firstValueFrom(
      this.projectControllerService.getProjectRoleFormValues(projectId, role.projectRoleId, currentLang)
    );

    // Update state with complete role form values
    const currentValue = this.projectFormSubject.value;
    this.projectFormSubject.next({
      ...currentValue,
      roles: {
        ...currentValue.roles,
        roles: currentValue.roles?.roles ? [...currentValue.roles.roles, roleFormValues] : [roleFormValues]
      }
    });

    return role;
  }

  async deleteProjectRole(projectId: string, roleId: string) {
    try {
      // Wait for API call to complete
      await firstValueFrom(this.projectControllerService.deleteProjectRole(projectId, roleId));

      const currentValue = this.projectFormSubject.value;

      // Update subject only after successful API call
      this.projectFormSubject.next({
        ...currentValue,
        roles: {
          ...currentValue.roles,
          roles: [...(currentValue.roles && currentValue.roles.roles ? currentValue.roles.roles.filter((role: ProjectRoleFormValuesDto) => role.projectRoleId !== roleId) : [])]
        }
      });

      // Also reset the models cache for this role
      this.resetProjectModelsByRole(roleId);

    } catch (error) {
      // Handle error if needed
      throw error;
    }
  }


  async addModelToProjectRole(projectId: string, roleId: string, modelId: string): Promise<string[]> {
    const res = await firstValueFrom(this.projectControllerService.addModelToProjectRole(projectId, this.currentLanguage, roleId, modelId));

    const currentValue = this.projectModelsByProjectAndRoleSubject.value;

    // Initialize the role's model list if it doesn't exist
    if (!currentValue[roleId]) {
      currentValue[roleId] = {
        projectModelFormValuesDto: [],
        requestDates: []
      };
    }

    // Append the new model to the existing list
    const updatedModels = [
      ...(currentValue[roleId].projectModelFormValuesDto || []),
      res.projectModel
    ];

    // Update both subjects with the new model appended
    this.projectModelsByProjectAndRoleSubject.next({
      ...currentValue,
      [roleId]: {
        projectModelFormValuesDto: updatedModels,
        requestDates: currentValue[roleId].requestDates || []
      }
    });

    this.projectModelsByRoleSubject.next({
      projectModelFormValuesDto: updatedModels,
      requestDates: currentValue[roleId].requestDates || []
    });

    return res.modelIds;
  }

  async deleteProjectModel(projectId: string, roleId: string, modelId: string): Promise<UseCaseResult> {
    const res = await firstValueFrom(this.projectControllerService.deleteModelFromProjectRole(projectId, modelId, roleId));

    if (res.result === 'SUCCESS') {
      const currentProjectModelsByProjectAndRoleSubjectValue: {
        [key: string]: { projectModelFormValuesDto: ProjectModelFormValuesDto[], requestDates: string[] }
      } = this.projectModelsByProjectAndRoleSubject.value;

      const updatedModels = currentProjectModelsByProjectAndRoleSubjectValue[roleId].projectModelFormValuesDto.filter(model => model.modelId !== modelId);

      this.projectModelsByProjectAndRoleSubject.next({
        ...currentProjectModelsByProjectAndRoleSubjectValue,
        [roleId]: {
          projectModelFormValuesDto: [...updatedModels],
          requestDates: currentProjectModelsByProjectAndRoleSubjectValue[roleId]?.requestDates || []
        }
      });

      this.projectModelsByRoleSubject.next({
        projectModelFormValuesDto: [...updatedModels],
        requestDates: currentProjectModelsByProjectAndRoleSubjectValue[roleId]?.requestDates || []
      });
    }

    return res;
  }

  async changeRole(projectRoleFormValuesDto: ProjectRoleFormValuesDto): Promise<ProjectRoleFormValuesDto> {
    const currentValue = this.projectFormSubject.value;

    return firstValueFrom(this.languageService.getLanguage$()).then(async (currentLanguage) => {

      const updatedRole = await firstValueFrom(this.projectControllerService.updateRole(currentLanguage, projectRoleFormValuesDto));

      const updatedRoles = currentValue.roles?.roles!.map((existingRole: ProjectRoleFormValuesDto) => {
        if (existingRole.projectRoleId === updatedRole.projectRoleId) {
          return updatedRole;
        }
        return existingRole;
      });

      this.projectFormSubject.next({
        ...currentValue,
        roles: {
          ...currentValue.roles,
          roles: updatedRoles
        }
      });

      return updatedRole;
    });
  }


  async changeProjectMotif(projectMotifFormValuesDto: ProjectMotifFormValuesDto, projectId: string): Promise<ProjectMotifFormValuesDto> {
    if (projectMotifFormValuesDto.motifId === null) {
      return projectMotifFormValuesDto;
    }

    const currentValue = this.projectFormSubject.value;
    const locationCurrentValue: ProjectLocationsAndMotifsFormValuesDto = this.locationsSubject.value;

    const updatedMotif = await firstValueFrom(this.projectControllerService.changeMotif(projectId, projectMotifFormValuesDto));

    // Helper function to update locations with motifs
    const updateLocationsWithMotif = (locations: ProjectLocationFormValuesDto[] = []) => {
      return locations.map(location => {
        // If the motif has a location and it matches this location
        if (updatedMotif.motifLocation === location.locationName) {
          const existingMotifs = location.motifs || [];
          const motifExists = existingMotifs.some(m => m.motifId === updatedMotif.motifId);

          return {
            ...location,
            motifs: motifExists
              ? existingMotifs.map(motif => motif.motifId === updatedMotif.motifId ? updatedMotif : motif)
              : [...existingMotifs, updatedMotif]
          };
        }

        // If the motif was previously in this location but has been moved
        return {
          ...location,
          motifs: (location.motifs || []).filter(motif => motif.motifId !== updatedMotif.motifId)
        };
      });
    };

    // Update projectFormSubject
    this.projectFormSubject.next({
      ...currentValue,
      projectLocations: {
        ...currentValue.projectLocations,
        locations: updateLocationsWithMotif(currentValue.projectLocations?.locations),
        motifs: currentValue.projectLocations?.motifs?.map(motif =>
          motif.motifId === updatedMotif.motifId ? updatedMotif : motif
        ) || []
      }
    });

    // Update locationsSubject
    const newValue = {
      ...locationCurrentValue,
      locations: updateLocationsWithMotif(locationCurrentValue.locations),
      motifs: (locationCurrentValue.motifs || []).map((mo: ProjectMotifFormValuesDto) => {
        if (mo.motifId === updatedMotif.motifId) {
          return updatedMotif;
        }
        return mo;
      })
    };

    this.locationsSubject.next(newValue);

    return updatedMotif;
  }

  changeUsageType(projectId: string, changeUsageTypeRequestDto: ChangeUsageTypeRequestDto) {
    const currentValue = this.projectFormSubject.value;
    firstValueFrom(this.projectControllerService.changeUsageType(projectId, changeUsageTypeRequestDto)).then(() => {
      this.projectFormSubject.next({
        ...currentValue,
        usageType: {
          ...currentValue.usageType,
          selectedUsageType: {
            selectedUsageType: changeUsageTypeRequestDto.selectedUsageType?.selectedUsageType,
            selectedUsageSubType: changeUsageTypeRequestDto.selectedUsageType?.selectedUsageSubType
          }
        }
      });
    });
  }

  changeExecutorName(projectId: string, executorFormValuesDto: ExecutorFormValuesDto) {
    const currentValue = this.projectFormSubject.value;
    firstValueFrom(this.projectControllerService.changeExecutor(projectId, executorFormValuesDto)).then(() => {
      this.projectFormSubject.next({
        ...currentValue,
        executor: {
          ...currentValue.executor,
          name: executorFormValuesDto.name,
          profession: executorFormValuesDto.profession
        }
      });
    });
  }

  changeExecutorProfession(projectId: string, executorFormValuesDto: ExecutorFormValuesDto, changeUsageTypeRequestDto: ChangeUsageTypeRequestDto) {
    const currentValue = this.projectFormSubject.value;
    firstValueFrom(this.projectControllerService.changeExecutor(projectId, executorFormValuesDto)).then(() => {
      this.projectFormSubject.next({
        ...currentValue,
        usageType: {
          ...currentValue.usageType,
          selectedUsageType: {
            selectedUsageType: changeUsageTypeRequestDto.selectedUsageType?.selectedUsageType,
            selectedUsageSubType: changeUsageTypeRequestDto.selectedUsageType?.selectedUsageSubType
          }
        },
        executor: {
          ...currentValue.executor,
          name: executorFormValuesDto.name,
          profession: executorFormValuesDto.profession
        }
      });
    });
  }

  changeBuyOut(projectId: string, projectBuyOutFromValuesDto: ProjectBuyOutFromValuesDto) {
    const currentValue = this.projectFormSubject.value;
    firstValueFrom(this.projectControllerService.changeBuyOut(projectId, projectBuyOutFromValuesDto)).then(() => {
      this.projectFormSubject.next({
        ...currentValue,
        buyOut: projectBuyOutFromValuesDto.buyOut
      });
    });
  }

  resetBuyOut(projectId: string, projectBuyOutFromValuesDto: ProjectBuyOutFromValuesDto, changeUsageTypeRequestDto: ChangeUsageTypeRequestDto) {
    const currentValue = this.projectFormSubject.value;
    firstValueFrom(this.projectControllerService.changeBuyOut(projectId, projectBuyOutFromValuesDto)).then(() => {
      this.projectFormSubject.next({
        ...currentValue,
        usageType: {
          ...currentValue.usageType,
          selectedUsageType: {
            selectedUsageType: changeUsageTypeRequestDto.selectedUsageType?.selectedUsageType,
            selectedUsageSubType: changeUsageTypeRequestDto.selectedUsageType?.selectedUsageSubType
          }
        },
        buyOut: projectBuyOutFromValuesDto.buyOut
      });
    });
  }

  changeBudget(projectId: string, budgetFormValuesDto: BudgetFormValuesDto) {
    const currentValue = this.projectFormSubject.value;
    firstValueFrom(this.projectControllerService.changeBudget(projectId, budgetFormValuesDto)).then(() => {
      this.projectFormSubject.next({
        ...currentValue,
        budget: {
          ...currentValue.budget,
          total: budgetFormValuesDto.total,
          inclProvision: budgetFormValuesDto.inclProvision,
          description: budgetFormValuesDto.description
        }
      });
    });
  }

  changeProjectStatus(projectId: string, projectStatusFormValuesDto: ProjectStatusFormValuesDto) {
    const currentValue = this.projectFormSubject.value;
    firstValueFrom(this.projectControllerService.changeProjectStatus(projectId, projectStatusFormValuesDto)).then(() => {
      this.projectFormSubject.next({
        ...currentValue,
        status: {
          status: projectStatusFormValuesDto.status,
          received: projectStatusFormValuesDto.received,
          projectStatusOptions: currentValue.status?.projectStatusOptions
        }
      });
    });
  }


  async generateDemoPdf(projectId: string, data: DemoPdfRequestDtoData) {

    const currentValue = this.projectFormSubject.value;
    const fileName = 'demo-' + projectId;
    const dto: GenerateDocumentDemoRequest = {
      demoPdfRequestDto: {
        data: data,
        fileName: fileName
      }
    };
    const options: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(dto.demoPdfRequestDto)
    };

    const response = await this.projectControllerApi2.generateDocumentDemoRaw({
      demoPdfRequestDto: {
        data: data,
        fileName: fileName
      }
    });
    const blob = await response.raw.blob();
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    this.projectFormSubject.next({
      ...currentValue
    });
    return blob;
  }

  changeFinalCustomer(projectId: string, finalCustomerFormValuesDto: FinalCustomerFormValuesDto) {
    const currentValue = this.projectFormSubject.value;
    firstValueFrom(this.projectControllerService.changeFinalCustomer(projectId, finalCustomerFormValuesDto)).then(() => {
      this.projectFormSubject.next({
        ...currentValue,
        finalCustomer: {
          ...currentValue.finalCustomer,
          ...finalCustomerFormValuesDto
        }
      });
    });
  }

  changeProtocol(protocolEntryCreateRequestDto: ProtocolEntryCreateRequestDto) {
    const currentFormValue = this.projectFormSubject.getValue();

    firstValueFrom(this.projectControllerService.createProtocolEntry(protocolEntryCreateRequestDto)).then((protocol) => {

      const updatedProtocol = currentFormValue.protocol
        ? [...(currentFormValue.protocol.protocols || []), protocol]
        : [protocol];

      this.projectFormSubject.next({
        ...currentFormValue,
        protocol: {
          protocols: updatedProtocol
        }
      });
    });
  }

  changeProjectName(projectId: string, projectNameFormValuesDto: ProjectNameFormValuesDto) {
    const currentFormValue = this.projectFormSubject.getValue();
    firstValueFrom(this.projectControllerService.changeProjectName(projectId, projectNameFormValuesDto)).then(() => {
      this.projectFormSubject.next({
        ...currentFormValue,
        name: {
          ...currentFormValue.name,
          title: projectNameFormValuesDto.title,
          workingTitle: projectNameFormValuesDto.workingTitle,
          projectNumber: projectNameFormValuesDto.projectNumber,
          description: projectNameFormValuesDto.description
        }
      });
    });
  }

  changeProductionDetails(projectId: string, productionDetailsFormValuesDto: ProductionDetailsFormValuesDto) {
    const currentFormValue = this.projectFormSubject.getValue();
    firstValueFrom(this.projectControllerService.changeProductionDetails(projectId, productionDetailsFormValuesDto)).then(() => {
      this.projectFormSubject.next({
        ...currentFormValue,
        productionDetails: {
          ...currentFormValue.productionDetails,
          startDate: productionDetailsFormValuesDto.startDate,
          endDate: productionDetailsFormValuesDto.endDate,
          timeWindow: productionDetailsFormValuesDto.timeWindow,
          productionContact: productionDetailsFormValuesDto.productionContact,
          mobile: productionDetailsFormValuesDto.mobile,
          phone: productionDetailsFormValuesDto.phone
        }
      });
    });

  }

  async changeProjectProducer(projectId: string, projectProducerFormValueDto: ProjectProducerFormValueDto): Promise<void> {
    const currentFormValue = this.projectFormSubject.getValue();

    firstValueFrom(this.projectControllerService.changeProjectProducer(projectId, projectProducerFormValueDto)).then(() => {

    this.projectFormSubject.next({
      ...currentFormValue,
      producer: {
        ...currentFormValue.producer,
        customerId: projectProducerFormValueDto.customerId,
        customerName: projectProducerFormValueDto.customerName,
        note: projectProducerFormValueDto.note,
          selectedEmployee: projectProducerFormValueDto.selectedEmployee
        }
      });
    });
  }

  changeRoleDetails(
    projectId: string,
    changeRoleDetailsRequestDto: ChangeRoleDetailsRequestDto
  ) {
    firstValueFrom(this.projectControllerService.changeRoleDetails(projectId, changeRoleDetailsRequestDto)).then(() => {
      const currentFormValue = this.projectModelsByRoleSubject.getValue();

        this.projectModelsByRoleSubject.next({
          ...currentFormValue,
          requestDates: [...currentFormValue?.requestDates || []],
          projectModelFormValuesDto: currentFormValue?.projectModelFormValuesDto.map((projectModel) => {
            if (projectModel.modelId === changeRoleDetailsRequestDto.modelId) {
              projectModel.roleDetailsFormValuesDto = {
                ...projectModel.roleDetailsFormValuesDto,
                ...changeRoleDetailsRequestDto
              };
              projectModel.modelProductionDetailsFormValuesDto = {
                ...projectModel.modelProductionDetailsFormValuesDto,
                events: projectModel.modelProductionDetailsFormValuesDto?.events || [],
                infoAdditional: changeRoleDetailsRequestDto.additionalInfo,
                infoArrival: changeRoleDetailsRequestDto.arrivalInfo,
                infoSleepOver: changeRoleDetailsRequestDto.accommodationInfo
              };
            }

            return projectModel;
          }) || []
        });
    });
  }

  changeBookingProducer(projectId: string, bookingProducerFormValuesDto: BookingProducerFormValuesDto) {
    const currentFormValue = this.projectFormSubject.getValue();
    firstValueFrom(this.projectControllerService.changeBookingProducer(projectId, bookingProducerFormValuesDto)).then(() => {
      this.projectFormSubject.next({
        ...currentFormValue,
        bookingProducer: {
          ...currentFormValue.bookingProducer,
          ...bookingProducerFormValuesDto,
          selectedTarget: {
            selectedTarget: bookingProducerFormValuesDto.selectedTarget?.selectedTarget,
            selectedAddressee: bookingProducerFormValuesDto.selectedTarget?.selectedAddressee
          }
        }
      });
    });
  }

  changeExtraCosts(projectId: string, extraCostsListFormValuesDto: ExtraCostsListFormValuesDto) {
    const currentFormValue = this.projectFormSubject.getValue();
    firstValueFrom(this.projectControllerService.changeExtraCosts(projectId, extraCostsListFormValuesDto)).then(() => {
      this.projectFormSubject.next({
          ...currentFormValue,
          billing: {
            ...currentFormValue.billing,
            extraCosts: {
              ...currentFormValue.billing?.extraCosts,
              extraCosts: extraCostsListFormValuesDto.extraCosts
            }
          }
        });
        this.loadProjectFormValues(projectId);
      });

  }

  changeInvoiceAddressee(projectId: string, projectBillingAddresseeFormValuesDto: ProjectBillingAddresseeFormValuesDto) {
    const currentFormValue = this.projectFormSubject.getValue();
    firstValueFrom(this.projectControllerService.changeBillingAddressee(projectId, projectBillingAddresseeFormValuesDto)).then(() => {
      this.projectFormSubject.next({
          ...currentFormValue,
          billing: {
            ...currentFormValue.billing,
            addressee: {
              ...currentFormValue.billing?.addressee,
              target: projectBillingAddresseeFormValuesDto.target,
              addressee: projectBillingAddresseeFormValuesDto.addressee,
              note: projectBillingAddresseeFormValuesDto.note
            }
          }
        });
      });
  }

  changeBookingModel(projectId: string, changeModelBookingInformationRequestDto: ChangeModelBookingInformationRequestDto) {
    const currentFormValue = this.bookedModelByProjectSubject.value;
    firstValueFrom(this.projectControllerService.changeBookingModels(projectId, changeModelBookingInformationRequestDto)).then(() => {
      this.bookedModelByProjectSubject.next({
        projectModelFormValuesDtoList: currentFormValue.projectModelFormValuesDtoList?.map(
          ele => ele.roleId === changeModelBookingInformationRequestDto.roleId
            && ele.modelId === changeModelBookingInformationRequestDto.modelId ?
            {
              ...ele,
              bookingModelFormValuesDto: {
                travelCosts: changeModelBookingInformationRequestDto.travelCosts,
                bookingStatus: changeModelBookingInformationRequestDto.bookingStatus
              }

            } : ele
        )
      });
    });
  }

  changeProjectModelTravelCosts(projectId: string, modelId: string, roleId: string, travelCostListFormValuesDto: TravelCostListFormValuesDto) {
    const currentFormValue = this.bookedModelByProjectSubject.value;
    firstValueFrom(this.projectControllerService.changeProjectModelTravelCosts(projectId, modelId, roleId, travelCostListFormValuesDto)).then(() => {
        this.bookedModelByProjectSubject.next({
          projectModelFormValuesDtoList: currentFormValue.projectModelFormValuesDtoList?.map(
            ele => ele.roleId === roleId
              && ele.modelId === modelId ?
              {
                ...ele,
                travelCostFormValuesDto: {
                  ...ele.travelCostFormValuesDto,
                  travelCosts: travelCostListFormValuesDto.travelCosts
                }
              } : ele
          )
        });
        this.loadProjectFormValues(projectId);
      });
  }

  changeProjectInvoice(projectId: string, projectBillingInvoiceFormValuesDto: ProjectBillingInvoiceFormValuesDto) {
    const currentFormValue = this.projectFormSubject.value;
    firstValueFrom(this.projectControllerService.changeProjectInvoice(projectId, projectBillingInvoiceFormValuesDto)).then(() => {
      this.projectFormSubject.next(
          {
            ...currentFormValue,
            billing: {
              ...currentFormValue.billing,
              invoice: {
                ...currentFormValue.billing?.invoice,
                invoiceStatus: projectBillingInvoiceFormValuesDto.invoiceStatus,
                dueDate: projectBillingInvoiceFormValuesDto.dueDate,
                paymentReceived: projectBillingInvoiceFormValuesDto.paymentReceived,
                ifPaymentReceived: projectBillingInvoiceFormValuesDto.ifPaymentReceived
              }
            }
          });
      });
  }

  changeProjectBillingSum(projectId: string, projectBillingSumFormValuesDto: ProjectBillingSumFormValuesDto) {
    const currentFormValue = this.projectFormSubject.value;
    firstValueFrom(this.projectControllerService.changeProjectBillingSum(projectId, projectBillingSumFormValuesDto)).then(() => {
      this.projectFormSubject.next(
          {
            ...currentFormValue,
            billing: {
              ...currentFormValue.billing,
              totalSum: {
                ...currentFormValue.billing?.totalSum,
                agentFeePercent: projectBillingSumFormValuesDto.agentFeePercent,
                serviceFeePercent: projectBillingSumFormValuesDto.serviceFeePercent
              }
            }
          });
        this.loadProjectFormValues(projectId);
      });
  }

  changeProjectModelBilling(projectId: string, modelId: string, roleId: string, projectModelBillingFormValuesDto: ProjectModelBillingFormValuesDto) {
    const currentFormValue = this.bookedModelByProjectSubject.value;
    firstValueFrom(this.projectControllerService.changeProjectModelBilling(projectId, modelId, roleId, projectModelBillingFormValuesDto)).then(() => {
      this.bookedModelByProjectSubject.next(
          {
            projectModelFormValuesDtoList: currentFormValue.projectModelFormValuesDtoList?.map(
              ele =>
                ele.roleId === roleId && ele.modelId === modelId ? {
                  ...ele,
                  projectModelBillingFormValuesDto: {
                    ...ele.projectModelBillingFormValuesDto,
                    ...projectModelBillingFormValuesDto,
                    periodOptions: ele.projectModelBillingFormValuesDto?.periodOptions
                  }
                } : ele
            )
          });
        this.loadProjectFormValues(projectId);
      });

  }


  fetchProjectModels(projectId: string) {
    firstValueFrom(this.projectControllerService.getProjectModelsByProjectId(projectId)).then((response) => {
      this.projectModelSubject.next({
        ...response
      });
    });
    return this.projectModel$;
  }

  fetchBuyOutPerModelFormValuesDto(projectId: string) {
    firstValueFrom(this.projectControllerService.getBuyOutPerModelByProjectId(projectId)).then((response) => {
      this.buyOutPerModelSubject.next({
        ...response
      });
    });
    return this.buyOutPerModel$;
  }

  async changeBuyOutPerModel(projectId: string, buyOutPerModelFormValuesDto: BuyOutPerModelFormValuesDto) {
    const currentValue = this.buyOutPerModelSubject.getValue();
    const roleId = buyOutPerModelFormValuesDto.roleId;
    if (!roleId) {
      return;
    }
    currentValue.buyOutPerModelFormValuesDtoList ??= [];

    const index = currentValue.buyOutPerModelFormValuesDtoList
      .findIndex(item => item.modelId === buyOutPerModelFormValuesDto.modelId);

    if (index !== -1) {
      currentValue.buyOutPerModelFormValuesDtoList[index] = buyOutPerModelFormValuesDto;
    } else {
      currentValue.buyOutPerModelFormValuesDtoList.push(buyOutPerModelFormValuesDto);
    }

    firstValueFrom(this.projectControllerService.changeBuyOutPerModel(projectId, roleId, buyOutPerModelFormValuesDto)).then(() => {
      this.buyOutPerModelSubject.next(currentValue);
    });
  }

  deleteBuyOutPerModel(projectId: string, modelId: string, roleId: string) {
    const currentValue = this.buyOutPerModelSubject.getValue();
    firstValueFrom(this.projectControllerService.deleteBuyOutPerModel(projectId, modelId, roleId)).then(() => {
      this.buyOutPerModelSubject.next({
        ...currentValue,
        buyOutPerModelFormValuesDtoList: currentValue.buyOutPerModelFormValuesDtoList?.filter(item => {
          return !(item.modelId == modelId && item.roleId == roleId);
        }
        ) || []
      });
    });
  }


  addProductionDays(
    projectId: string,
    projectModelId: string,
    events: AddProductionDetailsEventDto[]
  ) {
    return firstValueFrom(this.projectControllerService.addProductionDaysToProjectModel(
      projectId,
      projectModelId,
      {
        events
      }
    ));
  }

  deleteProductionDayEvent(
    projectId: string,
    projectModelId: string,
    eventId: string
  ) {
    return firstValueFrom(this.projectControllerService.deleteProductionDayEventFromProjectModel(projectId, projectModelId, { eventId: eventId }));
  }

  loadModelProductionDayEvents(
    projectId: string,
    projectModelId: string,
    from: Date,
    to: Date
  ) {

    firstValueFrom(this.projectControllerService.getProductionDaysForProjectModel(projectId, projectModelId, {
      from: from.toISOString(),
      to: to.toISOString()
    }));
  }

  async onAddNewBuyOutPerModel(projectId: string, addNewBuyOutPerModelRequest: AddNewBuyOutPerModelRequest) {
    const currentValue = this.buyOutPerModelSubject.getValue();
    firstValueFrom(this.projectControllerService.addBuyOutPerModel(projectId, addNewBuyOutPerModelRequest)).then((response) => {
      this.buyOutPerModelSubject.next({
        ...currentValue,
        buyOutPerModelFormValuesDtoList: [
          ...currentValue.buyOutPerModelFormValuesDtoList || [], response
        ]
      });
    });
  }

  async getBookingProducerConfirmationPreview(projectId: string): Promise<void> {
    const language = this.languageService.getLanguage();
    const filename_result = await firstValueFrom(this.projectControllerService.generatePdfFilenameBookingProducer(projectId, true ));
    const response = await this.projectControllerApi2.getBookingProducerConfirmationPreviewRaw({ projectId, language });
    await response.raw.blob().then(
      ele => this.openPdfInNewWindow(ele, filename_result.filename)
    );
  }

  async downloadBookingProducerPdf(projectId: string): Promise<void> {
    const language = this.languageService.getLanguage();
    const filename_result = await firstValueFrom(this.projectControllerService.generatePdfFilenameBookingProducer(projectId, false ));
    const response = await this.projectControllerApi2.getBookingProducerConfirmationPreviewRaw({ projectId, language });
    await response.raw.blob().then(
      ele => this.openPdfInNewWindow(ele, filename_result.filename)
    );
  }

  async getBookingProducerConfirmationPreviewWithBuyout(projectId: string): Promise<void> {
    const language = this.languageService.getLanguage();
    const filename_result = await firstValueFrom(this.projectControllerService.generatePdfFilenameBookingProducer(projectId, true ));
    const response = await this.projectControllerApi2.getBookingProducerConfirmationWithBuyoutsPreviewRaw({ projectId, language });
    await response.raw.blob().then(
      ele => this.openPdfInNewWindow(ele, filename_result.filename)
    );
  }

  async getBookingModelConfirmationPreview(projectId: string, roleId: string, modelId: string): Promise<void> {
    const language = this.languageService.getLanguage();
    const filename_result = await firstValueFrom(this.projectControllerService.generatePdfFilenameBookingModel(projectId, modelId, true ));
    const response = await this.projectControllerApi2.getBookingModelConfirmationPreviewRaw({
      projectId,
      modelId,
      roleId,
      language
    });
    await response.raw.blob().then(
      ele => this.openPdfInNewWindow(ele, filename_result.filename)
    );
  }

  async downloadBookingModelPdf(projectId: string, roleId: string, modelId: string) {
    const language = this.languageService.getLanguage();
    const filename_result = await firstValueFrom(this.projectControllerService.generatePdfFilenameBookingModel(projectId, modelId, false ));
    const response = await this.projectControllerApi2.getBookingModelConfirmationPreviewRaw({ projectId, modelId, roleId, language });
    await response.raw.blob().then(
      ele => this.openPdfInNewWindow(ele, filename_result.filename)
    );
  }

  async getCurrentModelSelection(projectId: string): Promise<void> {
    const language = this.languageService.getLanguage();
    const response = await this.projectControllerApi2.getCurrentModelSelectionRaw({ projectId, language });
    await response.raw.blob().then(
      ele => {
        this.openPdfInNewWindow(ele);
      }
    );
  }

  async getProjectBill(projectId: string): Promise<void> {
    const language = this.languageService.getLanguage();
    const response = await this.projectControllerApi2.getBillPreviewRaw({ projectId, language });
    await response.raw.blob().then(
      ele => this.openPdfInNewWindow(ele)
    );
  }

  openPdfInNewWindow(blob: Blob, filename?: string): void {
    const blob2 = new Blob([blob], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob2);
    link.download = filename || 'FileName_plh_.pdf';
    window.open(URL.createObjectURL(blob2));
    link.click();
  }


  reset() {
    this.selectedProjectUuidSubject.next(null);
    this.projectFormSubject.next(this.initialProjectFormValuesState);
  }

  getInitialProjectFormValuesState() {
    return this.initialProjectFormValuesState;
  }

  resetProjectModelsByRole(roleId?: string) {
    if (roleId) {
      const currentValue = this.projectModelsByProjectAndRoleSubject.value;
      const newValue = { ...currentValue };
      delete newValue[roleId];
      this.projectModelsByProjectAndRoleSubject.next(newValue);
      this.projectModelsByRoleSubject.next(null);
    } else {
      this.projectModelsByProjectAndRoleSubject.next({});
      this.projectModelsByRoleSubject.next(null);
    }
    this.modelsByRoleResetSubject.next();
  }

  modelAdded(modelId: string) {
    this.modelAddedSubject.next(modelId);
  }
}
