/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';
import type { DanceFormValuesDto } from './DanceFormValuesDto';
import {
    DanceFormValuesDtoFromJSON,
    DanceFormValuesDtoFromJSONTyped,
    DanceFormValuesDtoToJSON,
} from './DanceFormValuesDto';

/**
 * 
 * @export
 * @interface DancesFormValuesDto
 */
export interface DancesFormValuesDto {
    /**
     * 
     * @type {Array<DanceFormValuesDto>}
     * @memberof DancesFormValuesDto
     */
    dances?: Array<DanceFormValuesDto>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof DancesFormValuesDto
     */
    danceOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof DancesFormValuesDto
     */
    danceLevelOptions?: Array<AdminValue>;
}

/**
 * Check if a given object implements the DancesFormValuesDto interface.
 */
export function instanceOfDancesFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DancesFormValuesDtoFromJSON(json: any): DancesFormValuesDto {
    return DancesFormValuesDtoFromJSONTyped(json, false);
}

export function DancesFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DancesFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dances': !exists(json, 'dances') ? undefined : ((json['dances'] as Array<any>).map(DanceFormValuesDtoFromJSON)),
        'danceOptions': !exists(json, 'danceOptions') ? undefined : ((json['danceOptions'] as Array<any>).map(AdminValueFromJSON)),
        'danceLevelOptions': !exists(json, 'danceLevelOptions') ? undefined : ((json['danceLevelOptions'] as Array<any>).map(AdminValueFromJSON)),
    };
}

export function DancesFormValuesDtoToJSON(value?: DancesFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dances': value.dances === undefined ? undefined : ((value.dances as Array<any>).map(DanceFormValuesDtoToJSON)),
        'danceOptions': value.danceOptions === undefined ? undefined : ((value.danceOptions as Array<any>).map(AdminValueToJSON)),
        'danceLevelOptions': value.danceLevelOptions === undefined ? undefined : ((value.danceLevelOptions as Array<any>).map(AdminValueToJSON)),
    };
}

