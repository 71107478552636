/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TwoLayerFilterDto } from './TwoLayerFilterDto';
import {
    TwoLayerFilterDtoFromJSON,
    TwoLayerFilterDtoFromJSONTyped,
    TwoLayerFilterDtoToJSON,
} from './TwoLayerFilterDto';

/**
 * 
 * @export
 * @interface ModelMasterDataSearchFilterDto
 */
export interface ModelMasterDataSearchFilterDto {
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataSearchFilterDto
     */
    query?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataSearchFilterDto
     */
    sex?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataSearchFilterDto
     */
    skinType?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataSearchFilterDto
     */
    hairLength?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataSearchFilterDto
     */
    hairStyle?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelMasterDataSearchFilterDto
     */
    chestCircumferenceFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelMasterDataSearchFilterDto
     */
    chestCircumferenceTo?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataSearchFilterDto
     */
    braCupFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataSearchFilterDto
     */
    braCupTo?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelMasterDataSearchFilterDto
     */
    waistCircumferenceFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelMasterDataSearchFilterDto
     */
    waistCircumferenceTo?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelMasterDataSearchFilterDto
     */
    hipCircumferenceFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelMasterDataSearchFilterDto
     */
    hipCircumferenceTo?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelMasterDataSearchFilterDto
     */
    heightFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelMasterDataSearchFilterDto
     */
    heightTo?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelMasterDataSearchFilterDto
     */
    eyeColors?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelMasterDataSearchFilterDto
     */
    hairColors?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ModelMasterDataSearchFilterDto
     */
    isPregnant?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ModelMasterDataSearchFilterDto
     */
    ageFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelMasterDataSearchFilterDto
     */
    ageTo?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataSearchFilterDto
     */
    confectionFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataSearchFilterDto
     */
    confectionTo?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataSearchFilterDto
     */
    shoeSizeFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataSearchFilterDto
     */
    shoeSizeTo?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataSearchFilterDto
     */
    shoeSizeUsFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataSearchFilterDto
     */
    shoeSizeUsTo?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataSearchFilterDto
     */
    zipCode?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelMasterDataSearchFilterDto
     */
    modelCategories?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataSearchFilterDto
     */
    nationality?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataSearchFilterDto
     */
    ethnicity?: string;
    /**
     * 
     * @type {Array<TwoLayerFilterDto>}
     * @memberof ModelMasterDataSearchFilterDto
     */
    sports?: Array<TwoLayerFilterDto>;
    /**
     * 
     * @type {Array<TwoLayerFilterDto>}
     * @memberof ModelMasterDataSearchFilterDto
     */
    dances?: Array<TwoLayerFilterDto>;
    /**
     * 
     * @type {Array<TwoLayerFilterDto>}
     * @memberof ModelMasterDataSearchFilterDto
     */
    spokenLanguages?: Array<TwoLayerFilterDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelMasterDataSearchFilterDto
     */
    piercings?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelMasterDataSearchFilterDto
     */
    tattoos?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelMasterDataSearchFilterDto
     */
    specials?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelMasterDataSearchFilterDto
     */
    driverLicenses?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ModelMasterDataSearchFilterDto
     */
    hasEarPiercing?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelMasterDataSearchFilterDto
     */
    hasPiercing?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelMasterDataSearchFilterDto
     */
    hasScar?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelMasterDataSearchFilterDto
     */
    hasTattoo?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataSearchFilterDto
     */
    currentJob?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataSearchFilterDto
     */
    learnedJob?: string;
}

/**
 * Check if a given object implements the ModelMasterDataSearchFilterDto interface.
 */
export function instanceOfModelMasterDataSearchFilterDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ModelMasterDataSearchFilterDtoFromJSON(json: any): ModelMasterDataSearchFilterDto {
    return ModelMasterDataSearchFilterDtoFromJSONTyped(json, false);
}

export function ModelMasterDataSearchFilterDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelMasterDataSearchFilterDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'query': !exists(json, 'query') ? undefined : json['query'],
        'sex': !exists(json, 'sex') ? undefined : json['sex'],
        'skinType': !exists(json, 'skinType') ? undefined : json['skinType'],
        'hairLength': !exists(json, 'hairLength') ? undefined : json['hairLength'],
        'hairStyle': !exists(json, 'hairStyle') ? undefined : json['hairStyle'],
        'chestCircumferenceFrom': !exists(json, 'chestCircumferenceFrom') ? undefined : json['chestCircumferenceFrom'],
        'chestCircumferenceTo': !exists(json, 'chestCircumferenceTo') ? undefined : json['chestCircumferenceTo'],
        'braCupFrom': !exists(json, 'braCupFrom') ? undefined : json['braCupFrom'],
        'braCupTo': !exists(json, 'braCupTo') ? undefined : json['braCupTo'],
        'waistCircumferenceFrom': !exists(json, 'waistCircumferenceFrom') ? undefined : json['waistCircumferenceFrom'],
        'waistCircumferenceTo': !exists(json, 'waistCircumferenceTo') ? undefined : json['waistCircumferenceTo'],
        'hipCircumferenceFrom': !exists(json, 'hipCircumferenceFrom') ? undefined : json['hipCircumferenceFrom'],
        'hipCircumferenceTo': !exists(json, 'hipCircumferenceTo') ? undefined : json['hipCircumferenceTo'],
        'heightFrom': !exists(json, 'heightFrom') ? undefined : json['heightFrom'],
        'heightTo': !exists(json, 'heightTo') ? undefined : json['heightTo'],
        'eyeColors': !exists(json, 'eyeColors') ? undefined : json['eyeColors'],
        'hairColors': !exists(json, 'hairColors') ? undefined : json['hairColors'],
        'isPregnant': !exists(json, 'isPregnant') ? undefined : json['isPregnant'],
        'ageFrom': !exists(json, 'ageFrom') ? undefined : json['ageFrom'],
        'ageTo': !exists(json, 'ageTo') ? undefined : json['ageTo'],
        'confectionFrom': !exists(json, 'confectionFrom') ? undefined : json['confectionFrom'],
        'confectionTo': !exists(json, 'confectionTo') ? undefined : json['confectionTo'],
        'shoeSizeFrom': !exists(json, 'shoeSizeFrom') ? undefined : json['shoeSizeFrom'],
        'shoeSizeTo': !exists(json, 'shoeSizeTo') ? undefined : json['shoeSizeTo'],
        'shoeSizeUsFrom': !exists(json, 'shoeSizeUsFrom') ? undefined : json['shoeSizeUsFrom'],
        'shoeSizeUsTo': !exists(json, 'shoeSizeUsTo') ? undefined : json['shoeSizeUsTo'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'modelCategories': !exists(json, 'modelCategories') ? undefined : json['modelCategories'],
        'nationality': !exists(json, 'nationality') ? undefined : json['nationality'],
        'ethnicity': !exists(json, 'ethnicity') ? undefined : json['ethnicity'],
        'sports': !exists(json, 'sports') ? undefined : ((json['sports'] as Array<any>).map(TwoLayerFilterDtoFromJSON)),
        'dances': !exists(json, 'dances') ? undefined : ((json['dances'] as Array<any>).map(TwoLayerFilterDtoFromJSON)),
        'spokenLanguages': !exists(json, 'spokenLanguages') ? undefined : ((json['spokenLanguages'] as Array<any>).map(TwoLayerFilterDtoFromJSON)),
        'piercings': !exists(json, 'piercings') ? undefined : json['piercings'],
        'tattoos': !exists(json, 'tattoos') ? undefined : json['tattoos'],
        'specials': !exists(json, 'specials') ? undefined : json['specials'],
        'driverLicenses': !exists(json, 'driverLicenses') ? undefined : json['driverLicenses'],
        'hasEarPiercing': !exists(json, 'hasEarPiercing') ? undefined : json['hasEarPiercing'],
        'hasPiercing': !exists(json, 'hasPiercing') ? undefined : json['hasPiercing'],
        'hasScar': !exists(json, 'hasScar') ? undefined : json['hasScar'],
        'hasTattoo': !exists(json, 'hasTattoo') ? undefined : json['hasTattoo'],
        'currentJob': !exists(json, 'currentJob') ? undefined : json['currentJob'],
        'learnedJob': !exists(json, 'learnedJob') ? undefined : json['learnedJob'],
    };
}

export function ModelMasterDataSearchFilterDtoToJSON(value?: ModelMasterDataSearchFilterDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'query': value.query,
        'sex': value.sex,
        'skinType': value.skinType,
        'hairLength': value.hairLength,
        'hairStyle': value.hairStyle,
        'chestCircumferenceFrom': value.chestCircumferenceFrom,
        'chestCircumferenceTo': value.chestCircumferenceTo,
        'braCupFrom': value.braCupFrom,
        'braCupTo': value.braCupTo,
        'waistCircumferenceFrom': value.waistCircumferenceFrom,
        'waistCircumferenceTo': value.waistCircumferenceTo,
        'hipCircumferenceFrom': value.hipCircumferenceFrom,
        'hipCircumferenceTo': value.hipCircumferenceTo,
        'heightFrom': value.heightFrom,
        'heightTo': value.heightTo,
        'eyeColors': value.eyeColors,
        'hairColors': value.hairColors,
        'isPregnant': value.isPregnant,
        'ageFrom': value.ageFrom,
        'ageTo': value.ageTo,
        'confectionFrom': value.confectionFrom,
        'confectionTo': value.confectionTo,
        'shoeSizeFrom': value.shoeSizeFrom,
        'shoeSizeTo': value.shoeSizeTo,
        'shoeSizeUsFrom': value.shoeSizeUsFrom,
        'shoeSizeUsTo': value.shoeSizeUsTo,
        'zipCode': value.zipCode,
        'modelCategories': value.modelCategories,
        'nationality': value.nationality,
        'ethnicity': value.ethnicity,
        'sports': value.sports === undefined ? undefined : ((value.sports as Array<any>).map(TwoLayerFilterDtoToJSON)),
        'dances': value.dances === undefined ? undefined : ((value.dances as Array<any>).map(TwoLayerFilterDtoToJSON)),
        'spokenLanguages': value.spokenLanguages === undefined ? undefined : ((value.spokenLanguages as Array<any>).map(TwoLayerFilterDtoToJSON)),
        'piercings': value.piercings,
        'tattoos': value.tattoos,
        'specials': value.specials,
        'driverLicenses': value.driverLicenses,
        'hasEarPiercing': value.hasEarPiercing,
        'hasPiercing': value.hasPiercing,
        'hasScar': value.hasScar,
        'hasTattoo': value.hasTattoo,
        'currentJob': value.currentJob,
        'learnedJob': value.learnedJob,
    };
}

