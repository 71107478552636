/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectModelBillingEntity
 */
export interface ProjectModelBillingEntity {
    /**
     * 
     * @type {number}
     * @memberof ProjectModelBillingEntity
     */
    overtimeCosts?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectModelBillingEntity
     */
    provision?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelBillingEntity
     */
    period?: ProjectModelBillingEntityPeriodEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectModelBillingEntity
     */
    vat?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProjectModelBillingEntity
     */
    overtime?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectModelBillingEntity
     */
    buyoutValue?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelBillingEntity
     */
    buyoutType?: ProjectModelBillingEntityBuyoutTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ProjectModelBillingEntity
     */
    dailyRate?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectModelBillingEntity
     */
    salary?: number;
}


/**
 * @export
 */
export const ProjectModelBillingEntityPeriodEnum = {
    OneHour: 'ONE_HOUR',
    TwoHours: 'TWO_HOURS',
    ThreeHours: 'THREE_HOURS',
    HalfDay: 'HALF_DAY',
    FiveHours: 'FIVE_HOURS',
    SixHours: 'SIX_HOURS',
    SevenHours: 'SEVEN_HOURS',
    OneDay: 'ONE_DAY',
    OneAndHalfDays: 'ONE_AND_HALF_DAYS',
    TwoDays: 'TWO_DAYS',
    TwoAndHalfDays: 'TWO_AND_HALF_DAYS',
    ThreeDays: 'THREE_DAYS',
    ThreeAndHalfDays: 'THREE_AND_HALF_DAYS',
    FourDays: 'FOUR_DAYS',
    FourAndHalfDays: 'FOUR_AND_HALF_DAYS',
    FiveDays: 'FIVE_DAYS',
    FiveAndHalfDays: 'FIVE_AND_HALF_DAYS',
    SixDays: 'SIX_DAYS',
    SixAndHalfDays: 'SIX_AND_HALF_DAYS',
    SevenDays: 'SEVEN_DAYS',
    SevenAndHalfDays: 'SEVEN_AND_HALF_DAYS',
    EightDays: 'EIGHT_DAYS',
    EightAndHalfDays: 'EIGHT_AND_HALF_DAYS',
    NineDays: 'NINE_DAYS',
    NineAndHalfDays: 'NINE_AND_HALF_DAYS',
    TenDays: 'TEN_DAYS'
} as const;
export type ProjectModelBillingEntityPeriodEnum = typeof ProjectModelBillingEntityPeriodEnum[keyof typeof ProjectModelBillingEntityPeriodEnum];

/**
 * @export
 */
export const ProjectModelBillingEntityBuyoutTypeEnum = {
    Incl: 'INCL',
    Flat: 'FLAT',
    Percentage: 'PERCENTAGE'
} as const;
export type ProjectModelBillingEntityBuyoutTypeEnum = typeof ProjectModelBillingEntityBuyoutTypeEnum[keyof typeof ProjectModelBillingEntityBuyoutTypeEnum];


/**
 * Check if a given object implements the ProjectModelBillingEntity interface.
 */
export function instanceOfProjectModelBillingEntity(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectModelBillingEntityFromJSON(json: any): ProjectModelBillingEntity {
    return ProjectModelBillingEntityFromJSONTyped(json, false);
}

export function ProjectModelBillingEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectModelBillingEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'overtimeCosts': !exists(json, 'overtimeCosts') ? undefined : json['overtimeCosts'],
        'provision': !exists(json, 'provision') ? undefined : json['provision'],
        'period': !exists(json, 'period') ? undefined : json['period'],
        'vat': !exists(json, 'vat') ? undefined : json['vat'],
        'overtime': !exists(json, 'overtime') ? undefined : json['overtime'],
        'buyoutValue': !exists(json, 'buyoutValue') ? undefined : json['buyoutValue'],
        'buyoutType': !exists(json, 'buyoutType') ? undefined : json['buyoutType'],
        'dailyRate': !exists(json, 'dailyRate') ? undefined : json['dailyRate'],
        'salary': !exists(json, 'salary') ? undefined : json['salary'],
    };
}

export function ProjectModelBillingEntityToJSON(value?: ProjectModelBillingEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'overtimeCosts': value.overtimeCosts,
        'provision': value.provision,
        'period': value.period,
        'vat': value.vat,
        'overtime': value.overtime,
        'buyoutValue': value.buyoutValue,
        'buyoutType': value.buyoutType,
        'dailyRate': value.dailyRate,
        'salary': value.salary,
    };
}

