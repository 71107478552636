/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';

/**
 * 
 * @export
 * @interface BodyAccessoriesFormValuesDto
 */
export interface BodyAccessoriesFormValuesDto {
    /**
     * 
     * @type {boolean}
     * @memberof BodyAccessoriesFormValuesDto
     */
    earHole?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BodyAccessoriesFormValuesDto
     */
    piercings?: string;
    /**
     * 
     * @type {string}
     * @memberof BodyAccessoriesFormValuesDto
     */
    tattoos?: string;
    /**
     * 
     * @type {string}
     * @memberof BodyAccessoriesFormValuesDto
     */
    scars?: string;
    /**
     * 
     * @type {string}
     * @memberof BodyAccessoriesFormValuesDto
     */
    earHoleString?: string;
    /**
     * 
     * @type {string}
     * @memberof BodyAccessoriesFormValuesDto
     */
    allergies?: string;
    /**
     * 
     * @type {AdminValue}
     * @memberof BodyAccessoriesFormValuesDto
     */
    dietType?: AdminValue;
    /**
     * 
     * @type {string}
     * @memberof BodyAccessoriesFormValuesDto
     */
    dietNotes?: string;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof BodyAccessoriesFormValuesDto
     */
    dietOptions?: Array<AdminValue>;
}

/**
 * Check if a given object implements the BodyAccessoriesFormValuesDto interface.
 */
export function instanceOfBodyAccessoriesFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BodyAccessoriesFormValuesDtoFromJSON(json: any): BodyAccessoriesFormValuesDto {
    return BodyAccessoriesFormValuesDtoFromJSONTyped(json, false);
}

export function BodyAccessoriesFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BodyAccessoriesFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'earHole': !exists(json, 'earHole') ? undefined : json['earHole'],
        'piercings': !exists(json, 'piercings') ? undefined : json['piercings'],
        'tattoos': !exists(json, 'tattoos') ? undefined : json['tattoos'],
        'scars': !exists(json, 'scars') ? undefined : json['scars'],
        'earHoleString': !exists(json, 'earHoleString') ? undefined : json['earHoleString'],
        'allergies': !exists(json, 'allergies') ? undefined : json['allergies'],
        'dietType': !exists(json, 'dietType') ? undefined : AdminValueFromJSON(json['dietType']),
        'dietNotes': !exists(json, 'dietNotes') ? undefined : json['dietNotes'],
        'dietOptions': !exists(json, 'dietOptions') ? undefined : ((json['dietOptions'] as Array<any>).map(AdminValueFromJSON)),
    };
}

export function BodyAccessoriesFormValuesDtoToJSON(value?: BodyAccessoriesFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'earHole': value.earHole,
        'piercings': value.piercings,
        'tattoos': value.tattoos,
        'scars': value.scars,
        'earHoleString': value.earHoleString,
        'allergies': value.allergies,
        'dietType': AdminValueToJSON(value.dietType),
        'dietNotes': value.dietNotes,
        'dietOptions': value.dietOptions === undefined ? undefined : ((value.dietOptions as Array<any>).map(AdminValueToJSON)),
    };
}

