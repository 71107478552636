/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BahnCard
 */
export interface BahnCard {
    /**
     * 
     * @type {string}
     * @memberof BahnCard
     */
    bahncardType: string;
    /**
     * 
     * @type {boolean}
     * @memberof BahnCard
     */
    bahncardClass: boolean;
    /**
     * 
     * @type {string}
     * @memberof BahnCard
     */
    cardNumber: string;
    /**
     * 
     * @type {Date}
     * @memberof BahnCard
     */
    expirationDate: Date;
}

/**
 * Check if a given object implements the BahnCard interface.
 */
export function instanceOfBahnCard(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "bahncardType" in value;
    isInstance = isInstance && "bahncardClass" in value;
    isInstance = isInstance && "cardNumber" in value;
    isInstance = isInstance && "expirationDate" in value;

    return isInstance;
}

export function BahnCardFromJSON(json: any): BahnCard {
    return BahnCardFromJSONTyped(json, false);
}

export function BahnCardFromJSONTyped(json: any, ignoreDiscriminator: boolean): BahnCard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bahncardType': json['bahncardType'],
        'bahncardClass': json['bahncardClass'],
        'cardNumber': json['cardNumber'],
        'expirationDate': (new Date(json['expirationDate'])),
    };
}

export function BahnCardToJSON(value?: BahnCard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bahncardType': value.bahncardType,
        'bahncardClass': value.bahncardClass,
        'cardNumber': value.cardNumber,
        'expirationDate': (value.expirationDate.toISOString().substr(0,10)),
    };
}

