/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TaskManagerInfos } from './TaskManagerInfos';
import {
    TaskManagerInfosFromJSON,
    TaskManagerInfosFromJSONTyped,
    TaskManagerInfosToJSON,
} from './TaskManagerInfos';

/**
 * 
 * @export
 * @interface ProjectTableItemDto
 */
export interface ProjectTableItemDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectTableItemDto
     */
    projectId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTableItemDto
     */
    workingTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTableItemDto
     */
    producerName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTableItemDto
     */
    lastEditedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTableItemDto
     */
    lastWatchedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTableItemDto
     */
    creator?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTableItemDto
     */
    createdAt?: string;
    /**
     * 
     * @type {TaskManagerInfos}
     * @memberof ProjectTableItemDto
     */
    taskManagerInfos?: TaskManagerInfos;
}

/**
 * Check if a given object implements the ProjectTableItemDto interface.
 */
export function instanceOfProjectTableItemDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectTableItemDtoFromJSON(json: any): ProjectTableItemDto {
    return ProjectTableItemDtoFromJSONTyped(json, false);
}

export function ProjectTableItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectTableItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'workingTitle': !exists(json, 'workingTitle') ? undefined : json['workingTitle'],
        'producerName': !exists(json, 'producerName') ? undefined : json['producerName'],
        'lastEditedAt': !exists(json, 'lastEditedAt') ? undefined : json['lastEditedAt'],
        'lastWatchedBy': !exists(json, 'lastWatchedBy') ? undefined : json['lastWatchedBy'],
        'creator': !exists(json, 'creator') ? undefined : json['creator'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'taskManagerInfos': !exists(json, 'taskManagerInfos') ? undefined : TaskManagerInfosFromJSON(json['taskManagerInfos']),
    };
}

export function ProjectTableItemDtoToJSON(value?: ProjectTableItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projectId': value.projectId,
        'workingTitle': value.workingTitle,
        'producerName': value.producerName,
        'lastEditedAt': value.lastEditedAt,
        'lastWatchedBy': value.lastWatchedBy,
        'creator': value.creator,
        'createdAt': value.createdAt,
        'taskManagerInfos': TaskManagerInfosToJSON(value.taskManagerInfos),
    };
}

