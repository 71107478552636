import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector, createSelector } from '@ngxs/store';
import { ProjectEditStateModel, initialState } from './project-edit.model';
import { ProjectQueryControllerService, ProjectControllerService, ModelMasterDataControllerService } from '@brody-bookings/api-v2';
import { ProjectFormService } from '../../service/project.form.service';

import { ProjectProducerFormValueDto } from 'libs/api-typescript-angular/src/model/projectProducerFormValueDto';
import { ProjectRoleFormValuesDto } from 'libs/api-typescript-angular/src/model/projectRoleFormValuesDto';
import { UsageTypesFormValuesDto } from 'libs/api-typescript-angular/src/model/usageTypesFormValuesDto';
import { ErrorHandlingService } from '../../service/errorHandling.service';
import { ModelSearchService } from '../../service/model.search.service';

// Actions
import {
    ProjectEditBaseActions,
    ProjectEditProducerActions,
    ProjectEditProjectActions,
    ProjectEditModelActions,
    ProjectEditAppointmentActions,
    ProjectEditRolesActions,
    ProjectEditAdditionalParticipantsActions,
    ProjectEditExternalParticipantsActions,
    ProjectEditLocationActions
} from './actions';

// Handlers
import {
    ProjectEditBaseHandlers,
    ProjectEditProducerHandlers,
    ProjectEditProjectHandlers,
    ProjectEditModelHandlers,
    ProjectEditAppointmentHandlers,
    ProjectEditRolesHandlers,
    ProjectEditAdditionalParticipantsHandlers,
    ProjectEditExternalParticipantsHandlers,
    ProjectEditLocationHandlers
} from './handlers';

@State<ProjectEditStateModel>({
    name: 'projectEdit',
    defaults: initialState
})
@Injectable()
export class ProjectEditState {
    private baseHandlers: ProjectEditBaseHandlers;
    private modelHandlers: ProjectEditModelHandlers;
    private appointmentHandlers: ProjectEditAppointmentHandlers;
    private rolesHandlers: ProjectEditRolesHandlers;
    private producerHandlers: ProjectEditProducerHandlers;
    private projectHandlers: ProjectEditProjectHandlers;
    private additionalParticipantsHandlers: ProjectEditAdditionalParticipantsHandlers;
    private externalParticipantsHandlers: ProjectEditExternalParticipantsHandlers;
    private locationHandlers: ProjectEditLocationHandlers;
    constructor(
        private projectQueryService: ProjectQueryControllerService,
        private projectFormService: ProjectFormService,
        private projectControllerService: ProjectControllerService,
        private modelMasterDataControllerService: ModelMasterDataControllerService,
        private modelSearchService: ModelSearchService,
        private errorHandlingService: ErrorHandlingService
    ) {
        this.baseHandlers = new ProjectEditBaseHandlers(projectFormService);
        this.modelHandlers = new ProjectEditModelHandlers(projectControllerService, modelSearchService);
        this.appointmentHandlers = new ProjectEditAppointmentHandlers(modelMasterDataControllerService, errorHandlingService, projectControllerService);
        this.rolesHandlers = new ProjectEditRolesHandlers(projectQueryService, projectControllerService);
        this.producerHandlers = new ProjectEditProducerHandlers(projectControllerService, projectFormService);
        this.projectHandlers = new ProjectEditProjectHandlers(projectControllerService);
        this.additionalParticipantsHandlers = new ProjectEditAdditionalParticipantsHandlers(
            projectControllerService
        );
        this.externalParticipantsHandlers = new ProjectEditExternalParticipantsHandlers(
            projectControllerService
        );
        this.locationHandlers = new ProjectEditLocationHandlers(projectControllerService);
    }

    @Selector()
    static loading(state: ProjectEditStateModel): boolean {
        return state.loading;
    }

    @Selector()
    static getState(state: ProjectEditStateModel): ProjectEditStateModel {
        return state;
    }

    @Selector()
    static producer(state: ProjectEditStateModel): ProjectProducerFormValueDto | undefined {
        return state.producer;
    }

    @Selector()
    static roles(state: ProjectEditStateModel): ProjectRoleFormValuesDto[] {
        return [...(state.roles?.roles ?? [])].sort((a, b) => 
            (a.name ?? '').localeCompare(b.name ?? '')
        );
    }

    @Selector()
    static selectedRole(state: ProjectEditStateModel): ProjectRoleFormValuesDto | undefined {
        return state.selectedRole;
    }

    @Selector()
    static usageType(state: ProjectEditStateModel): UsageTypesFormValuesDto | undefined {
        return state.usageType;
    }

    @Selector()
    static additionalParticipants(state: ProjectEditStateModel) {
        return [...(state.additionalParticipantList ?? [])].sort((a, b) => 
            (a.employeeLastName ?? '').localeCompare(b.employeeLastName ?? '')
        );
    }

    @Selector()
    static externalParticipants(state: ProjectEditStateModel) {
        return state.externalParticipantList;
    }

    @Selector()
    static projectLocations(state: ProjectEditStateModel) {
        return state.projectLocations;
    }

    @Selector()
    static selectedLocation(state: ProjectEditStateModel) {
        return state.selectedLocation;
    }

    @Selector()
    static selectedMotif(state: ProjectEditStateModel) {
        return state.selectedMotif;
    }

    @Selector()
    static selectedProjectModelsForRole(state: ProjectEditStateModel) {
        return state.projectRolesWithModels?.projectRoleWithModels?.find(role => role.roleId === state.selectedRoleInProductionDetails?.projectRoleId)?.projectModels?.filter(model => model.selectedStatus?.key === 'SELECTED');
    }

    @Selector()
    static getFilteredSearchModels(state: ProjectEditStateModel) {
        const selectedRoleId = state.projectRolesWithModels?.selectedRoleId;
        const alreadySelectedModelIds = state.projectRolesWithModels?.projectRoleWithModels
            ?.find(role => role.roleId === selectedRoleId)
            ?.projectModels
            ?.map(model => model.modelId) || [];

        return state.modelSearch.allModels?.filter(
            model => !alreadySelectedModelIds.includes(model.modelId)
        );
    }

    @Selector()
    static bookedModels(state: ProjectEditStateModel) {
        const projectRolesWithModels = state.projectRolesWithModels?.projectRoleWithModels;
        const bookedModels = projectRolesWithModels?.flatMap(role => role.projectModels?.filter(model => model.selectedStatus?.key === 'SELECTED'));
        return bookedModels;
    }

    @Selector()
    static protocol(state: ProjectEditStateModel) {
        return state.protocol;
    }


    /**************************************************
     *************   Base Actions   ******************
    **************************************************/

    @Action(ProjectEditBaseActions.SetLoading)
    setLoading(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditBaseActions.SetLoading) {
        return this.baseHandlers.setLoading(ctx, action);
    }

    @Action(ProjectEditBaseActions.UpdateLanguage)
    updateLanguage(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditBaseActions.UpdateLanguage) {
        return this.baseHandlers.updateLanguage(ctx, action);
    }

    @Action(ProjectEditBaseActions.UpdateState)
    updateState(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditBaseActions.UpdateState) {
        return this.baseHandlers.updateState(ctx, action);
    }

    @Action(ProjectEditBaseActions.ResetState)
    resetState(ctx: StateContext<ProjectEditStateModel>) {
        return this.baseHandlers.resetState(ctx);
    }

    @Action(ProjectEditBaseActions.LoadProject)
    loadProject(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditBaseActions.LoadProject) {
        return this.baseHandlers.loadProject(ctx, action);
    }

    // Producer Actions
    @Action(ProjectEditProducerActions.UpdateProducer)
    updateProducer(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditProducerActions.UpdateProducer) {
        return this.producerHandlers.updateProducer(ctx, action);
    }

    @Action(ProjectEditProducerActions.UpdateProducerSelectedEmployee)
    updateProducerSelectedEmployee(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditProducerActions.UpdateProducerSelectedEmployee) {
        return ProjectEditProducerHandlers.updateProducerSelectedEmployee(ctx, action);
    }

    /**************************************************
     *************   Project Actions   **************
    **************************************************/

    @Action(ProjectEditProjectActions.UpdateName)
    updateName(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditProjectActions.UpdateName) {
        return this.projectHandlers.updateName(ctx, action);
    }

    @Action(ProjectEditProjectActions.UpdateFinalCustomer)
    updateFinalCustomer(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditProjectActions.UpdateFinalCustomer) {
        return this.projectHandlers.updateFinalCustomer(ctx, action);
    }

    @Action(ProjectEditProjectActions.UpdateProductionDetails)
    updateProductionDetails(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditProjectActions.UpdateProductionDetails) {
        return this.projectHandlers.updateProductionDetails(ctx, action);
    }

    @Action(ProjectEditProjectActions.UpdateBuyOut)
    updateBuyOut(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditProjectActions.UpdateBuyOut) {
        return this.projectHandlers.updateBuyOut(ctx, action);
    }

    @Action(ProjectEditProjectActions.UpdateExecutor)
    updateExecutor(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditProjectActions.UpdateExecutor) {
        return this.projectHandlers.updateExecutor(ctx, action);
    }

    @Action(ProjectEditProjectActions.UpdateStatus)
    updateStatus(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditProjectActions.UpdateStatus) {
        return this.projectHandlers.updateStatus(ctx, action);
    }

    /**************************************************
     **********   Model Actions   ******************
    **************************************************/

    @Action(ProjectEditModelActions.LoadProjectModels)
    loadProjectModels(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditModelActions.LoadProjectModels) {
        return this.modelHandlers.loadProjectModels(ctx, action);
    }

    @Action(ProjectEditModelActions.ChangeProjectModel)
    changeProjectModel(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditModelActions.ChangeProjectModel) {
        return this.modelHandlers.changeProjectModel(ctx, action);
    }

    @Action(ProjectEditModelActions.ChangeProjectModelBuyout)
    changeProjectModelBuyout(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditModelActions.ChangeProjectModelBuyout) {
        return this.modelHandlers.changeProjectModelBuyout(ctx, action);
    }

    @Action(ProjectEditModelActions.AddProductionDays)
    addProductionDays(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditModelActions.AddProductionDays) {
        return this.modelHandlers.addProductionDays(ctx, action);
    }

    @Action(ProjectEditModelActions.ChangeProductionDay)
    changeProductionDay(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditModelActions.ChangeProductionDay) {
        return this.modelHandlers.changeProductionDay(ctx, action);
    }

    @Action(ProjectEditModelActions.DeleteProductionDayEvent)
    deleteProductionDayEvent(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditModelActions.DeleteProductionDayEvent) {
        return this.modelHandlers.deleteProductionDayEvent(ctx, action);
    }

    @Action(ProjectEditModelActions.SetProductionDay)
    setProductionDay(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditModelActions.SetProductionDay) {
        return this.modelHandlers.setProductionDay(ctx, action);
    }

    @Action(ProjectEditModelActions.LoadProductionDayEvents)
    loadProductionDayEvents(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditModelActions.LoadProductionDayEvents) {
        console.log('Loading production day events for model:', action.projectModelId, 'and day:', action.productionDay);
        return this.modelHandlers.loadProductionDayEvents(ctx, action);
    }

    static getProductionDayEventsForDate(modelId: string, date: Date) {
        console.log('getProductionDayEventsForDate', modelId, date);
        return createSelector([ProjectEditState], (state: ProjectEditStateModel) => {
            const projectRolesWithModels = state.projectRolesWithModels;

            const role = projectRolesWithModels?.projectRoleWithModels?.find((role) => role.roleId === state.selectedRoleInProductionDetails?.projectRoleId);
            const projectModel = role?.projectModels?.find((model) => model.projectModelId === modelId);

            if (!projectModel?.modelProductionDetailsFormValuesDto?.events) {
                return;
            }

            return projectModel?.modelProductionDetailsFormValuesDto?.events?.filter((event) => {
                const eventDate = new Date(event.startDate);
                eventDate.setHours(12);
                const productionDay = date;
                productionDay.setHours(12);

                return eventDate.toISOString() === productionDay.toISOString();
            });

        });
    }

    @Action(ProjectEditModelActions.AddModelToProjectRole)
    addModelToProjectRole(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditModelActions.AddModelToProjectRole) {
        return this.modelHandlers.addModelToProjectRole(ctx, action);
    }   

    @Action(ProjectEditModelActions.LoadSearchModels)
    loadSearchModels(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditModelActions.LoadSearchModels) {
        return this.modelHandlers.loadSearchModels(ctx, action);
    }

    @Action(ProjectEditModelActions.DeleteProjectModel)
    deleteProjectModel(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditModelActions.DeleteProjectModel) {
        return this.modelHandlers.deleteProjectModel(ctx, action);
    }


    /**************************************************
     **********   Appointment Actions   *************
    **************************************************/

    @Action(ProjectEditAppointmentActions.LoadAppointments)
    loadAppointments(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditAppointmentActions.LoadAppointments) {
        return this.appointmentHandlers.loadAppointments(ctx, action);
    }

    @Action(ProjectEditAppointmentActions.LoadAppointmentsOfModel)
    loadAppointmentsOfModel(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditAppointmentActions.LoadAppointmentsOfModel) {
        return this.appointmentHandlers.loadAppointmentsOfModel(ctx, action);
    }

    @Action(ProjectEditAppointmentActions.ChangeAppointment)
    changeAppointment(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditAppointmentActions.ChangeAppointment) {
        return this.appointmentHandlers.changeAppointment(ctx, action);
    }

    @Action(ProjectEditAppointmentActions.AddDeclinedAppointment)
    addDeclinedAppointment(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditAppointmentActions.AddDeclinedAppointment) {
        return this.appointmentHandlers.addDeclinedAppointment(ctx, action);
    }

    @Action(ProjectEditAppointmentActions.AddRequestTimeframe)
    addRequestTimeframe(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditAppointmentActions.AddRequestTimeframe) {
        return this.appointmentHandlers.addRequestTimeframe(ctx, action);
    }

    @Action(ProjectEditAppointmentActions.DeleteAppointment)
    deleteAppointment(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditAppointmentActions.DeleteAppointment) {
        return this.appointmentHandlers.deleteAppointment(ctx, action);
    }

    @Action(ProjectEditAppointmentActions.AddProjectRelatedAppointment)
    addProjectRelatedAppointment(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditAppointmentActions.AddProjectRelatedAppointment) {
        return this.appointmentHandlers.addProjectRelatedAppointment(ctx, action);
    }

    @Action(ProjectEditAppointmentActions.CreateRoleRequestTimeFrame)
    createRoleRequestTimeFrame(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditAppointmentActions.CreateRoleRequestTimeFrame) {
        return this.appointmentHandlers.createRoleRequestTimeFrame(ctx, action);
    }

    @Action(ProjectEditAppointmentActions.DeleteRoleRequestTimeFrame)
    deleteRoleRequestTimeFrame(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditAppointmentActions.DeleteRoleRequestTimeFrame) {
        return this.appointmentHandlers.deleteRoleRequestTimeFrame(ctx, action);
    }

    /**************************************************
     *************   Roles Actions   ******************
    **************************************************/

    @Action(ProjectEditRolesActions.SetSelectedRole)
    setSelectedRole(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditRolesActions.SetSelectedRole) {
        return this.rolesHandlers.setSelectedRole(ctx, action);
    }

    @Action(ProjectEditRolesActions.SetSelectedRoleInProductionDetails)
    setSelectedRoleInProductionDetails(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditRolesActions.SetSelectedRoleInProductionDetails) {
        return this.rolesHandlers.setSelectedRoleInProductionDetails(ctx, action);
    }

    @Action(ProjectEditRolesActions.LoadRolesAsOptions)
    loadRolesAsOptions(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditRolesActions.LoadRolesAsOptions) {
        return this.rolesHandlers.loadRolesAsOptions(ctx, action);
    }

    @Action(ProjectEditModelActions.UpdateSelectedProjectRoleForModelSelection)
    updateSelectedProjectRoleForModelSelection(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditModelActions.UpdateSelectedProjectRoleForModelSelection) {
        return this.modelHandlers.updateSelectedProjectRoleForModelSelection(ctx, action);
    }

    @Action(ProjectEditRolesActions.ChangeRole)
    changeRole(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditRolesActions.ChangeRole) {
        return this.rolesHandlers.changeRole(ctx, action);
    }

    @Action(ProjectEditRolesActions.GetProjectRoleFormValues)
    getProjectRoleFormValues(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditRolesActions.GetProjectRoleFormValues) {
        return this.rolesHandlers.getProjectRoleFormValues(ctx, action);
    }

    @Action(ProjectEditRolesActions.AddProjectRole)
    addProjectRole(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditRolesActions.AddProjectRole) {
        return this.rolesHandlers.addProjectRole(ctx, action);
    }

    @Action(ProjectEditRolesActions.DeleteProjectRole)
    deleteProjectRole(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditRolesActions.DeleteProjectRole) {
        return this.rolesHandlers.deleteProjectRole(ctx, action);
    }

    @Action(ProjectEditRolesActions.ChangeRoleDetailsInProductionDetails)
    changeRoleDetailsInProductionDetails(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditRolesActions.ChangeRoleDetailsInProductionDetails) {
        return this.rolesHandlers.changeRoleDetailsInProductionDetails(ctx, action);
    }

    /**************************************************
     ********** Additional Participants Actions *******
    **************************************************/

    @Action(ProjectEditAdditionalParticipantsActions.LoadAdditionalParticipants)
    loadAdditionalParticipants(ctx: StateContext<ProjectEditStateModel>) {
        return this.additionalParticipantsHandlers.loadAdditionalParticipants(ctx);
    }

    @Action(ProjectEditAdditionalParticipantsActions.AddAdditionalParticipant)
    addAdditionalParticipant(
        ctx: StateContext<ProjectEditStateModel>,
        action: ProjectEditAdditionalParticipantsActions.AddAdditionalParticipant
    ) {
        return this.additionalParticipantsHandlers.addAdditionalParticipant(ctx, action);
    }

    // @Action(ProjectEditAdditionalParticipantsActions.UpdateAdditionalParticipant)
    // updateAdditionalParticipant(
    //     ctx: StateContext<ProjectEditStateModel>,
    //     action: ProjectEditAdditionalParticipantsActions.UpdateAdditionalParticipant
    // ) {
    //     return this.additionalParticipantsHandlers.updateAdditionalParticipant(ctx, action);
    // }

    @Action(ProjectEditAdditionalParticipantsActions.ChangeAdminRights)
    changeAdditionalParticipantAdminRights(
        ctx: StateContext<ProjectEditStateModel>,
        action: ProjectEditAdditionalParticipantsActions.ChangeAdminRights
    ) {
        return this.additionalParticipantsHandlers.changeAdminRights(ctx, action);
    }

    @Action(ProjectEditAdditionalParticipantsActions.DeleteAdditionalParticipant)
    deleteAdditionalParticipant(
        ctx: StateContext<ProjectEditStateModel>,
        action: ProjectEditAdditionalParticipantsActions.DeleteAdditionalParticipant
    ) {
        return this.additionalParticipantsHandlers.deleteAdditionalParticipant(ctx, action);
    }

    /**************************************************
     ********* External Participants Actions *********
    **************************************************/

    @Action(ProjectEditExternalParticipantsActions.LoadExternalParticipants)
    loadExternalParticipants(
        ctx: StateContext<ProjectEditStateModel>,
        action: ProjectEditExternalParticipantsActions.LoadExternalParticipants
    ) {
        return this.externalParticipantsHandlers.loadExternalParticipants(ctx);
    }

    @Action(ProjectEditExternalParticipantsActions.AddExternalParticipant)
    addExternalParticipant(
        ctx: StateContext<ProjectEditStateModel>,
        action: ProjectEditExternalParticipantsActions.AddExternalParticipant
    ) {
        return this.externalParticipantsHandlers.addExternalParticipant(ctx, action);
    }

    @Action(ProjectEditExternalParticipantsActions.DeleteExternalParticipant)
    deleteExternalParticipant(
        ctx: StateContext<ProjectEditStateModel>,
        action: ProjectEditExternalParticipantsActions.DeleteExternalParticipant
    ) {
        return this.externalParticipantsHandlers.deleteExternalParticipant(ctx, action);
    }

    @Action(ProjectEditExternalParticipantsActions.ChangeAdminRights)
    changeExternalParticipantAdminRights(
        ctx: StateContext<ProjectEditStateModel>,
        action: ProjectEditExternalParticipantsActions.ChangeAdminRights
    ) {
        return this.externalParticipantsHandlers.changeAdminRights(ctx, action);
    }


    /**************************************************
     *************   Location Actions   **************
    **************************************************/

    @Action(ProjectEditLocationActions.LoadLocations)
    loadLocations(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditLocationActions.LoadLocations) {
        return this.locationHandlers.loadProjectLocationsAndMotifs(ctx, action);
    }

    @Action(ProjectEditLocationActions.ChangeLocation)
    changeLocation(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditLocationActions.ChangeLocation) {
        return this.locationHandlers.changeLocation(ctx, action);
    }

    @Action(ProjectEditLocationActions.AddLocation)
    addLocation(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditLocationActions.AddLocation) {
        return this.locationHandlers.addLocation(ctx, action);
    }

    @Action(ProjectEditLocationActions.DeleteLocation)
    deleteLocation(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditLocationActions.DeleteLocation) {
        return this.locationHandlers.deleteLocation(ctx, action);
    }

    @Action(ProjectEditLocationActions.ChangeMotif)
    changeMotif(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditLocationActions.ChangeMotif) {
        return this.locationHandlers.changeMotif(ctx, action);
    }

    @Action(ProjectEditLocationActions.AddMotif)
    addMotif(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditLocationActions.AddMotif) {
        return this.locationHandlers.addMotif(ctx, action);
    }

    @Action(ProjectEditLocationActions.DeleteMotif)
    deleteMotif(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditLocationActions.DeleteMotif) {
        return this.locationHandlers.deleteMotif(ctx, action);
    }

    @Action(ProjectEditLocationActions.SelectLocation)
    selectLocation(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditLocationActions.SelectLocation) {
        return this.locationHandlers.selectLocation(ctx, action);
    }

    @Action(ProjectEditLocationActions.SelectMotif)
    selectMotif(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditLocationActions.SelectMotif) {
        return this.locationHandlers.selectMotif(ctx, action);
    }
}
