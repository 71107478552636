import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface ImagePreviewState {
  images: string[];
  isVisible: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ImagePreviewService {
  private state = new BehaviorSubject<ImagePreviewState>({
    images: [],
    isVisible: false
  });

  state$ = this.state.asObservable();

  show(images: string[]) {
    this.state.next({
      images,
      isVisible: true
    });
  }

  close() {
    this.state.next({
      images: [],
      isVisible: false
    });
  }
}