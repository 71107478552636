import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SidebarModule } from 'primeng/sidebar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ButtonModule } from 'primeng/button';
import { InputSwitchModule } from 'primeng/inputswitch';
import { AppConfigComponent } from './app.config.component';
import { ConversationWidgetComponent } from '../../demo/components/shared/widgets/conversation/conversation.widget.component';
import { ConversationModule } from '../../demo/components/conversation/conversation.module';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { ConversationService } from '../../demo/service/conversation.service';
import { WidgetConversationService } from '../../demo/components/shared/widgets/conversation/_service/widget.conversation.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SidebarModule,
        RadioButtonModule,
        ButtonModule,
        InputSwitchModule,
        CheckboxModule,
        ConversationModule,
        TooltipModule,
        ToastModule,
        DialogModule
    ],
    declarations: [
        AppConfigComponent,
        ConversationWidgetComponent
    ],
    exports: [
        AppConfigComponent
    ],
    providers: [
        ConversationService,
        WidgetConversationService
    ]
})
export class AppConfigModule { }
