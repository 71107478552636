/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BookingModelEntity
 */
export interface BookingModelEntity {
    /**
     * 
     * @type {string}
     * @memberof BookingModelEntity
     */
    travelCosts?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingModelEntity
     */
    bookingStatus?: BookingModelEntityBookingStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof BookingModelEntity
     */
    modelConfirmed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BookingModelEntity
     */
    bookingId?: number;
    /**
     * 
     * @type {Date}
     * @memberof BookingModelEntity
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BookingModelEntity
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BookingModelEntity
     */
    start?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BookingModelEntity
     */
    end?: Date;
}


/**
 * @export
 */
export const BookingModelEntityBookingStatusEnum = {
    Transacted: 'TRANSACTED',
    Confirmed: 'CONFIRMED'
} as const;
export type BookingModelEntityBookingStatusEnum = typeof BookingModelEntityBookingStatusEnum[keyof typeof BookingModelEntityBookingStatusEnum];


/**
 * Check if a given object implements the BookingModelEntity interface.
 */
export function instanceOfBookingModelEntity(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BookingModelEntityFromJSON(json: any): BookingModelEntity {
    return BookingModelEntityFromJSONTyped(json, false);
}

export function BookingModelEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookingModelEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'travelCosts': !exists(json, 'travelCosts') ? undefined : json['travelCosts'],
        'bookingStatus': !exists(json, 'bookingStatus') ? undefined : json['bookingStatus'],
        'modelConfirmed': !exists(json, 'modelConfirmed') ? undefined : json['modelConfirmed'],
        'bookingId': !exists(json, 'bookingId') ? undefined : json['bookingId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'start': !exists(json, 'start') ? undefined : (new Date(json['start'])),
        'end': !exists(json, 'end') ? undefined : (new Date(json['end'])),
    };
}

export function BookingModelEntityToJSON(value?: BookingModelEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'travelCosts': value.travelCosts,
        'bookingStatus': value.bookingStatus,
        'modelConfirmed': value.modelConfirmed,
        'bookingId': value.bookingId,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'start': value.start === undefined ? undefined : (value.start.toISOString()),
        'end': value.end === undefined ? undefined : (value.end.toISOString()),
    };
}

